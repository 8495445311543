import { Button, IconButton, TextField } from "@mui/material";
import React, { useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import InputAdornment from "@mui/material/InputAdornment";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { createClinicRegisterRequest } from "../database/clinic";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import { useNavigate } from "react-router-dom";
export default function RegisterClinic() {
  const [clinicName, setClinicName] = useState("");
  const [clinicEmail, setClinicEmail] = useState("");
  const [clinicPassword, setClinicPassword] = useState("");
  const [showPass, setShowPass] = useState(true);
  const [rut, setRut] = useState("");
  const navigate = useNavigate();
  const saveClinic = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const data = {
      clinicName,
      // clinicLocation,
      rut,
      clinicEmail,
      clinicPassword,
      // clinicDoctors: listClinicDoctor,
    };
    const response = await createClinicRegisterRequest(data);
    Swal.close();
    if (response) {
      Swal.fire({
        icon: "success",
        title: "¡Registro exitoso!",
        text: "Verifique su correo para iniciar sesión",
      });
      navigate("/login");
    } else {
      Swal.fire({
        icon: "error",
        title: "¡Error al registrar!",
        text: "Intente nuevamente",
      });
    }
  };

  const handleClickShowPassword = () => {
    setShowPass(!showPass);
  };

  return (
    <>
      <TextField
        variant="filled"
        margin="normal"
        required
        placeholder="Nombre de la clinica"
        type="text"
        autoComplete="name"
        fullWidth
        onChange={(e) => {
          let eventName = e.target.value
            .toLowerCase()
            .normalize("NFD")
            .replace(
              /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
              "$1"
            );
          setClinicName(eventName);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PermIdentityIcon />
            </InputAdornment>
          ),
        }}
      />
      {/* <TextField
        sx={{
          width: "80%",
          backgroundColor: "#DEE3EB",
        }}
        margin="normal"
        required
        placeholder="Dirección"
        type="text"
        autoComplete="address"
        onChange={(e) => {
          let eventLastName = e.target.value
            .toLowerCase()
            .normalize("NFD")
            .replace(
              /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
              "$1"
            );
          setClinicLocation(eventLastName);
        }}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
          style: {
            fontSize: "1.2em", // Cambia el tamaño de la fuente
            padding: "3%", // Aumenta el padding
          },
        }}
        inputProps={{
          style: {
            height: "90%", // Aumenta la altura
          },
        }}
      /> */}
      <TextField
        margin="normal"
        required
        hiddenLabel
        placeholder="RUT"
        variant="filled"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AssignmentIndOutlinedIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setRut(e.target.value)}
      />
      <TextField
        margin="normal"
        required
        hiddenLabel
        placeholder="Correo Electrónico"
        variant="filled"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon />
            </InputAdornment>
          ),
        }}
        onChange={(e) => setClinicEmail(e.target.value)}
      />

      <TextField
        type={showPass ? "password" : "text"}
        margin="normal"
        required
        hiddenLabel
        placeholder="Contraseña"
        variant="filled"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PasswordOutlinedIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment>
              <IconButton onClick={handleClickShowPassword}>
                {showPass ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={(e) => setClinicPassword(e.target.value)}
      />

      <Button
        onClick={() => saveClinic()}
        variant="contained"
        disableElevation
        sx={{
          mt: 3,
        }}
      >
        REGISTRAR CLINICA
      </Button>
      <br />
    </>
  );
}
