import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  capitalize,
  Divider,
  Box,
  Chip,
} from "@mui/material";
import {
  getTokenQueue,
  updateHourStatusToFree,
  updateRequestStatus,
} from "../database/request";
import { getChatId, createChat, activateChat } from "../database/chats";
import Swal from "sweetalert2";
import DialogCompletedRequest from "./dialog_complet_request";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Document from "@mui/icons-material/DocumentScanner";
import DialogPay from "./dialog_Pay";
import loadingSVG from "../assets/logo_Loading.gif";

import {
  Circle,
  DateRange,
  DateRangeOutlined,
  Hail,
  MailOutline,
  Close,
} from "@mui/icons-material";
import { capitalizeAll } from "../utils/functions";
import axios from "axios";
import {
  getInvoiceByIdRequest,
  saveInvoiceResponse,
} from "../database/invoices";
import DialogSendInvoice from "./dialog_send_invoice";
import { getDoctorById } from "../database/doctors";
import LoadingInvoice from "./loading_invoice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function DialogCalendar({
  open,
  close,
  data,
  handleOpened,
  loadData,
}) {
  const [name, setName] = useState("");
  const [openComplete, setOpenComplete] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);
  const [userData, setUserData] = useState({});
  const [loadingCreate, setLoadingCreate] = useState(false);
  const openModal = async () => {
    setPayModal(true);
  };
  const navigate = useNavigate();
  const actualUserData = useSelector((state) => state.auth.userData);

  const tokenSII = "";
  useEffect(() => {
    async function fetchData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const dataDoctorResponse = await getDoctorById(data.idDoctor);
      setUserData(dataDoctorResponse);
      setName(
        `${capitalize(data.client.name)} ${capitalize(data.client.lastName)}`
      );
      const response = await getInvoiceByIdRequest(data.id);
      if (response.length > 0) {
        setInvoices(response);
      } else {
        setInvoices([]);
      }
      Swal.close();
    }
    fetchData();
  }, []);

  const createNewChat = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const resChat = await createChat(data.idDoctor, data.idClient, data.id);
    const a = await updateRequestStatus(data.id, "waiting", data);
    Swal.close();
  };

  const openPdfInNewTab = (binaryData) => {
    try {
      const blob = new Blob([binaryData], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error al abrir el PDF:", error);
    }
  };

  const handleDownloadInvoice = async () => {
    const binaryData = atob(invoices[0].data.pdfBase64);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }

    const blob = new Blob([byteArray], { type: "application/pdf" });

    const url = URL.createObjectURL(blob);

    window.open(url);
  };

  const handleWaiting = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    try {
      const resChat = await getChatId(data.idDoctor, data.idClient);
      if (resChat == []) {
        Swal.close();
        Swal.fire({
          icon: "warning",
          title: "Crear chat",
          text: "No tiene chat con este paciente. ¿Desea crearlo?",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Aceptar",
          cancelButtonColor: "#d33",
          showCancelButton: true,
        }).then((response) => {
          if (response.isConfirmed) {
            createNewChat();
          } else {
            handleOpened();
          }
        });
      } else {
        if (resChat?.status === "inactive") {
          Swal.fire({
            icon: "info",
            title: "Chat inactivo",
            text: "El chat con este paciente está inactivo. ¿Desea activarlo?",
            cancelButtonText: "Cancelar",
            confirmButtonText: "Aceptar",
            cancelButtonColor: "#d33",
            showCancelButton: true,
          }).then(async (response) => {
            if (response.isConfirmed) {
              await activateChat(resChat.id);
              await updateRequestStatus(data.id, "waiting", data);
              handleOpened();
            } else {
              handleOpened();
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleComplete = async () => {
    const datosCodificados = encodeURIComponent(JSON.stringify(data));
    const nuevaPestana = window.open("", "_blank");
    setOpenComplete(false);
    nuevaPestana.location.href = `/patientEntry?data=${datosCodificados}`;
    close();
  };

  const handleCancel = async () => {
    try {
      Swal.fire({
        title: "¿Estás segura(o) de marcar al paciente como ausente?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Marcar como ausente",
      }).then((response) => {
        Swal.fire({
          imageUrl: loadingSVG,
          imageWidth: 300,
          imageHeight: 300,
          title: "Cargando...",
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        if (response.isConfirmed) {
          const res = updateRequestStatus(data.id, "clientAbsent", data);
          if (!res) {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "No se pudo marcar como ausente. Inténtalo nuevamente.",
            });
          } else {
            if (data.dateCitation.toDate() < new Date()) {
              Swal.fire({
                title: "¡Listo!",
                text: "Se ha marcado como ausente. Si ocurre muchas veces se penalizará al cliente.",
                icon: "success",
                showCancelButton: false,
                confirmButtonText: "Aceptar",
                allowOutsideClick: false,
                allowEscapeKey: false,
              }).then(() => {
                Swal.close();
                close();
                loadData();
              });
            } else {
              Swal.fire({
                icon: "warning",
                title: "¿Deseas liberar la hora?",
                text: "Si la liberas puedes asignarla a otra persona.",
                showCancelButton: true,
                cancelButtonText: "No, no liberar",
                confirmButtonText: "Sí, liberar",
              }).then(async (response) => {
                if (response.isConfirmed) {
                  Swal.fire({
                    imageUrl: loadingSVG,
                    imageWidth: 300,
                    imageHeight: 300,
                    title: "Cargando...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  });
                  const dateCitation = data.dateCitation.toDate(); // Suponiendo que data.dateCitation es un objeto de fecha de Firestore
                  const year = dateCitation.getFullYear();
                  const month = String(dateCitation.getMonth() + 1).padStart(
                    2,
                    "0"
                  ); // Sumamos 1 al mes porque los meses comienzan en 0
                  const day = String(dateCitation.getDate()).padStart(2, "0");
                  const dateString = `${year}-${month}-${day}`;
                  const res = await updateHourStatusToFree(
                    data.doctor.id,
                    data.request.date,
                    data.request.hour
                  );

                  const tokensResponse = await getTokenQueue(
                    data.doctor.mainSpecialty,
                    data.request.date
                  );

                  await axios.post(
                    "https://us-central1-dfinder-5f8ae.cloudfunctions.net/app/cancelRequest",
                    {
                      idDoctor: data.doctor.id,
                      nameDoctor: capitalizeAll(
                        data.doctor.name + " " + data.doctor.lastName
                      ),
                      hour: data.request.hour,
                      date: data.request.date,
                      type: data.doctor.mainSpecialty,
                      typeRequest: data.request.type,
                      tokens: tokensResponse,
                    }
                  );
                  if (!res) {
                    Swal.fire({
                      icon: "error",
                      title: "Error",
                      text: "No se pudo liberar la hora. Inténtalo nuevamente.",
                    });
                  } else {
                    close();
                    loadData();
                    Swal.fire({
                      icon: "success",
                      title: "¡Listo!",
                      text: "Se ha liberado la hora exitosamente.",
                    });
                  }
                } else {
                  Swal.close();
                  close();
                  loadData();
                }
              });
            }
          }
        } else {
          Swal.close();
          close();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGenerateBoleta = async () => {
    setLoadingCreate(true);
    const res = await axios.post(
      "https://us-central1-dfinder-5f8ae.cloudfunctions.net/generateBoletaSII",
      {
        data: {
          RutUsuario: "19473859-5",
          PasswordSII: "FIOV19473",
          Retencion: 1,
          FechaEmision: "09-04-2024",
          Emisor: {
            Direccion: "0",
          },
          Receptor: {
            Rut: "77.634.343-9",
            Nombre: "Inversiones Maxi Pos Spa",
            Direccion: "Aldunate 719 of 302 Temuco",
            Region: 9,
            Comuna: "Temuco",
          },
          Detalles: [
            {
              Nombre: "CONSULTORIA INFORMATICA",
              Valor: 10000,
            },
          ],
        },
        pass: userData.tokenSII ? userData.tokenSII : tokenSII,
      },
      {
        method: "POST",
        withCredentials: true,
        headers: {
          Authorization:
            "Bearer " + userData.tokenSII ? userData.tokenSII : tokenSII,
          "Content-Type": "application/json",
        },
      }
    );

    if (res.status === 200) {
      await saveInvoiceResponse(res.data, data.id);
      setLoadingCreate(false);
      Swal.fire({
        icon: "success",
        title: "¡Listo!",
        text: "Se ha generado la boleta exitosamente.",
      }).then(() => {
        Swal.close();
        close();
        loadData();
      });
    } else {
      setLoadingCreate(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No se pudo generar la boleta. Inténtalo nuevamente.",
      });
    }
  };

  return (
    <div style={{ position: "fixed" }}>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={close}
        PaperProps={{
          style: {
            backgroundColor: "#EEE8F4",
            borderRadius: "28px",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="titleLarge"
            sx={{
              color: "black",
            }}
          >
            Titular de la reserva: {name}
          </Typography>

          <Close onClick={close} style={{ cursor: "pointer" }} />
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                color: "#006686",
              }}
            >
              Estado de la reserva
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Chip
                label={
                  <Typography>
                    <Circle
                      sx={
                        data.request.payData.status === "pending"
                          ? {
                              m: 0,
                              p: 0,
                              fontSize: "10px",
                              color: "#FFB829",
                            }
                          : {
                              m: 0,
                              p: 0,
                              fontSize: "10px",
                              color: "green",
                            }
                      }
                    />{" "}
                    {data.request.payData.status === "pending"
                      ? "Esperando pago"
                      : "Pagado"}
                  </Typography>
                }
                variant="outlined"
              />
              <Chip
                label={
                  <Typography>
                    {data.request.payData.typePay === "Particular"
                      ? "Particular"
                      : data.request.payData.typePay === "Isapre"
                      ? `Isapre ${data.request.payData.isapre}`
                      : "Fonasa"}
                  </Typography>
                }
                variant="outlined"
              />
            </Box>
          </Box>
          <Container
            sx={{
              border: "1px solid #ccc",
              borderRadius: "28px",
              my: 2,
              pb: 2,
              pt: 1,
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#006686",
                  ml: 1,
                }}
              >
                Datos del titular
              </Typography>
            </Box>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Hail
                sx={{
                  fontSize: "25px",
                  marginRight: "5px",
                }}
              />
              <div>
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "12px",
                  }}
                >
                  Nombre del titular:
                </Typography>
                <Typography>{name}</Typography>
              </div>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Hail
                sx={{
                  fontSize: "25px",
                  marginRight: "5px",
                }}
              />
              <div>
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "12px",
                  }}
                >
                  Tipo
                </Typography>
                <Typography>
                  {data.request.type && data.request.type}
                </Typography>
              </div>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <DateRangeOutlined
                sx={{
                  fontSize: "25px",
                  marginRight: "5px",
                }}
              />
              <div>
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "12px",
                  }}
                >
                  Programada para
                </Typography>
                <Typography>
                  {data.dateCitation.toDate().toLocaleDateString() ===
                  new Date().toLocaleDateString()
                    ? `Hoy a las ${data.request.hour} hrs.`
                    : data.dateCitation.toDate().toLocaleDateString()}
                </Typography>
              </div>
            </div>
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              <Document
                sx={{
                  fontSize: "25px",
                  marginRight: "5px",
                }}
              />
              <div>
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "12px",
                  }}
                >
                  Detalles indicados por el paciente
                </Typography>
                <Typography>
                  {data.request.details ? data.request.details : "Sin detalles"}
                </Typography>
              </div>
            </div>
          </Container>

          <Container
            sx={{
              border: "1px solid #ccc",
              borderRadius: "28px",
              my: 1,
              pb: 2,
              pt: 2,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontSize: "15px",
                  color: "#006686",
                  ml: 1,
                }}
              >
                Datos del paciente
              </Typography>
              {data.request.isFamiliar && (
                <Chip
                  label="Familiar"
                  variant="filled"
                  sx={{
                    backgroundColor: "#E1E0FF",
                    borderRadius: "5px",
                    color: "#006686",
                    fontSize: "15px",
                    // fontWeight: "bold",
                  }}
                />
              )}
            </div>

            <br />
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "5px",
                  marginBottom: "10px",
                }}
              >
                <PersonOutlineOutlinedIcon
                  sx={{
                    fontSize: "25px",
                    marginRight: "5px",
                  }}
                />
                <div>
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "12px",
                    }}
                  >
                    Nombre
                  </Typography>
                  <Typography>
                    {data.request.isFamiliar
                      ? `${data.request.familiarData.name} ${data.request.familiarData.lastName}`
                      : data.client.name + " " + data.client.lastName}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "5px",
                  marginBottom: "10px",
                }}
              >
                <MailOutline
                  sx={{
                    fontSize: "25px",
                    marginRight: "5px",
                  }}
                />
                <div>
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "12px",
                    }}
                  >
                    Email
                  </Typography>
                  <Typography>
                    {capitalize(data.client.email ? data.client.email : "NA")}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "5px",
                  marginBottom: "10px",
                }}
              >
                <MailOutline
                  sx={{
                    fontSize: "25px",
                    marginRight: "5px",
                  }}
                />
                <div>
                  <Typography
                    sx={{
                      color: "grey",
                      fontSize: "12px",
                    }}
                  >
                    Dirección
                  </Typography>
                  <Typography>
                    {capitalize(
                      data.client.location.address +
                        ", " +
                        data.client.location.comuna
                    )}
                  </Typography>
                </div>
              </div>
            </div>
          </Container>
        </DialogContent>
        <Divider />
        <DialogActions>
          {data.request.payData.status === "pending" &&
          new Date(data.dateCitation.toDate().getTime() + 60 * 60 * 1000) >
            new Date() ? (
            <>
              <Button
                variant="text"
                onClick={() => {
                  handleCancel();
                }}
              >
                Se Ausentó
              </Button>
              <Button
                variant="text"
                onClick={() => {
                  openModal();
                }}
              >
                Ir a pagar
              </Button>
            </>
          ) : (
            <>
              {data.request.payData.status === "pending" &&
                new Date(
                  data.dateCitation.toDate().getTime() + 60 * 60 * 1000
                ) > new Date() && (
                  <Button
                    variant="text"
                    onClick={() => {
                      close();
                      handleWaiting();
                    }}
                  >
                    Esperar exámenes
                  </Button>
                )}

              <Button
                variant="text"
                onClick={() => {
                  handleCancel();
                }}
              >
                Se Ausentó
              </Button>
              {new Date(data.dateCitation.toDate().getTime() + 60 * 60 * 1000) >
                new Date() &&
                actualUserData.role !== "secretary" && (
                  <Button
                    variant="text"
                    onClick={() => {
                      if (data.request.type === "videollamada") {
                        console.log("datavideo call", data);
                        localStorage.setItem(
                          "videoCallData",
                          JSON.stringify(data)
                        );
                        navigate("/videocalls");
                      } else {
                        handleComplete();
                      }
                    }}
                  >
                    Atender
                  </Button>
                )}
              {invoices.length > 0 &&
                data.request.payData.typePay === "Particular" && (
                  <>
                    <Button
                      variant="text"
                      onClick={() => {
                        setSendEmail(true);
                      }}
                    >
                      Enviar boleta
                    </Button>
                    <Button
                      variant="text"
                      onClick={() => {
                        handleDownloadInvoice();
                      }}
                    >
                      Descargar boleta
                    </Button>
                  </>
                )}
              {invoices.length === 0 &&
                data.request.payData.typePay === "Particular" && (
                  <>
                    <Button
                      variant="text"
                      onClick={() => {
                        handleGenerateBoleta();
                      }}
                    >
                      Generar boleta
                    </Button>
                  </>
                )}
            </>
          )}
        </DialogActions>
      </Dialog>
      <DialogCompletedRequest
        loadData={loadData}
        open={openComplete}
        close={setOpenComplete}
        data={data}
        cerrar={close}
        handleOpened={handleOpened}
      />
      {/* <PatientEntry open={entrypatient} close={setEntrypatient} /> */}
      <DialogPay
        open={payModal}
        close={setPayModal}
        data={data}
        closeMain={close}
        loadData={loadData}
      />
      {sendEmail && (
        <DialogSendInvoice
          open={sendEmail}
          onClose={() => setSendEmail(false)}
          tokenSII={userData.tokenSII ? userData.tokenSII : tokenSII}
          invoices={invoices}
        />
      )}
      {
        <LoadingInvoice
          open={loadingCreate}
          onClose={() => setLoadingCreate(false)}
        />
      }
    </div>
  );
}

{
  /* <Button sx={{ fontSize: "12px" }}
                        onClick={() => {
                          handleDownloadInvoice();
                        }}
                      >
                        Descargar boleta
                      </Button> */
}

// {data.request.isFamiliar ?
//   `${data.request.familiarData.name} ${data.request.familiarData.lastName}` :
//   data.client.name + " " + data.client.lastName
// }
