import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import DrawerAdminComponent from "../components/drawer_admin";
import TopBarAdmin from "../components/top_bar_admin";
import { useSelector } from "react-redux";
import {
  getAllPendingChangeRequest,
  updateChangeRequest,
} from "../database/change_data_bank";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import imageUnselected from "../assets/request_select.svg";
import { capitalizeAll } from "../utils/functions";
import { ArrowRight } from "@mui/icons-material";

export default function AdminBankRequest() {
  const userData = useSelector((state) => state.auth.userData);
  const [selectedTab, setSelectedTab] = useState("pending");
  const [listChangeRequest, setListChangeRequest] = useState([]);
  const [requestSelected, setRequestSelected] = useState(null);
  useEffect(() => {
    const execute = async () => {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      try {
        const data = await getAllPendingChangeRequest();
        setListChangeRequest(
          data === listChangeRequest ? listChangeRequest : data
        );
        console.log(data);
        Swal.close();
      } catch (error) {
        console.log(error);
        Swal.close();
      }
    };

    execute();
  }, []);

  const handleStatusRequest = async (prop) => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    try {
      requestSelected.newStatus = prop;
      const a = await updateChangeRequest(requestSelected);
      if (a) {
        Swal.fire({
          icon: "success",
          title: "Solicitud actualizada",
          showConfirmButton: false,
          timer: 1500,
        });

        setRequestSelected(null);
      } else {
        Swal.fire({
          icon: "error",
          title: "Error al actualizar la solicitud",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      console.log("error", error);
      Swal.close();
    }
  };

  return (
    <Box
      flex={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="start"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "25px",
        }}
      >
        <div
          style={{
            height: "85vh",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <List
            sx={{
              padding: "0px",
              margin: "0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              maxWidth: "16vw",
              flex: 1,
              borderRight: "1px solid rgba(192, 199, 205, 1)",
            }}
          >
            <div
              style={{
                width: "100%",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                // borderBottom: '1px solid rgba(192, 199, 205, 1)'
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) => {
                  setSelectedTab(newValue);
                  // loadData(newValue);
                  // setDoctorSelected(null);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: 1,
                  width: "100%",
                  borderBottom: "1px solid rgba(192, 199, 205, 1)",
                }}
              >
                <Tab
                  value={"pending"}
                  sx={{
                    flex: 1,
                  }}
                  label="Pendientes"
                />
              </Tabs>
            </div>

            {listChangeRequest?.length === 0 && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flex={1}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    marginTop: "20px",
                    color: "#006686",
                    fontFamily: "Nunito",
                  }}
                >
                  No hay clientes {selectedTab.toLocaleLowerCase()}
                  registrados
                </Typography>
              </Box>
            )}

            {listChangeRequest &&
              listChangeRequest?.map((request, index) => {
                return (
                  <ListItem
                    key={request.id}
                    sx={{
                      height: "10%",
                      ":hover": { backgroundColor: "rgba(0, 102, 134, 0.6)" },
                      backgroundColor:
                        requestSelected?.idUser === request.idUser
                          ? "rgba(0, 102, 134, 0.9)"
                          : "white",
                    }}
                    onClick={() => {
                      setRequestSelected(request);
                    }}
                    button
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          border: "1px solid white",
                          backgroundColor:
                            requestSelected?.idUser === request.idUser
                              ? "rgba(0, 102, 134, 0.1)"
                              : "rgba(0, 102, 134, 0.3)",
                        }}
                        src={request.profesionalData.imageProfile}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primaryTypographyProps={{
                        style: {
                          fontSize: "1rem",
                          color:
                            requestSelected?.idUser === request.idUser
                              ? "#77F8E0"
                              : "black",
                        },
                      }}
                      secondaryTypographyProps={{
                        style: {
                          fontSize: "0.8rem",
                          color:
                            requestSelected?.idUser === request.idUser
                              ? "#77F8E0"
                              : "grey",
                        },
                      }}
                      primary={capitalizeAll(
                        `${request.profesionalData.name} ${request.profesionalData.lastName}`
                      )}
                      secondary={request.profesionalData.email}
                    />
                    <ListItemIcon>
                      <ArrowRight
                        sx={{
                          fontSize: "25px",
                          ml: 3,
                          color:
                            requestSelected?.idUser === request.idUser
                              ? "#77F8E0"
                              : "black",
                        }}
                      />
                    </ListItemIcon>
                  </ListItem>
                );
              })}
          </List>
          {requestSelected ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#006686",
                    fontFamily: "Nunito",
                    marginTop: "2vh",
                    marginLeft: "3vw",
                    textAlign: "start",
                    fontStyle: "normal",
                    fontWeight: "400",
                    marginBottom: "2vh",
                  }}
                >
                  Datos del usuario
                </Typography>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "47%",
                  }}
                >
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    margin="normal"
                    disabled
                    placeholder="Rut"
                    value={requestSelected.profesionalData.rut}
                    type="text"
                    variant="standard"
                    endad
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <FingerprintOutlinedIcon />
                      //   </InputAdornment>
                      // ),
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    variant="standard"
                    margin="normal"
                    disabled
                    value={requestSelected.profesionalData.name}
                    placeholder="Nombre"
                    type="text"
                    autoComplete="name"
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <PersonOutlinedIcon />
                      //   </InputAdornment>
                      // ),
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "47%",
                  }}
                >
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    margin="normal"
                    disabled
                    placeholder="Apellido"
                    type="text"
                    autoComplete="lastName"
                    value={requestSelected.profesionalData.lastName}
                    variant="standard"
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <CalendarMonthOutlined />
                      //   </InputAdornment>
                      // ),
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    margin="normal"
                    disabled
                    placeholder="Email"
                    type="text"
                    autoComplete="Email"
                    value={requestSelected.profesionalData.email}
                    variant="standard"
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <FlagOutlinedIcon />
                      //   </InputAdornment>
                      // ),
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#006686",
                    fontFamily: "Nunito",
                    marginTop: "2vh",
                    marginLeft: "3vw",
                    textAlign: "start",
                    fontStyle: "normal",
                    fontWeight: "400",
                    marginBottom: "3vh",
                  }}
                >
                  Datos para actualizar
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "47%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#006686",
                      fontFamily: "Nunito",
                      textAlign: "start",
                      fontStyle: "normal",
                      fontWeight: "200",
                    }}
                  >
                    Datos bancarios antiguos
                  </Typography>
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    margin="normal"
                    disabled
                    placeholder="Numero de cuenta"
                    value={requestSelected.data.oldBankAccount.numAccount}
                    type="text"
                    variant="standard"
                    endad
                    InputProps={{
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    margin="normal"
                    disabled
                    placeholder="tipo de cuenta"
                    value={
                      requestSelected.data.oldBankAccount.typeAccountSelected
                    }
                    type="text"
                    variant="standard"
                    endad
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <FingerprintOutlinedIcon />
                      //   </InputAdornment>
                      // ),
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    variant="standard"
                    margin="normal"
                    disabled
                    value={requestSelected.data.oldBankAccount.bankSelected}
                    placeholder="banco"
                    type="text"
                    InputProps={{
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "47%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#006686",
                      fontFamily: "Nunito",
                      textAlign: "start",
                      fontStyle: "normal",
                      fontWeight: "200",
                    }}
                  >
                    Datos bancarios nuevos
                  </Typography>
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    margin="normal"
                    disabled
                    placeholder="Numero de cuenta"
                    value={requestSelected.data.newBankAccount.numAccount}
                    type="text"
                    variant="standard"
                    endad
                    InputProps={{
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    margin="normal"
                    disabled
                    placeholder="tipo de cuenta"
                    value={
                      requestSelected.data.newBankAccount.typeAccountSelected
                    }
                    type="text"
                    variant="standard"
                    endad
                    InputProps={{
                      // startAdornment: (
                      //   <InputAdornment position="start">
                      //     <FingerprintOutlinedIcon />
                      //   </InputAdornment>
                      // ),
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    variant="standard"
                    margin="normal"
                    disabled
                    value={requestSelected.data.newBankAccount.bankSelected}
                    placeholder="banco"
                    type="text"
                    InputProps={{
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  width: "96%",
                  height: "100%",
                  marginTop: "30px",
                  marginBottom: "30px",
                  marginRight: "31vw",
                }}
              >
                <Button
                  variant="text"
                  sx={{ color: "#006686", width: "150px" }}
                  onClick={() => handleStatusRequest("declined")}
                >
                  Rechazar
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    background: "#006686",
                    color: "#77F8E0",
                    borderRadius: "25px 25px 25px 25px",
                    width: "150px",
                    marginLeft: "30px",
                  }}
                  onClick={() => {
                    handleStatusRequest("completed");
                  }}
                >
                  Aceptar
                </Button>
              </div>
            </div>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <img
                src={imageUnselected}
                alt="selectimage"
                style={{ alignSelf: "center" }}
              />
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontWeight: "bold",
                  color: "black",
                  fontFamily: "Nunito",
                }}
              >
                Selecciona una solicitud para revisarla
              </Typography>
            </Box>
          )}
        </div>
      </div>
    </Box>
  );
}
