import React, { useEffect, useState } from "react";
import {
  Container,
  MenuItem,
  TextField,
  Select,
  Typography,
  Box,
  Divider,
  Button,
  Grid,
  Chip,
  CardActions,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getDoctorById, updateDoctor } from "../database/doctors";
import loadingSVG from "../assets/logo_Loading.gif";
import Swal from "sweetalert2";
import { getClinicById } from "../database/clinic";
import {
  createChangeRequest,
  getChangeRequest,
} from "../database/change_data_bank";
import { Circle } from "@mui/icons-material";

export default function ProfileBankData() {
  const [dataDoctor, setDocData] = useState({});
  const myId = useSelector((state) => state.auth.idUser);
  const userData = useSelector((state) => state.auth.userData);

  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [bankTypeAccount, setBankTypeAccount] = useState("");
  const [nameAccount, setNameAccount] = useState("");
  const [lastnameAccount, setLastnameAccount] = useState("");
  const [emailAccount, setEmailAccount] = useState("");
  const [loading, setLoading] = useState(true);
  const [rut, setRut] = useState("");
  const [changeStatus, setChangeStatus] = useState(false);

  function formatearRut(rut) {
    // console.log("Rut sin formatear:", rut);
    // Eliminar cualquier caracter que no sea dígito o la letra 'k' si está al final
    rut = rut.replace(/[^\dkK]/g, "");

    // Separar el rut y el dígito verificador
    var rutSinDigito = rut.slice(0, -1);
    var digitoVerificador = rut.slice(-1).toUpperCase();

    // Formatear el rut con puntos y guión
    var rutFormateado = "";
    var contador = 0;
    for (var i = rutSinDigito.length - 1; i >= 0; i--) {
      rutFormateado = rutSinDigito.charAt(i) + rutFormateado;
      contador++;
      if (contador % 3 === 0 && contador !== rutSinDigito.length) {
        rutFormateado = "." + rutFormateado;
      }
    }

    // Agregar el guión y el dígito verificador
    rutFormateado += "-" + digitoVerificador;

    return rutFormateado;
  }

  function limpiarRut(rut) {
    // Eliminar puntos y guión
    return rut.replace(/[.-]/g, "");
  }

  useEffect(() => {
    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      try {
        let a = await getChangeRequest(myId);
        setChangeStatus(a);

        let res;
        if (userData.role === "doctor") {
          res = await getDoctorById(myId);
          setDocData(res);
          // console.log("Datos del médico:", res);
          setBank(res.bankAccount.bankSelected);
          setAccountNumber(res.bankAccount.numAccount);
          setLastnameAccount(res.lastName);
          setNameAccount(res.name);
          setRut(formatearRut(res.rut));
          setEmailAccount(res.email);
        } else if (userData.role === "medicalCenter") {
          let a = await getClinicById(myId);
          res = a[0];
          setDocData(res);
          // console.log("Datos del médico:", res);
          setBank(res.bankAccount.bankSelected);
          setAccountNumber(res.bankAccount.numAccount);
          setLastnameAccount(res.lastName);
          setNameAccount(res.name);
          setEmailAccount(res.email);
          setRut(res.rut ? formatearRut(res.rut) : null);
        }

        setBankTypeAccount(res.bankAccount.typeAccountSelected);
        setLoading(false);
        // console.log(
        //   "Datos del banco:",
        //   res.bankAccount.bankSelected,
        //   res.bankAccount.typeAccountSelected,
        //   res.bankAccount.numAccount
        // );
      } catch (error) {
        console.error("Error al obtener datos del médico:", error);
      }
      Swal.close();
    }
    getData();
  }, [myId]);

  const handleBank = (event) => {
    setBank(event.target.value);
  };

  const handleTypeAccount = (event) => {
    setBankTypeAccount(event.target.value);
  };

  const handleSave = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const countEmpty = [];

    const data = {
      newBankAccount: {
        bankSelected: bank,
        numAccount: accountNumber,
        typeAccountSelected: bankTypeAccount,
      },
      oldBankAccount: dataDoctor.bankAccount,
    };
    if (data.oldBankAccount.bankSelected === data.newBankAccount.bankSelected) {
      countEmpty.push("banco");
    }
    if (data.oldBankAccount.numAccount === data.newBankAccount.numAccount) {
      countEmpty.push("numero de cuenta");
    }
    if (
      data.oldBankAccount.typeAccountSelected ===
      data.newBankAccount.typeAccountSelected
    ) {
      countEmpty.push("tipo de cuenta");
    }

    if (countEmpty.length === 3) {
      Swal.fire({
        icon: "warning",
        title: "¡Atención!",
        text: "No se han realizado cambios. Por favor, modifica al menos un campo.",
      });
    } else {
      const res = await createChangeRequest({
        idUser: myId,
        data,
        status: "pending",
      });
      if (res) {
        Swal.fire({
          icon: "success",
          title: "¡Solicitud enviada!",
          text: "Tu solicitud de cambio de datos bancarios ha sido enviada con éxito. Pronto nos pondremos en contacto contigo.",
        }).then(() => {
          setChangeStatus(true);
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "¡Error!",
          text: "Ha ocurrido un error al enviar tu solicitud. Por favor, intenta nuevamente.",
        });
      }
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 0,
        height: "100%",
      }}
    >
      {loading ? (
        <></>
      ) : (
        <>
          {changeStatus && (
            <div
              style={{
                maxWidth: "100%",
                height: "20px",
                padding: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                borderRadius: "21px",
                marginTop: "16px",
              }}
            >
              <Chip
                label={"Procesando solicitud de cambio de datos bancarios"}
                icon={
                  <Circle
                    sx={{ marginRight: "10px", color: "orange !important" }}
                  />
                }
              />
            </div>
          )}
          <Box
            overflowY="scroll"
            sx={{
              p: 2,
              pb: 12,
              flexGrow: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                py: 2,
                mt: 2,
              }}
            >
              <Typography variant="titleSmall">Datos bancarios</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoFocus={!rut}
                  placeholder="RUT de titular"
                  variant="filled"
                  type="text"
                  id="rut"
                  label="RUT del titular"
                  disabled
                  fullWidth
                  name="rut"
                  value={rut}
                  onChange={(e) => setRut(formatearRut(e.target.value))}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Número de cuenta"
                  variant="filled"
                  id="numberAccount"
                  label="Número de cuenta"
                  name="numberAccount"
                  fullWidth
                  defaultValue={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  placeholder="Selecciona tu banco"
                  variant="filled"
                  id="select-bank"
                  value={bank}
                  label="Banco"
                  fullWidth
                  onChange={handleBank}
                >
                  <MenuItem key="selecciona tipo" value="">
                    Selecciona un banco
                  </MenuItem>
                  {bankOptions.map((option, index) => (
                    <MenuItem
                      sx={{
                        backgroundColor:
                          index % 2 === 0
                            ? "rgba(248, 249, 251, 1)"
                            : "rgba(220, 227, 233, 1)",
                      }}
                      key={index}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select
                  placeholder="Selecciona tu tipo de cuenta"
                  variant="filled"
                  id="select-type"
                  value={bankTypeAccount}
                  label="Tipo de cuenta"
                  fullWidth
                  onChange={handleTypeAccount}
                >
                  <MenuItem key="selecciona banco" value="">
                    Selecciona un tipo de cuenta
                  </MenuItem>
                  {typeAccountOptions.map((option, index) => (
                    <MenuItem
                      sx={{
                        backgroundColor:
                          index % 2 === 0
                            ? "rgba(248, 249, 251, 1)"
                            : "rgba(220, 227, 233, 1)",
                      }}
                      key={index}
                      value={option.value}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Box
              sx={{
                display: "flex",
                py: 2,
                mt: 2,
              }}
            >
              <Typography variant="titleSmall">Datos de la cuenta</Typography>
            </Box>
            <Grid container spacing={2} sx={{}}>
              <Grid item xs={12} sm={6}>
                <TextField
                  placeholder="Nombre de titular"
                  variant="filled"
                  id="name"
                  type="text"
                  label="Nombre"
                  disabled
                  name="name"
                  fullWidth
                  value={nameAccount}
                  onChange={(e) => {
                    let eventName = e.target.value
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(
                        /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
                        "$1"
                      );
                    setNameAccount(eventName);
                  }}
                />
              </Grid>
              {userData.role !== "medicalCenter" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="Apellido de titular"
                    variant="filled"
                    type="text"
                    disabled
                    id="lastname"
                    value={lastnameAccount}
                    label="Apellido"
                    name="lastname"
                    fullWidth
                    onChange={(e) => {
                      let eventName = e.target.value
                        .toLowerCase()
                        .normalize("NFD")
                        .replace(
                          /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
                          "$1"
                        );
                      setLastnameAccount(eventName);
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{
                    width: "100%",
                    backgroundColor: "rgba(220, 227, 233, 1)",
                  }}
                  placeholder="Email de titular"
                  variant="filled"
                  type="email"
                  id="email"
                  value={emailAccount}
                  onChange={(e) => setEmailAccount(e.target.value)}
                  label="Correo electrónico"
                  disabled
                  name="email"
                />
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Box
        sx={{
          zIndex: "200",
          width: "100%",
          backgroundColor: "white",
          position: "sticky",
          bottom: { xs: "0px", sm: "0px", md: "60px" },
        }}
      >
        <Divider />
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            py: 3,
            px: 2,
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              if (!changeStatus) {
                handleSave();
              } else {
                Swal.fire({
                  icon: "warning",
                  title: "¡Atención!",
                  text: "Ya has enviado una solicitud de cambio de datos bancarios. Por favor, espera a que sea procesada.",
                });
              }
            }}
          >
            Enviar solicitud de cambio
          </Button>
        </CardActions>
      </Box>
    </Box>
  );
}
const bankOptions = [
  { value: "Banco estado", label: "Banco estado" },
  { value: "Banco Chile/Edwards", label: "Banco Chile/Edwards" },
  { value: "Banco Internacional", label: "Banco Internacional" },
  { value: "Banco Scotiabank", label: "Banco Scotiabank" },
  { value: "Banco BCI", label: "Banco BCI" },
  { value: "Banco do Brasil S.A.", label: "Banco do Brasil S.A." },
  { value: "Corpbanca", label: "Corpbanca" },
  { value: "Banco BICE", label: "Banco BICE" },
  { value: "Banco HSBC Bank", label: "Banco HSBC Bank" },
  { value: "Banco Santander", label: "Banco Santander" },
  { value: "Banco ITAU", label: "Banco ITAU" },
  { value: "Banco Security", label: "Banco Security" },
  { value: "Banco Falabella", label: "Banco Falabella" },
  { value: "Banco Ripley", label: "Banco Ripley" },
  { value: "Banco Security", label: "Banco Security" },
  { value: "RaboBank", label: "RaboBank" },
  { value: "Banco Consorcio", label: "Banco Consorcio" },
  { value: "Banco Paris", label: "Banco Paris" },
  { value: "BBVA", label: "BBVA" },
  { value: "Banco del Desarrollo", label: "Banco del Desarrollo" },
  { value: "Coopeuch", label: "Coopeuch" },
  { value: "Prepago Los Heroes", label: "Prepago Los Heroes" },
  { value: "Tenpo Prepago", label: "Tenpo Prepago" },
  { value: "TAPP Caja Los Andes", label: "TAPP Caja Los Andes" },
  { value: "Copec Pay", label: "Copec Pay" },
  { value: "Mercado Pago", label: "Mercado Pago" },
];
const typeAccountOptions = [
  { value: "Cuenta vista", label: "Cuenta vista" },
  { value: "Cuenta corriente", label: "Cuenta corriente" },
  { value: "Cuenta de ahorro", label: "Cuenta de ahorro" },
];
