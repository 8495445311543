import {
  addDoc,
  collection,
  doc,
  updateDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
  deleteDoc,
  limit,
} from "firebase/firestore";
import { db } from "./config";
import { getUserById } from "./users";
import { sendNotificationFCM } from "./notifications";

export async function getChatId(idDoc, idUser) {
  const chat = await getDocs(
    query(collection(db, "chats"), where("ids", "array-contains-any", [idUser]))
  );


  if (chat.docs.length === 0) {
    return [];
  } else {
    let id;
    for (let doc of chat.docs) {
      if (doc.data().ids.includes(idUser) && doc.data().ids.includes(idDoc)) {
        id = {
          id: doc.id,
          ...doc.data(),
        };
        break; // Terminamos el bucle una vez que encontramos el ID
      } else {
        id = []; // Si no se encuentra el ID, establecemos id como null
      }
    }
    return id;
  }
}

export async function getChatsUnread(idUser) {
  let totalChatsNoLeidos = 0;
  const chat = await getDocs(
    query(collection(db, "chats"), where("ids", "array-contains-any", [idUser]))
  );

  for (const chatDoc of chat.docs) {
    // Consulta la colección de mensajes para este chat y sort por fecha descendente
    const mensajesSnapshot = await getDocs(
      query(
        collection(db, "chats", chatDoc.id, "messages"),
        orderBy("createdAt", "desc"),
        limit(1),
        where("idReceiver", "==", idUser)
      )
    );

    // console.log('mensajes', mensajesSnapshot);

    // Verifica si hay mensajes y si el último mensaje no está visto
    if (mensajesSnapshot.docs.length > 0) {
      const ultimoMensaje = mensajesSnapshot.docs[0].data();
      if (!ultimoMensaje.viewed) {
        totalChatsNoLeidos++;
      }
    }
  }

  return totalChatsNoLeidos;
}

export async function createChat(idDoc, idUser, idRequest) {
  const data = {
    status: "active",
    ids: [idUser, idDoc],
    createdAt: serverTimestamp(),
    lastMessageDate: new Date(),
    idRequest: idRequest,
  };
  const queryData = collection(db, "chats");
  const resAddDoc = await addDoc(queryData, data);
  const parentDocRef = doc(db, "chats", resAddDoc.id);
  const messagesCollectionRef = collection(parentDocRef, "messages");
  const a = await addDoc(messagesCollectionRef, {
    type: "initMessage",
    message: "Chat abierto por el doctor",
    idSender: "",
    idReceiver: "",
    createdAt: serverTimestamp(),
  });

  return true;
}

export async function markMessagesAsRead(chatId, idUser) {
  const messagesRef = collection(db, "chats", chatId, "messages");
  const docRefRoot = doc(db, "chats", chatId);

  try {
    // Obtener los mensajes no leídos
    const querySnapshot = await getDocs(
      query(
        messagesRef,
        where("viewed", "==", false),
        where("idReceiver", "==", idUser)
      )
    );

    // Marcar los mensajes como leídos
    querySnapshot.forEach(async (doc) => {
      const docRef = doc.ref;
      await updateDoc(docRef, { viewed: true });
    });

    await updateDoc(docRefRoot, { viewed: true });
    // console.log("Mensajes marcados como leídos correctamente.");
    return true;
  } catch (error) {
    console.error("Error al marcar mensajes como leídos:", error);
    return false;
  }
}

export async function getMessagesUnread(chatId, myId) {
  const messagesRef = collection(db, "chats", chatId, "messages");
  const querySnapshot = await getDocs(
    query(
      messagesRef,
      where("viewed", "==", false),
      where("idReceiver", "==", myId)
    )
  );

  const messages = [];

  querySnapshot.forEach((doc) => {
    messages.push({
      id: doc.id,
      ...doc.data(),
    });
  });

  return messages;
}

export async function deleteChat(chatId) {
  try {
    // Referencia al documento del chat
    const chatDocRef = doc(db, "chats", chatId);

    // Eliminar el documento del chat
    await deleteDoc(chatDocRef);

    return true;
  } catch (error) {
    console.error("Error al eliminar el chat:", error);
    return false;
  }
}

export async function deactivateChat(chatId) {
  const chatDocRef = doc(db, "chats", chatId);

  try {
    await updateDoc(chatDocRef, {
      status: "inactive",
    });

    return true;
  } catch (error) {
    console.error("Error updating chat status:", error);
    return false;
  }
}
export async function activateChat(chatId, idRequest) {
  const chatDocRef = doc(db, "chats", chatId);

  try {
    await updateDoc(chatDocRef, {
      status: "active",
    });

    return true;
  } catch (error) {
    console.error("Error updating chat status:", error);
    return false;
  }
}
export const streamChatList = (doctorId, setStateFunction) => {
  const unsubscribe = db
    .collection("chats")
    .where("ids", "array-contains", doctorId)
    .onSnapshot((querySnapshot) => {
      const chatList = [];
      querySnapshot.forEach((doc) => {
        chatList.push({
          id: doc.id,
          ...doc.data(),
        });
      });
      setStateFunction(chatList);
    });

  return () => unsubscribe();
};

export async function getChatList(userId, setData) {
  // console.log('id', userId);
  const chatListRef = collection(db, "chats");
  const chatListQuery = query(
    chatListRef,
    where("ids", "array-contains", userId)
  );
  const chatListSnapshot = await getDocs(chatListQuery);
  const chatList = [];
  for (const chatDoc of chatListSnapshot.docs) {
    const chatData = chatDoc.data();

    //   const otherUserId = chatData.users.find((id) => id !== userId);
    // console.log("estos son los datos del chat", chatDoc.id);

    const otherUserDoc = await getUserById(chatData.ids[0]);

    if (chatData.status !== "inactive") {
      chatList.push({
        idChats: chatDoc.id,
        client: otherUserDoc,
        doctorId: userId,
        viewed: chatData.viewed,
        idRequest: chatData.idRequest,
      });
    }
  }

  // console.log(chatList); // aquí puedes hacer lo que necesites con la lista de chats actualizada en tiempo real

  setData(chatList);
  return chatList;
}

export async function getMessages(idChat, setData) {
  // console.log('call this');
  const queryA = query(
    collection(db, "chats", idChat, "messages"),
    orderBy("createdAt", "asc")
  );

  const unsubscribe = onSnapshot(queryA, (querySnapshot) => {
    const updatedData = [];

    querySnapshot.forEach((doc) => {
      updatedData.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    setData(updatedData);
    // console.log(updatedData);
  });

  return () => unsubscribe();
}

export async function sendMessageChat(message, idChat, myId, idClient) {
  // console.log("enviar mensaje", message);
  // console.log("Id chat", idChat);
  // console.log("Id cliente", idClient);
  // console.log("Mi id", myId);
  const chatRef = collection(db, "chats", idChat, "messages");
  const ref = doc(db, "chats", idChat);
  await updateDoc(ref, {
    lastMessageDate: new Date(),
  });
  await addDoc(chatRef, {
    type: "text",
    message: message,
    idReceiver: idClient,
    idSender: myId,
    viewed: false,
    createdAt: serverTimestamp(),
  });
  await sendNotificationFCM(
    {
      title: "Nuevo mensaje",
      body: message,
    },
    idClient,
    "newMessage"
  );
  return true;
}
