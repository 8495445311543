import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DialogActions } from '@mui/material';
import axios from 'axios';
import loadingSVG from "../assets/logo_Loading.gif";
import Swal from 'sweetalert2';
import { generatePDFCertificado } from "../utils/generate_pdfs";


function DialogPDFs({ type, open, handleClose, valor, dataDoctor }) {
    const [textValue, setTextValue] = useState('');
    const [box, setBox] = useState('');
    const [indicaciones, setIndicaciones] = useState('');
    const [detalles, setDetalles] = useState('');
    const [medicines, setMedicines] = useState('');

    const handleChange = (event) => {
        setTextValue(event.target.value);
    };

    const handleMedicines = (event) => {
        setMedicines(event.target.value);
    }

    const handleBox = (event) => {
        setBox(event.target.value);
    }

    const handleIndicaciones = (event) => {
        setIndicaciones(event.target.value);
    }

    const handleDetalles = (event) => {
        setDetalles(event.target.value);
    }

    async function generateAlta(box, indicaciones, detalles) {
        try {
            Swal.fire({
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
            const response = await axios.post(
                "http://186.64.123.120:3005/generateAlta",
                {
                    indications: indicaciones,
                    otherData: detalles,
                    box: box,
                    userData: valor.client,
                    doctorData: valor.doctor,
                    waterMark: dataDoctor.logo
                },
                { responseType: "arraybuffer" } // Indica que esperas un buffer en la respuesta
            );

            // Crear el Blob a partir del buffer de la respuesta
            const blob = new Blob([response.data], {
                type: "application/pdf",
            });
            // Crear el enlace de datos a partir del Blob
            const dataUri = URL.createObjectURL(blob);
            handleClose();
            Swal.close();
            // Abrir el enlace en una nueva pestaña
            window.open(
                dataUri,
                "_blank",
                "noopener noreferrer"
            );
        } catch (error) {
            console.error("Error al obtener el PDF:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Ocurrió un error al obtener el PDF",
            });
        }
    }

    async function generateCertificado(text) {
        Swal.fire({
            imageUrl: loadingSVG,
            imageWidth: 300,
            imageHeight: 300,
            title: "Cargando...",
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
        });
        try {
            await generatePDFCertificado(valor.client, valor.doctor, valor.request, text, dataDoctor.logo);
            // const response = await axios.post(
            //     "http://186.64.123.120:3005/generateCertificado",
            //     {
            //         text: text,
            //         userData: valor.client,
            //         doctorData: valor.doctor,
            //         waterMark: dataDoctor.logo
            //     },
            //     { responseType: "arraybuffer" } // Indica que esperas un buffer en la respuesta
            // );

            // // Crear el Blob a partir del buffer de la respuesta
            // const blob = new Blob([response.data], {
            //     type: "application/pdf",
            // });

            // // Crear el enlace de datos a partir del Blob
            // const dataUri = URL.createObjectURL(blob);
            handleClose();
            Swal.close();
        } catch (error) {
            console.error("Error al obtener el PDF:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Ocurrió un error al obtener el PDF",
            });
        }
    }

    async function generateReceta(medicines) {
        try {
            Swal.fire({
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
            const logo = dataDoctor.logo;
            const response = await axios.post(
                "http://186.64.123.120:3005/generateReceta",
                {
                    medicines: medicines,
                    userData: valor.client,
                    doctorData: valor.doctor,
                    waterMark: logo
                },
                { responseType: "arraybuffer" } // Indica que esperas un buffer en la respuesta
            );

            // Crear el Blob a partir del buffer de la respuesta
            const blob = new Blob([response.data], {
                type: "application/pdf",
            });

            // Crear el enlace de datos a partir del Blob
            const dataUri = URL.createObjectURL(blob);
            handleClose();
            Swal.close();

            // Abrir el enlace en una nueva pestaña
            window.open(
                dataUri,
                "_blank",
                "noopener noreferrer"
            );
        } catch (error) {
            console.error("Error al obtener el PDF:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Ocurrió un error al obtener el PDF",
            });
        }
    }

    return (
        type === "certificado" ? (
            <Dialog open={open} onClose={handleClose} PaperProps={{
                style: {
                    backgroundColor: "#EEE8F4",
                    borderRadius: "25px 25px 25px 25px",
                    width: "50vw",
                    heigh: "auto",
                }
            }}>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        Indica un detalle:
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="text"
                        label="Detalle"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={textValue}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="text">Cancelar</Button>
                    <Button onClick={() => {
                        generateCertificado(textValue)
                    }} variant="text">Generar</Button>
                </DialogActions>
                <br />
            </Dialog>
        ) : type === 'alta' ? (
            <Dialog open={open} onClose={handleClose} PaperProps={{
                style: {
                    backgroundColor: "#EEE8F4",
                    borderRadius: "25px 25px 25px 25px",
                    width: "50vw",
                    heigh: "auto",
                }
            }}>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        Ingresa el box, las indicaciones y los detalles:
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="text"
                        label="Box"
                        type="text"
                        fullWidth
                        value={box}
                        onChange={handleBox}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="text"
                        label="Indicaciones"
                        type="text"
                        fullWidth
                        multiline
                        rows={3}
                        value={indicaciones}
                        onChange={handleIndicaciones}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="text"
                        label="Detalles"
                        type="text"
                        fullWidth
                        multiline
                        rows={3}
                        value={detalles}
                        onChange={handleDetalles}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="text">Cancelar</Button>
                    <Button onClick={() => {
                        generateAlta(box, indicaciones, detalles);
                    }} variant="text">Generar</Button>
                </DialogActions>
                <br />
            </Dialog>
        ) : (
            <Dialog open={open} onClose={handleClose} PaperProps={{
                style: {
                    backgroundColor: "#EEE8F4",
                    borderRadius: "25px 25px 25px 25px",
                    width: "50vw",
                    heigh: "auto",
                }
            }}>
                <DialogContent>
                    <Typography variant="body1" gutterBottom>
                        Indica al paciente las medicinas que debe tomar:
                    </Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="text"
                        label="Medicinas"
                        type="text"
                        fullWidth
                        multiline
                        rows={4}
                        value={medicines}
                        onChange={handleMedicines}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="text">Cancelar</Button>
                    <Button onClick={() => {
                        generateReceta(medicines)
                    }} variant="text">Generar</Button>
                </DialogActions>
                <br />
            </Dialog>
        )
    );
}

export default DialogPDFs;
