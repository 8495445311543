import { db } from "./config";
import {
  doc,
  updateDoc,
  collection,
  query,
  addDoc,
  serverTimestamp,
  where,
  getDocs,
  deleteDoc,
  orderBy,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";

export async function getSales(myId) {
  // console.log("myId", myId);
  const mySales = await getDocs(
    query(
      collection(db, "sales"),
      where("idUser", "==", myId),
      orderBy("createdAt", "desc")
    )
  );
  // console.log("mySales", mySales);
  return mySales.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export async function deleteSale(id) {
  try {
    const salesRef = doc(db, "sales", id);
    await deleteDoc(salesRef);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateSale(id, newData) {
  // console.log("newData", newData);
  // console.log("id", id);
  try {
    const salesRef = doc(db, "sales", id);
    await updateDoc(salesRef, newData);
    // console.log('hecho');
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function createSale(dataSale) {
  dataSale.createdAt = serverTimestamp();
  const queryData = query(collection(db, "sales"));
  await addDoc(queryData, dataSale);
  return true;
}

export async function uploadSale(file, data) {
  try {
    if (file === null) {
      data.url = "default";
      await createSale(data);
      return true;
    } else {
      const storageRef = firebase.storage().ref();
      const imageRef = storageRef.child(`images/${file.name}-${Date.now()}`);
      // Sube la imagen a Firebase Storage
      await imageRef.put(file);

      // Obtiene la URL de descarga de la imagen
      const url = await imageRef.getDownloadURL();
      // Retorna la URL de descarga
      data.url = url;
      await createSale(data);
      return true;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}
