import React, { useEffect, useState } from "react";
import TopBar from "../components/top_bar";
import { useSelector } from "react-redux";
import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Card,
  ListItemText,
  Typography,
  Container,
  Box,
} from "@mui/material";
import {
  ArrowBack,
  ArrowRight,
  FileOpenOutlined,
  ImageOutlined,
  TimerTwoTone,
} from "@mui/icons-material";
import { getMedicalRecordsByDoctor } from "../database/medical_records";
import { getUserById } from "../database/users";
import userMaleImage from "../assets/user_male_light.png";
import userFemaleImage from "../assets/user_female_light.png";
import { capitalize, capitalizeAll } from "../utils/functions";
import Swal from "sweetalert2";
import { getRequestById } from "../database/request";
import DialogMedicalData from "../components/dialog_medical_data";
// import NewDrawerComponent from "../components/new_drawer";
import loadingSVG from "../assets/logo_Loading.gif";
import NavPaper from "../components/nav_paper";

export default function MedicalRecords() {
  const myId = useSelector((state) => state.auth.idUser);
  const dataDoctor = useSelector((state) => state.auth.userData);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [selectedUserMedicalRecord, setSelectedUserMedicalRecord] =
    useState(null);
  const [selectedMedicalRecord, setSelectedMedicalRecord] = useState(null);
  const [medicalRecordsById, setMedicalRecordsById] = useState([]);
  const [selectedRequestData, setSelectedRequestData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [name, setName] = useState("");
  const [showList, setShowList] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const puntoDeQuiebre = 1300;
  const breakVariable = windowWidth >= puntoDeQuiebre;

  useEffect(() => {
    const getMedicalRecords = async () => {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const medicalRecords = await getMedicalRecordsByDoctor(myId);

      // Agrupar los registros médicos por idUser
      const medicalRecordsById = medicalRecords.reduce((acc, medicalRecord) => {
        if (medicalRecord.type === "forPage") {
          if (!acc[medicalRecord.client.rut]) {
            acc[medicalRecord.client.rut] = {
              id: medicalRecord.client.rut,
              medicalRecords: [],
              type: "forPage",
              client: medicalRecord.client,
            };
          }
          acc[medicalRecord.client.rut].medicalRecords.push(medicalRecord);
          acc[medicalRecord.client.rut].medicalRecords.sort((a, b) => {
            return b.createdAt.toDate() - a.createdAt.toDate();
          });
          return acc;
        } else {
          const { idUser, id } = medicalRecord;

          // Si aún no existe un grupo para este idUser, crear uno
          if (!acc[idUser]) {
            acc[idUser] = {
              id: idUser,
              medicalRecords: [],
            };
          }
          // Agregar el registro médico al grupo correspondiente
          acc[idUser].medicalRecords.push(medicalRecord);
          acc[idUser].medicalRecords.sort((a, b) => {
            return b.createdAt.toDate() - a.createdAt.toDate();
          });
          return acc;
        }
      }, {});

      // Convertir el objeto en un array de valores
      const medicalRecordsArray = Object.values(medicalRecordsById);

      // Obtener datos del usuario para cada idUser
      for (const record of medicalRecordsArray) {
        if (record.type === "forPage") {
          record.userData = record.client;
        } else {
          const userData = await getUserById(record.id); // Suponiendo que tienes una función getUserData que recupera los datos del usuario por su id
          record.userData = userData;
        }
      }

      // Actualizar el estado
      setMedicalRecords(medicalRecords);
      setMedicalRecordsById(medicalRecordsArray);
      Swal.close();
    };

    getMedicalRecords();

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const paperNav = (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
      }}
    >
      {medicalRecordsById.length === 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              marginTop: "20px",
              color: "#006686",
              fontFamily: "Nunito",
            }}
          >
            No hay fichas médicas
          </Typography>
        </div>
      ) : selectedUserMedicalRecord == null ? (
        medicalRecordsById.map((medicalRecord, index) => (
          <ListItem
            key={index}
            onClick={() => {
              setSelectedUserMedicalRecord(medicalRecord);
              setName(
                `${capitalizeAll(medicalRecord.userData.name)} ${capitalizeAll(
                  medicalRecord.userData.lastName
                )}`
              );
              setSelectedMedicalRecord(null);
            }}
            sx={{
              hover: {
                backgroundColor: "black",
              },
              display: "flex",
              color:
                selectedUserMedicalRecord?.id === medicalRecord.id
                  ? "#5154B2"
                  : "black",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-between",
              backgroundColor:
                selectedUserMedicalRecord?.id == medicalRecord.id
                  ? "#E1E0FF"
                  : "transparent",
              cursor: "pointer",
              borderBottom: "1px solid rgba(192, 199, 205, 1)",
            }}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: { md: "40px", lg: "48px" },
                  height: { md: "40px", lg: "48px" },
                  mr: 2,
                }}
                src={
                  medicalRecord.userData.imageProfile === "default" &&
                  medicalRecord.userData.genre === "Masculino"
                    ? userMaleImage
                    : medicalRecord.userData.imageProfile == "default" &&
                      medicalRecord.userData.genre == "Femenino"
                    ? userFemaleImage
                    : medicalRecord.userData.imageProfile
                }
              />
            </ListItemAvatar>
            <ListItemText
              primary={`${capitalizeAll(
                medicalRecord.userData.name
              )} ${capitalizeAll(medicalRecord.userData.lastName)}`}
              secondary={`${medicalRecord.medicalRecords.length} ficha(s) médica(s)`}
            />
            <ArrowRight />
          </ListItem>
        ))
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: 2,
              width: "100%",
              height: "62px",
            }}
          >
            <IconButton
              onClick={() => {
                setSelectedUserMedicalRecord(null);
                setName("");
                setSelectedMedicalRecord(null);
              }}
            >
              <ArrowBack />
            </IconButton>
            <div>
              {breakVariable && (
                <Typography
                  sx={{
                    textAlign: "start",
                    marginTop: "1px",
                    fontFamily: "Nunito",
                    fontWeight: "bold",
                    marginX: "10px",
                  }}
                >
                  {name}
                </Typography>
              )}
              <Typography
                sx={{
                  textAlign: "start",
                  marginTop: "1px",
                  fontFamily: "Nunito",
                  marginX: "10px",
                  width: "100%",
                }}
              >
                {selectedUserMedicalRecord.medicalRecords.length} registros
              </Typography>
            </div>
          </div>

          <List
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              overflowX: "hidden",
            }}
          >
            {selectedUserMedicalRecord.medicalRecords.map(
              (medicalRecord, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={async () => {
                    try {
                      Swal.fire({
                        imageUrl: loadingSVG,
                        imageWidth: 300,
                        imageHeight: 300,
                        title: "Cargando...",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                      });
                      const request = await getRequestById(
                        medicalRecord.idRequest
                      );
                      setSelectedRequestData(request);
                      setSelectedMedicalRecord(medicalRecord);
                      setShowList(false);
                      Swal.close();
                    } catch (error) {
                      console.log(error);
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Ocurrió un error al obtener la información de la ficha médica",
                      });
                    }
                  }}
                  sx={{
                    width: "100%",
                    display: "flex",
                    color:
                      selectedMedicalRecord?.id === medicalRecord.id
                        ? "rgba(81, 84, 178, 1)"
                        : "black",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: 2,
                    backgroundColor:
                      selectedMedicalRecord?.id == medicalRecord.id
                        ? "#E1E0FF"
                        : "white",
                    cursor: "pointer",
                    borderBottom: "1px solid rgba(192, 199, 205, 1)",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyItems: "start",
                        justifyContent: "space-between",
                      }}
                    >
                      <ListItemText
                        primary={`Dr. ${capitalizeAll(
                          dataDoctor.name
                        )} ${capitalizeAll(dataDoctor.lastName)}`}
                        secondary={`#${medicalRecord.numberRecord}`}
                        sx={{
                          margin: 0,
                          color:
                            selectedMedicalRecord?.id === medicalRecord.id
                              ? "#5154B2"
                              : "#191C1E",
                          fontFamily: "Nunito",
                        }}
                        primaryTypographyProps={{
                          fontSize: { xs: "1rem", lg: "1.02rem" },
                          fontWeight: "700",
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "grey",
                            fontSize: "0.75rem",
                          }}
                        >
                          {`${medicalRecord.createdAt
                            .toDate()
                            .toLocaleDateString("es-CL", {
                              weekday: "short",
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              /* second: "numeric", */
                            })}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "grey",
                            fontSize: "0.75rem",
                          }}
                        >
                          {`${medicalRecord.createdAt
                            .toDate()
                            .toLocaleDateString("es-CL", {
                              hour: "numeric",
                              minute: "numeric",
                              /* second: "numeric", */
                            })}`}
                        </Typography>
                      </Box>
                    </div>
                  </Box>
                </ListItem>
              )
            )}
          </List>
        </div>
      )}
    </List>
  );

  const paperContentHeader = selectedMedicalRecord && (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Avatar
        sx={{
          width: { md: "40px", lg: "48px" },
          height: { md: "40px", lg: "48px" },
        }}
        src={
          selectedUserMedicalRecord?.userData.imageProfile === "default" &&
          selectedUserMedicalRecord?.userData.genre === "Masculino"
            ? userMaleImage
            : selectedUserMedicalRecord?.userData.imageProfile == "default" &&
              selectedUserMedicalRecord?.userData.genre == "Femenino"
            ? userFemaleImage
            : selectedUserMedicalRecord?.userData.imageProfile
        }
      />
      <Box ml={2} mt={1}>
        <Typography
          variant="titleMedium"
          sx={{
            fontWeight: "normal",
            fontFamily: "Nunito",
            color: "#006686",
          }}
        >
          {capitalizeAll(selectedUserMedicalRecord?.userData.name)}{" "}
          {capitalizeAll(selectedUserMedicalRecord?.userData.lastName)}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Nunito",
            color: "#006686",
            fontSize: "1rem",
          }}
        >
          {selectedMedicalRecord.createdAt.toDate().toLocaleDateString()}
        </Typography>
      </Box>
    </Box>
  );

  const paperContent = selectedMedicalRecord ? (
    <List sx={{ ml: 2 }}>
      <br />
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#006686",
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        Datos del paciente
      </Typography>
      <div
        style={{
          marginTop: "10px",
          display: "flex",
          width: "35%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Nunito",
            color: "#000",
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          Nombre:
        </Typography>
        <Typography
          sx={{
            fontFamily: "Nunito",
            color: "#000",
            fontSize: "0.9rem",
          }}
        >
          {capitalizeAll(selectedUserMedicalRecord?.userData.name)}{" "}
          {capitalizeAll(selectedUserMedicalRecord?.userData.lastName)}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          width: "35%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Nunito",
            color: "#000",
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          Dirección:
        </Typography>
        <Typography
          sx={{
            fontFamily: "Nunito",
            color: "#000",
            fontSize: "0.9rem",
          }}
        >
          {capitalizeAll(
            `${selectedUserMedicalRecord?.userData.location.address}, ${selectedUserMedicalRecord?.userData.location.comuna}`
          )}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          width: "35%",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Nunito",
            color: "#000",
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          Email:
        </Typography>
        <Typography
          sx={{
            fontFamily: "Nunito",
            color: "#000",
            fontSize: "0.9rem",
          }}
        >
          {capitalizeAll(selectedUserMedicalRecord?.userData.email)}
        </Typography>
      </div>
      <br />
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#006686",
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        Tipo de atención: {capitalize(selectedRequestData?.request.type)}
      </Typography>
      <br />
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#006686",
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        Síntomas
      </Typography>
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#000",
          fontStyle: "oblique",
          fontSize: "0.9rem",
        }}
      >
        "{capitalize(selectedMedicalRecord?.symptoms)}." (Indicado por el
        paciente)
      </Typography>
      <br />
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#006686",
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        Historia
      </Typography>
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#000",
          fontSize: "0.9rem",
        }}
      >
        {capitalize(selectedMedicalRecord?.history)}.
      </Typography>
      <br />
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#006686",
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        Diagnóstico
      </Typography>
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#000",
          fontSize: "0.9rem",
        }}
      >
        {capitalize(selectedMedicalRecord?.diagnosis)}.
      </Typography>
      <br />
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#006686",
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        Tratamiento
      </Typography>
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#000",
          fontSize: "0.9rem",
        }}
      >
        {capitalize(selectedMedicalRecord?.treatment)}.
      </Typography>
      <br />
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#006686",
          fontWeight: "bold",
          fontSize: "0.9rem",
        }}
      >
        Indicaciones
      </Typography>
      <Typography
        sx={{
          fontFamily: "Nunito",
          color: "#000",
          fontSize: "0.9rem",
        }}
      >
        {capitalize(selectedMedicalRecord?.indications)}.
      </Typography>
      {selectedMedicalRecord.exams.length > 0 && (
        <div>
          <br />
          <Typography
            sx={{
              fontFamily: "Nunito",
              color: "#006686",
              fontWeight: "bold",
              fontSize: "0.9rem",
            }}
          >
            Exámenes
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {selectedMedicalRecord.exams.map((exam, index) => (
              <div
                key={index}
                onClick={() => {
                  if (exam.status === "pending") {
                    Swal.fire({
                      icon: "info",
                      title: "Examen pendiente",
                      text: "El examen aún no ha sido realizado",
                    });
                  } else {
                    window.open(exam.url, "_blank");
                  }
                }}
                style={{
                  cursor: "pointer",
                  margin: "5px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  padding: "10px",
                  borderRadius: "10px",
                  border: "1px solid rgba(0,0,0,0.3)",
                }}
              >
                {exam.status === "pending" ? (
                  <TimerTwoTone />
                ) : exam.type === "pdf" ? (
                  <FileOpenOutlined
                    sx={{
                      color: "black",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                ) : (
                  <ImageOutlined
                    sx={{
                      color: "black",
                      width: "20px",
                      height: "20px",
                    }}
                  />
                )}
                <Typography
                  sx={{
                    fontFamily: "Nunito",
                    color: "#000",
                    fontSize: "0.9rem",
                  }}
                >
                  {capitalize(exam.exam)}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      )}
    </List>
  ) : (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          marginTop: "30px",
          color: "#006686",
          fontFamily: "Nunito",
        }}
      >
        Selecciona una ficha médica
      </Typography>
    </Container>
  );

  return (
    <>
      <NavPaper
        paperTitle={
          <Typography variant="h6" sx={{ padding: 2 }}>
            Fichas Médicas
          </Typography>
        }
        paperNav={paperNav}
        paperContentHeader={paperContentHeader}
        paperContent={paperContent}
        showList={showList}
        setShowList={setShowList}
      />
      {openDialog && (
        <DialogMedicalData
          data={selectedMedicalRecord}
          open={openDialog}
          close={() => setOpenDialog(false)}
        />
      )}
    </>
  );
}
