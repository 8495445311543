import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  pdf,
} from "@react-pdf/renderer";
import fontNunitoPath from "../assets/fonts/Nunito-Regular.ttf"; // Asegúrate de que la ruta sea correcta
import fontNunitoBoldPath from "../assets/fonts/Nunito-Bold.ttf"; // Asegúrate de que la ruta sea correcta
import logo from "../assets/logo.png";
import { capitalizeAll } from "./functions";
import { getFolioById, createFolio, updateFolio } from "../database/documents";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";

Font.register({
  family: "Nunito",
  src: fontNunitoPath,
});
Font.register({
  family: "NunitoBold",
  src: fontNunitoBoldPath,
});

function formatRut(rut) {
  // Eliminar caracteres que no sean dígitos ni K
  rut = rut.replace(/[^\dkK]/g, "");

  // Formatear rut con puntos y guión
  return rut.replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
}

const stylesReceta = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 15,
    width: "100%",
    height: "100%",
    fontFamily: "Nunito",
    color: "#006686",
  },
  sectionRut: {
    borderTop: "1px solid #006686",
    borderLeft: "1px solid #006686",
    borderRight: "1px solid #006686",
    fontSize: 12,
    padding: 5,
  },
  sectionData: {
    border: "1px solid #006686",
    fontSize: 12,
    // padding: 5
  },
  sectionIndications: {
    border: "1px solid #006686",
    fontSize: 12,
    padding: 5,
    height: 300,
  },
  title: {
    fontWeight: "heavy",
    fontSize: 25,
    marginBottom: 10,
    textAlign: "center",
    fontFamily: "NunitoBold",
  },
  titleFolio: {
    fontWeight: "bold",
    fontFamily: "NunitoBold",
    padding: 5,
    fontSize: 18,
    marginBottom: 10,
    marginRight: 10,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 15,
    marginBottom: 2,
    textAlign: "start",
    fontFamily: "NunitoBold",
  },
  textSignature: {
    fontSize: 15,
    marginTop: 5,
    textAlign: "center",
  },
  subtitleIndications: {
    fontSize: 15,
    marginBottom: 1,
    textAlign: "start",
    marginTop: 10,
  },
  watermarkContainer: {
    position: "absolute",
    top: "55%",
    left: "45%",
    transform: "translate(-50%, -50%)",
  },
  watermark: {
    opacity: 0.5,
    width: 200,
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  flexContainerData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  flexContainerTitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  signatureColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  column: {
    width: "80%",
    float: "left",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
  },
  columnImageFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignContent: "center",
    alignItems: "center",
    width: "40%",
    float: "right",
  },
  columnImage: {
    width: "20%",
  },
  columnTitle: {
    width: "30.33%",
    textAlign: "center",
  },
  label: {
    fontWeight: "bold",
    fontSize: 14,
  },
  data: {
    fontSize: 12,
    marginRight: 5,
  },
  doctorData: {
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    margin: 10,
    right: 0,
    textAlign: "start",
    fontSize: 10,
  },
  signatureContainer: {
    marginTop: 50,
  },
  row: {
    borderTop: "1px solid #ddd",
    paddingTop: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    width: 100,
    height: 15,
  },
});

function calculateAge(birthday) {
  const birthdayString = birthday.split("/");
  const day = parseInt(birthdayString[0], 10);
  const month = parseInt(birthdayString[1], 10) - 1;
  const year = parseInt(birthdayString[2], 10);

  // Crear objetos de fecha para la fecha de nacimiento y la fecha actual
  const fechaNacimientoObj = new Date(year, month, day);
  const fechaActual = new Date();

  // Calcular la diferencia en años
  let edad = fechaActual.getFullYear() - fechaNacimientoObj.getFullYear();

  // Ajustar la edad si aún no ha tenido su cumpleaños este año
  if (
    fechaActual.getMonth() < fechaNacimientoObj.getMonth() ||
    (fechaActual.getMonth() === fechaNacimientoObj.getMonth() &&
      fechaActual.getDate() < fechaNacimientoObj.getDate())
  ) {
    edad--;
  }

  return edad;
}

async function getNumberFolio(idUser, typeFolio) {
  Swal.fire({
    imageUrl: loadingSVG,
    imageWidth: 300,
    imageHeight: 300,
    title: "Cargando...",
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
  try {
    let folio = await getFolioById(idUser);
    if (!folio) {
      let a = await createFolio(idUser);

      if (a) {
        let createdFolio = await getFolioById(idUser);
        if (createFolio) {
          if (typeFolio === "receta") {
            await updateFolio(idUser, {
              prescriptionFolio: createdFolio.prescriptionFolio + 1,
            });
            return createdFolio.prescriptionFolio;
          } else {
            await updateFolio(idUser, {
              atentionFolio: createdFolio.atentionFolio + 1,
            });
            return createdFolio.atentionFolio;
          }
        }
      }
    } else {
      if (typeFolio === "receta") {
        let a = await updateFolio(idUser, {
          prescriptionFolio: folio.prescriptionFolio + 1,
        });
        return folio.prescriptionFolio;
      } else {
        await updateFolio(idUser, { atentionFolio: folio.atentionFolio + 1 });
        return folio.atentionFolio;
      }
    }
  } catch (error) {
    console.log(error);
    return false;
  }
  // try {
  //   let a = await createFolio(idUser);

  //   console.log(a);
  // } catch (error) {
  //   console.log(error);
  // }
}

//RECETA-----------------------------------------------------------------------------------------------------------------------
export async function generatePDFReceta(
  userData,
  doctorData,
  request,
  medicines,
  waterMark
) {
  try {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    let edadUser = calculateAge(userData.birthday);
    let folioReceta = await getNumberFolio(doctorData.id, "receta");
    const myDoc = (
      <Document>
        <Page size="A4" style={stylesReceta.page}>
          <View style={stylesReceta.watermarkContainer}>
            <Image src={waterMark} style={stylesReceta.watermark} />
          </View>
          <View style={stylesReceta.flexContainerTitle}>
            <view style={{ width: 90 }}></view>
            <View>
              <Text style={stylesReceta.title}>RECETA</Text>
            </View>

            {/* <Text style={stylesReceta.title}>RECETA</Text> */}
            <View>
              <Text style={stylesReceta.titleFolio}>
                Folio N°: {folioReceta}
              </Text>

              {/* <Text style={stylesReceta.subtitle}>{request.date}</Text> */}
            </View>
          </View>

          {/* Datos del paciente */}
          <View>
            <Text style={stylesReceta.subtitle}>DATOS PACIENTE</Text>
            <View style={stylesReceta.sectionRut}>
              <Text style={stylesReceta.data}>
                RUT: {userData.rut}, {userData.name} {userData.lastName}
              </Text>
            </View>
            <View style={stylesReceta.sectionData}>
              <View style={stylesReceta.flexContainer}>
                <Text style={{ padding: "5px" }}>
                  <Text
                    style={{
                      fontWeight: "500",
                      fontFamily: "NunitoBold",
                      marginRight: 3,
                    }}
                  >
                    Domicilio:
                  </Text>{" "}
                  {userData.location.address}, {userData.location.comuna},{" "}
                  {userData.location.region}{" "}
                  <Text
                    style={{
                      fontWeight: "500",
                      fontFamily: "NunitoBold",
                      marginRight: 3,
                    }}
                  >
                    Teléfono:
                  </Text>{" "}
                  {userData.phone},{" "}
                  <Text
                    style={{
                      fontWeight: "500",
                      fontFamily: "NunitoBold",
                      marginRight: 3,
                    }}
                  >
                    Fecha nacimiento:
                  </Text>{" "}
                  {userData.birthday},{" "}
                  <Text
                    style={{
                      fontWeight: "500",
                      fontFamily: "NunitoBold",
                      marginRight: 3,
                    }}
                  >
                    Edad:
                  </Text>{" "}
                  {edadUser},{" "}
                  <Text
                    style={{
                      fontWeight: "500",
                      fontFamily: "NunitoBold",
                      marginRight: 3,
                    }}
                  >
                    Género:
                  </Text>{" "}
                  {userData.genre},{" "}
                  <Text
                    style={{
                      fontWeight: "500",
                      fontFamily: "NunitoBold",
                      marginRight: 3,
                    }}
                  >
                    Previsión:
                  </Text>{" "}
                  {!userData.payment
                    ? request.payData.typePay
                    : userData.payment === "Isapre"
                    ? `Isapre: ${userData.selectedIsapre}`
                    : userData.payment}
                  .
                </Text>
              </View>
            </View>
          </View>
          {/* Indicaciones */}
          <View>
            <Text style={[stylesReceta.subtitle, { marginTop: 15 }]}>
              MEDICAMENTOS
            </Text>
            <View style={stylesReceta.sectionIndications}>
              <Text>{medicines}</Text>
            </View>
          </View>
          {/* Firma del doctor */}
          <View style={stylesReceta.signatureContainer}>
            <View style={stylesReceta.signatureColumn}>
              <Text style={stylesReceta.textSignature}>
                {doctorData.rut}, {doctorData.name} {doctorData.lastName}
              </Text>
              <View
                style={{
                  borderTop: "2px solid #004860",
                  width: 200,
                  marginTop: 5,
                }}
              />
              <Text style={stylesReceta.textSignature}>RUT, NOMBRE, FIRMA</Text>
            </View>
          </View>
          {/* Información del doctor y logotipo */}
          <View style={stylesReceta.footer}>
            <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
              <View style={stylesReceta.columnImageFooter}>
                <Image src={logo} />
              </View>
              <View style={stylesReceta.column}>
                <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                  {doctorData.name} {doctorData.lastName}
                </Text>
                <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                  {doctorData.location.address}, {doctorData.location.comuna}
                </Text>
                <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                  {doctorData.email}
                </Text>
              </View>
            </View>
          </View>
        </Page>
      </Document>
    );

    const blob = await pdf(myDoc).toBlob();
    const url = URL.createObjectURL(blob);

    window.open(url, "_blank");
    Swal.close();

    return true;
  } catch (error) {
    console.log(error);
    Swal.close();

    return false;
  }
}

export async function generatePDFCertificado(
  userData,
  doctorData,
  request,
  details,
  waterMark
) {
  Swal.fire({
    imageUrl: loadingSVG,
    imageWidth: 300,
    imageHeight: 300,
    title: "Cargando...",
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
  let edadUser = calculateAge(userData.birthday);
  const myDoc = (
    <Document>
      <Page size="A4" style={stylesReceta.page}>
        <View style={stylesReceta.watermarkContainer}>
          <Image src={waterMark} style={stylesReceta.watermark} />
        </View>
        <View style={stylesReceta.flexContainer}>
          <Text style={stylesReceta.title}>CERTIFICADO MÉDICO</Text>
        </View>

        {/* Datos del paciente */}
        <View>
          <Text style={stylesReceta.subtitle}>DATOS PACIENTE</Text>
          <View style={stylesReceta.sectionRut}>
            <Text style={stylesReceta.data}>
              RUT: {userData.rut}, {userData.name} {userData.lastName}
            </Text>
          </View>
          <View style={stylesReceta.sectionData}>
            <View style={stylesReceta.flexContainer}>
              <Text style={{ padding: "5px" }}>
                <Text style={{ fontWeight: "500" }}>Domicilio:</Text>{" "}
                {userData.location.address}, {userData.location.comuna},{" "}
                {userData.location.region}{" "}
                <Text style={{ fontWeight: "500" }}>Teléfono:</Text>{" "}
                {userData.phone},{" "}
                <Text style={{ fontWeight: "500" }}>Fecha nacimiento:</Text>{" "}
                {userData.birthday},{" "}
                <Text style={{ fontWeight: "500" }}>Edad:</Text> {edadUser},{" "}
                <Text style={{ fontWeight: "500" }}>Género:</Text>{" "}
                {userData.genre},{" "}
                <Text style={{ fontWeight: "500" }}>Previsión:</Text>{" "}
                {!userData.payment
                  ? request.payData.typePay
                  : userData.payment === "Isapre"
                  ? `Isapre: ${userData.selectedIsapre}`
                  : userData.payment}
                .
              </Text>
            </View>
          </View>
        </View>
        {/* Indicaciones */}
        <View
          style={{
            marginTop: 10,
          }}
        >
          <View style={stylesReceta.sectionIndications}>
            <Text>{details}</Text>
          </View>
        </View>
        {/* Firma del doctor */}
        <View style={stylesReceta.signatureContainer}>
          <View style={stylesReceta.signatureColumn}>
            <Text style={stylesReceta.textSignature}>
              {doctorData.rut}, {doctorData.name} {doctorData.lastName}
            </Text>
            <View
              style={{
                borderTop: "2px solid #004860",
                width: 200,
                marginTop: 5,
              }}
            />
            <Text style={stylesReceta.textSignature}>RUT, NOMBRE, FIRMA</Text>
          </View>
        </View>
        {/* Información del doctor y logotipo */}
        <View style={stylesReceta.footer}>
          <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
            <View style={stylesReceta.columnImageFooter}>
              <Image src={logo} />
            </View>
            <View style={stylesReceta.column}>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {doctorData.name} {doctorData.lastName}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {doctorData.location.address}, {doctorData.location.comuna}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {doctorData.email}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
  try {
    const blob = await pdf(myDoc).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    Swal.close();
    return true;
  } catch (error) {
    console.log(error);
    Swal.close();

    return false;
  }
}

export async function generatePDFIndications(
  userData,
  doctorData,
  request,
  indications,
  exams,
  treatment,
  waterMark
) {
  Swal.fire({
    imageUrl: loadingSVG,
    imageWidth: 300,
    imageHeight: 300,
    title: "Cargando...",
    showConfirmButton: false,
    allowOutsideClick: false,
    allowEscapeKey: false,
  });
  let edadUser = calculateAge(userData.birthday);
  const myDoc = (
    <Document>
      <Page size="A4" style={stylesReceta.page}>
        <View style={stylesReceta.watermarkContainer}>
          <Image src={waterMark} style={stylesReceta.watermark} />
        </View>
        <View style={stylesReceta.flexContainer}>
          <Text style={stylesReceta.title}>INDICACIONES DE TRATAMIENTO</Text>
        </View>
        {/* Datos del paciente */}
        <View>
          <Text style={[stylesReceta.subtitle, { marginTop: 10 }]}>
            DATOS PACIENTE
          </Text>
          <View style={stylesReceta.sectionRut}>
            <Text style={stylesReceta.data}>
              RUT: {userData.rut}, {userData.name} {userData.lastName}
            </Text>
          </View>
          <View style={stylesReceta.sectionData}>
            <View style={stylesReceta.flexContainer}>
              <Text style={{ padding: "5px" }}>
                <Text style={{ fontWeight: "500" }}>Domicilio:</Text>{" "}
                {userData.location.address}, {userData.location.comuna},{" "}
                {userData.location.region}{" "}
                <Text style={{ fontWeight: "500" }}>Teléfono:</Text>{" "}
                {userData.phone},{" "}
                <Text style={{ fontWeight: "500" }}>Fecha nacimiento:</Text>{" "}
                {userData.birthday},{" "}
                <Text style={{ fontWeight: "500" }}>Edad:</Text> {edadUser},{" "}
                <Text style={{ fontWeight: "500" }}>Género:</Text>{" "}
                {userData.genre},{" "}
                <Text style={{ fontWeight: "500" }}>Previsión:</Text>{" "}
                {!userData.payment
                  ? request.payData.typePay
                  : userData.payment === "Isapre"
                  ? `Isapre: ${userData.selectedIsapre}`
                  : userData.payment}
                .
              </Text>
            </View>
          </View>
        </View>
        {/* Indicaciones */}
        {/* <View>
                    <Text style={stylesReceta.subtitleIndications}>EXÁMENES</Text>
                    <View style={stylesReceta.sectionIndications}>
                        <Text>{exams}</Text>
                    </View>
                </View> */}
        <View>
          <Text style={[stylesReceta.subtitle, { marginTop: 15 }]}>
            TRATAMIENTO
          </Text>
          <View style={stylesReceta.sectionData}>
            <Text style={{ padding: "5px" }}>
              {treatment ? treatment : "-"}
            </Text>
          </View>
        </View>
        <View>
          <Text style={[stylesReceta.subtitle, { marginTop: 15 }]}>
            INDICACIONES
          </Text>
          <View style={stylesReceta.sectionData}>
            <Text style={{ padding: "5px" }}>{indications}</Text>
          </View>
        </View>
        {/* Firma del doctor */}
        <View style={stylesReceta.signatureContainer}>
          <View style={stylesReceta.signatureColumn}>
            <Text
              style={{
                fontSize: 10,
                marginTop: 5,
                textAlign: "center",
              }}
            >
              {doctorData.rut}, {doctorData.name} {doctorData.lastName}
            </Text>
            <View
              style={{
                borderTop: "2px solid #004860",
                width: 200,
                marginTop: 5,
              }}
            />
            <Text style={stylesReceta.textSignature}>RUT, NOMBRE, FIRMA</Text>
          </View>
        </View>
        {/* Información del doctor y logotipo */}
        <View style={stylesReceta.footer}>
          <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
            <View style={stylesReceta.columnImageFooter}>
              <Image src={logo} />
            </View>
            <View style={stylesReceta.column}>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {doctorData.name} {doctorData.lastName}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {doctorData.location.address}, {doctorData.location.comuna}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {doctorData.email}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
  try {
    const blob = await pdf(myDoc).toBlob();
    const url = URL.createObjectURL(blob);
    Swal.close();

    window.open(url, "_blank");
    return true;
  } catch (error) {
    console.log(error);
    Swal.close();
    return false;
  }
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 15,
    fontFamily: "Nunito",
    width: "100%",
    height: "100%",
    fontSize: 12,
    color: "orange",
  },
  tableHeaders: {
    display: "table",
    width: "100%",
    borderWidth: 1,
    borderColor: "#006686",
    marginBottom: 5,
  },
  tableRowData: {
    display: "table",
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#006686",
  },
  tableHeadersFirst: {
    display: "table",
    width: "100%",
    borderWidth: 1,
    // marginTop: 20,
    border: "1px solid #006686",
  },
  tableHeadersSecond: {
    display: "table",
    width: "100%",
    borderWidth: 1,
    borderTop: "none",
    borderBottom: "1px solid #006686",
  },
  tableHeadersSignos: {
    display: "table",
    width: "100%",
    borderWidth: 1,
    borderColor: "#006686",
    marginTop: 5,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColTitleStart: {
    flex: 1,
    textAlign: "center",
    fontSize: 11,
    padding: 5,
    borderRight: "1px solid #006686",
  },
  tableColTitleEnd: {
    flex: 1,
    textAlign: "center",
    fontSize: 11,
    padding: 5,
    border: "none",
  },
  tableColDataStart: {
    flex: 1,
    textAlign: "center",
    fontSize: 11,
    padding: 5,
    borderLeft: "1px solid #006686",
    borderBottom: "none",
  },
  tableColDataEnd: {
    flex: 1,
    textAlign: "center",
    fontSize: 11,
    padding: 5,
    borderRight: "1px solid #006686",
    borderLeft: "1px solid #006686",
    borderBottom: "none",
  },
  title: {
    fontWeight: "bold",
    fontFamily: "NunitoBold",
    fontSize: 15,
    marginBottom: 0,
    marginTop: 10,
  },
  flexContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  column: {
    width: "80%",
  },
  columnImage: {
    width: "20%",
  },
  columnTitle: {
    width: "30.33%",
    textAlign: "center",
  },
  vl: {
    borderLeftWidth: 1,
    borderLeftColor: "#004860",
    height: 50,
  },
  content: {
    fontSize: 12,
    padding: 5,
    borderWidth: 1,
    borderColor: "#004860",
  },
});

export async function generatePDFFichaMedica(
  userData,
  doctorData,
  request,
  fichaData,
  watermark
) {
  let edadUser = calculateAge(userData.birthday);
  let atentionFolio = await getNumberFolio(doctorData.id, "atencion");
  // console.log("userData", userData);
  // console.log("doctorData", doctorData);
  // console.log("request", request);

  // console.log("fichaData", fichaData);

  // console.log("watermark", watermark);

  const myDoc = (
    <Document>
      <Page size="A4" style={stylesReceta.page}>
        <View style={stylesReceta.flexContainerTitle}>
          <View>
            <Image
              source={watermark}
              style={{
                width: 100,
              }}
            />
          </View>
          <View>
            <Text style={stylesReceta.title}>FICHA DE ATENCIÓN</Text>
          </View>
          <View>
            <Text style={stylesReceta.titleFolio}>
              Folio N°: {atentionFolio}
            </Text>

            {/* <Text style={stylesReceta.subtitle}>{request.date}</Text> */}
          </View>
        </View>
        {/* Datos del paciente */}
        <View>
          <Text style={styles.title}>DATOS PACIENTE</Text>
          <View style={stylesReceta.sectionRut}>
            <Text style={stylesReceta.data}>
              RUT: {userData.rut}, {userData.name} {userData.lastName}
            </Text>
          </View>
          <View style={stylesReceta.sectionData}>
            <View style={stylesReceta.flexContainerData}>
              <Text style={{ padding: "5px" }}>
                <Text style={stylesReceta.data}>Domicilio:</Text>{" "}
                {userData.location.address}, {userData.location.comuna},{" "}
                {userData.location.region}{" "}
                <Text style={stylesReceta.data}>Teléfono:</Text>{" "}
                {userData.phone},{" "}
                <Text style={stylesReceta.data}>Fecha nacimiento:</Text>{" "}
                {userData.birthday},{" "}
                <Text style={stylesReceta.data}>Edad:</Text> {edadUser},{" "}
                <Text style={stylesReceta.data}>Género:</Text> {userData.genre},{" "}
                <Text style={stylesReceta.data}>Previsión:</Text>{" "}
                {!userData.payment
                  ? request.payData.typePay
                  : userData.payment === "Isapre"
                  ? `Isapre: ${userData.selectedIsapre}`
                  : userData.payment}
                .
              </Text>
            </View>
          </View>
        </View>
        <Text style={styles.title}>CLASIFICACIÓN</Text>

        <View style={styles.tableHeadersFirst}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColTitleStart}>ENFERMERA(O)</Text>
            <Text style={styles.tableColTitleStart}>HORA</Text>
            <Text style={styles.tableColTitleStart}>TRIAGE</Text>
            <Text style={styles.tableColTitleEnd}>MOTIVO DE CONSULTA</Text>
          </View>
        </View>
        {/* Datos */}
        <View style={styles.tableRowData}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColDataStart}>{fichaData.enfermera}</Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.horaClasification}
            </Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.triage ? fichaData.triage : "-"}
            </Text>
            <Text style={styles.tableColDataEnd}>
              {fichaData.motivoConsulta}
            </Text>
          </View>
        </View>
        <Text style={styles.title}>
          MÉDICO TURNO: {doctorData.name} {doctorData.lastName}, ESPECIALIDAD:{" "}
          {fichaData.especialidad}, HORA DE ATENCIÓN: {fichaData.horaAtencion}
        </Text>

        <View style={styles.tableHeadersFirst}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColTitleStart}>ALERGIAS:</Text>
            <Text style={styles.tableColTitleStart}>MEDICAMENTOS:</Text>
            <Text style={styles.tableColTitleEnd}>HISTORIA:</Text>
          </View>
        </View>
        {/* Datos */}
        <View style={styles.tableRowData}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColDataStart}>
              {fichaData.alergias ? fichaData.alergias : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.medicamentos}
            </Text>
            <Text style={styles.tableColDataEnd}>
              {fichaData.historia ? fichaData.historia : "-"}
            </Text>
          </View>
        </View>

        <View style={styles.tableHeadersSecond}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColTitleStart}>EXÁMENES:</Text>
            <Text style={styles.tableColTitleStart}>INTERCONSULTA:</Text>
            <Text style={styles.tableColTitleEnd}>TRATAMIENTO:</Text>
          </View>
        </View>
        {/* Datos */}
        <View style={styles.tableRowData}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColDataStart}>
              {fichaData.examenes ? fichaData.examenes : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.interconsulta ? fichaData.interconsulta : "-"}
            </Text>
            <Text style={styles.tableColDataEnd}>
              {fichaData.tratamiento ? fichaData.tratamiento : "-"}
            </Text>
          </View>
        </View>

        <View style={styles.tableHeadersSecond}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColTitleStart}>DIAGNÓSTICO:</Text>
            <Text style={styles.tableColTitleStart}>EVOLUCIÓN:</Text>
            <Text style={styles.tableColTitleEnd}>INDICACIONES:</Text>
          </View>
        </View>
        {/* Datos */}
        <View style={styles.tableRowData}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColDataStart}>
              {fichaData.diagnostico ? fichaData.diagnostico : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>{fichaData.evolution}</Text>
            <Text style={styles.tableColDataEnd}>
              {fichaData.indications ? fichaData.indications : "-"}
            </Text>
          </View>
        </View>

        {/* <View style={styles.content}>
                    <Text>ALERGIAS: {fichaData.alergias}</Text>
                    <Text>MEDICAMENTOS: {fichaData.medicamentos}</Text>
                    <Text>HISTORIA: {fichaData.historia}</Text>
                    <Text>EXÁMENES: {fichaData.examenes}</Text>
                    <Text>INTERCONSULTA: {fichaData.interconsulta}</Text>
                    <Text>TRATAMIENTO: {fichaData.tratamiento}</Text>
                    <Text>DIAGNÓSTICO: {fichaData.diagnostico}</Text>
                    <Text>EVOLUCIÓN: {fichaData.}</Text>
                    <Text>INDICACIONES: {fichaData.indications}</Text>
                </View> */}

        <Text style={styles.title}>
          SIGNOS VITALES, HORA: {fichaData.horaSignos}, TOMADOS POR:{" "}
          {fichaData.tomadoPor}
        </Text>

        <View style={styles.tableHeadersFirst}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColTitleStart}>TEMPERATURA:</Text>
            <Text style={styles.tableColTitleStart}>PRESIÓN:</Text>
            <Text style={styles.tableColTitleStart}>PULSO:</Text>
            <Text style={styles.tableColTitleStart}>EVA:</Text>
            <Text style={styles.tableColTitleStart}>F. RESPIRATORIA:</Text>
            <Text style={styles.tableColTitleStart}>SAT. OXIGENO:</Text>
            <Text style={styles.tableColTitleStart}>L. CARDIACA:</Text>
            <Text style={styles.tableColTitleStart}>HEMOGLUCOSA:</Text>
            <Text style={styles.tableColTitleStart}>GLASSGOW (GCS):</Text>
            <Text style={styles.tableColTitleEnd}>PESO:</Text>
          </View>
        </View>
        {/* Datos */}
        <View style={styles.tableRowData}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColDataStart}>
              {fichaData.temperature ? fichaData.temperature : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>{fichaData.presion}</Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.pulso ? fichaData.pulso : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.eva ? fichaData.eva : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.respiratoria ? fichaData.respiratoria : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.saturacion ? fichaData.saturacion : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.cardiaca ? fichaData.cardiaca : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.hemoglucosa ? fichaData.hemoglucosa : "-"}
            </Text>
            <Text style={styles.tableColDataStart}>
              {fichaData.glassgow ? fichaData.glassgow : "-"}
            </Text>
            <Text style={styles.tableColDataEnd}>
              {fichaData.peso ? fichaData.peso : "-"}
            </Text>
          </View>
        </View>

        <View style={stylesReceta.footer}>
          <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
            <View style={stylesReceta.columnImageFooter}>
              <Image src={logo} />
            </View>
            <View style={stylesReceta.column}>
              <Text
                style={{
                  fontSize: 10,
                  margin: 2,
                  color: "#004860",
                  fontFamily: "NunitoBold",
                }}
              >
                {doctorData.name} {doctorData.lastName}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {doctorData.location.address}, {doctorData.location.comuna}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {doctorData.email}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
  try {
    const blob = await pdf(myDoc).toBlob();
    const url = URL.createObjectURL(blob);
    Swal.close();
    window.open(url, "_blank");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

const stylesReport = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 15,
    width: "100%",
    height: "100%",
    fontFamily: "Nunito",
    color: "#006686",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: "bold",
    fontSize: 25,
    // marginBottom: 10,
    textAlign: "center",
  },
  subtitle: {
    fontSize: 15,
    marginTop: 2,
    textAlign: "center",
  },
  date: {
    marginTop: 5,
    fontSize: 12,
    marginBottom: 2,
    textAlign: "start",
  },
  table: {
    display: "table",
    width: "auto",
  },
  header: {
    backgroundColor: "#006686",
    fontWeight: "bold",
  },
  headerRut: {
    backgroundColor: "#006686",
    fontWeight: "bold",
    width: "12%",
  },
  subHeaderRut: {
    backgroundColor: "#f0f0f0",
    width: "12%",
  },
  headerTypePay: {
    backgroundColor: "#006686",
    fontWeight: "bold",
    width: "18%",
  },
  subHeaderTypePay: {
    backgroundColor: "#f0f0f0",
    width: "18%",
  },
  headerDocCode: {
    backgroundColor: "#006686",
    fontWeight: "bold",
    width: "15%",
  },
  subHeaderDocCode: {
    backgroundColor: "#f0f0f0",
    width: "15%",
  },
  headerName: {
    backgroundColor: "#006686",
    fontWeight: "bold",
    width: "28%",
  },
  subHeaderName: {
    backgroundColor: "#f0f0f0",
    width: "28%",
  },
  headerAmount: {
    backgroundColor: "#006686",
    fontWeight: "bold",
    width: "15%",
  },
  subHeaderAmount: {
    backgroundColor: "#f0f0f0",
    width: "15%",
  },
  headerStatus: {
    backgroundColor: "#006686",
    fontWeight: "bold",
    width: "15%",
  },
  subHeaderStatus: {
    backgroundColor: "#f0f0f0",
    width: "15%",
  },
  footer: {
    flex: 1,
    backgroundColor: "white",
    fontWeight: "bold",
    borderBottom: "1px solid #006686",
  },
  cell: {
    margin: 2,
    fontSize: 12,
    color: "white",
    textAlign: "center",
  },
  cellValue: {
    margin: 2,
    fontSize: 12,
    color: "#006686",
    textAlign: "center",
  },
  cellValueMini: {
    margin: 1,
    fontSize: 8,
    color: "#006686",
    textAlign: "start",
  },
  row: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
  },
  rowBlue: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "start",
    backgroundColor: "#006686",
  },
  rowValue: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "start",
    backgroundColor: "#f0f0f0",
  },
  subHeader: {
    backgroundColor: "#f0f0f0",
    width: "25%",
  },
  textWhite: {
    color: "white",
    margin: 1,
    fontSize: 10,
    textAlign: "start",
  },
});

function convertToClp(value) {
  let newValue = value.toString().replace(/\$|\.+/g, "");
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(newValue);
}

export async function generatePDFReporting(data, withDetails) {
  const myDoc = (
    <Document>
      <Page size="A4" style={stylesReport.page}>
        <View style={stylesReport.mainContent}>
          <View>
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Text style={stylesReport.title}>RESUMEN</Text>
            </View>
            <View style={{ justifyContent: "flex-start", marginTop: 5 }}>
              <Text style={stylesReport.date}>{data.date}</Text>
            </View>
          </View>
          <View
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Text style={stylesReport.subTitle}>Dr. {data.name}</Text>
          </View>
          <View>
            <Text>Recaudaciones</Text>
          </View>
          <View style={stylesReport.table}>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Recaudación hoy</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`EFECTIVO (${data.dailyData.byType.efectivo})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`TARJETAS (${data.dailyData.byType.card})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`ISAPRE (${data.dailyData.byType.isapre})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`FONASA (${data.dailyData.byType.fonasa})`}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.dailyData.byType.totalEfectivo)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.dailyData.byType.totalCard)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.dailyData.byType.totalIsapre)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.dailyData.byType.totalFonasa)}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>Total {convertToClp(data.dailyData.total)}</Text>
            </View>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Recaudación semanal</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`EFECTIVO (${data.weeklyData.byType.efectivo})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`TARJETAS (${data.weeklyData.byType.card})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`ISAPRE (${data.weeklyData.byType.isapre})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`FONASA (${data.weeklyData.byType.fonasa})`}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.weeklyData.byType.totalEfectivo)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.weeklyData.byType.totalCard)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.weeklyData.byType.totalIsapre)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.weeklyData.byType.totalFonasa)}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>Total {convertToClp(data.weeklyData.total)}</Text>
            </View>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Recaudación mensual</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`EFECTIVO (${data.monthlyData.byType.efectivo})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`TARJETAS (${data.monthlyData.byType.card})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`ISAPRE (${data.monthlyData.byType.isapre})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`FONASA (${data.monthlyData.byType.fonasa})`}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.monthlyData.byType.totalEfectivo)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.monthlyData.byType.totalCard)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.monthlyData.byType.totalIsapre)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.monthlyData.byType.totalFonasa)}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>Total {convertToClp(data.monthlyData.totalMonth)}</Text>
            </View>
          </View>
          <View
            style={{
              marginTop: "3%",
            }}
          >
            <Text>Pacientes</Text>
          </View>
          <View style={[stylesReport.table]}>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Atendidos hoy</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Completados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Espera examen"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"cancelados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Ausentes"}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.dailyData.byStatus.completed}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.dailyData.byStatus.waiting}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.dailyData.byStatus.canceled}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.dailyData.byStatus.absent}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Atendidos en la semana</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Completados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Espera examen"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"cancelados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Ausentes"}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.weeklyData.byStatus.completed}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.weeklyData.byStatus.waiting}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.weeklyData.byStatus.canceled}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.weeklyData.byStatus.absent}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Atendidos en el mes</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Completados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Espera examen"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Cancelados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Ausentes"}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.monthlyData.byStatus.completed}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.monthlyData.byStatus.waiting}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.monthlyData.byStatus.canceled}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.monthlyData.byStatus.absent}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>
                Total:{" "}
                {data.monthlyData.byStatus.completed +
                  data.monthlyData.byStatus.waiting +
                  data.monthlyData.byStatus.canceled +
                  data.monthlyData.byStatus.absent}
              </Text>
            </View>
          </View>
        </View>
        <View style={stylesReceta.footer}>
          <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
            <View style={stylesReceta.columnImageFooter}>
              <Image
                src={data.watermark}
                style={{
                  width: 70,
                }}
              />
              <Image
                src={logo}
                style={{
                  width: 100,
                  height: 30,
                }}
              />
            </View>
            <View style={stylesReceta.column}>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.name}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.address}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.email}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      {withDetails && (
        <Page size="A4" style={stylesReport.page}>
          <View style={stylesReport.mainContent}>
            <View style={{ justifyContent: "center" }}>
              <Text style={stylesReport.title}>DETALLES DE CITAS MÉDICAS</Text>
            </View>
            <View
              style={[
                stylesReport.rowBlue,
                stylesReport.rowValue,
                {
                  backgroundColor: "#006686",
                },
              ]}
            >
              <View style={[stylesReport.headerRut]}>
                <Text style={stylesReport.textWhite}>{"Rut"}</Text>
              </View>
              <View style={[stylesReport.headerName]}>
                <Text style={stylesReport.textWhite}>{"Nombre"}</Text>
              </View>
              <View style={[stylesReport.headerTypePay]}>
                <Text style={stylesReport.textWhite}>{"Tipo pago"}</Text>
              </View>
              <View style={[stylesReport.headerDocCode]}>
                <Text style={stylesReport.textWhite}>{"Cód. documento"}</Text>
              </View>
              <View style={[stylesReport.headerAmount]}>
                <Text style={stylesReport.textWhite}>{"Monto"}</Text>
              </View>
              <View style={[stylesReport.headerStatus]}>
                <Text style={stylesReport.textWhite}>{"Estado"}</Text>
              </View>
            </View>
            {data.monthlyData.allData.map((item, index) => {
              return (
                <View key={index}>
                  <View
                    style={[
                      stylesReport.rowValue,
                      {
                        borderBottom: "1px solid #006686",
                      },
                    ]}
                  >
                    <View style={[stylesReport.subHeaderRut]}>
                      <Text style={stylesReport.cellValueMini}>
                        {formatRut(item.doctor.rut)}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderName]}>
                      <Text style={stylesReport.cellValueMini}>
                        {capitalizeAll(item.doctor.name)}{" "}
                        {capitalizeAll(item.doctor.lastName)}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderTypePay]}>
                      <Text style={stylesReport.cellValueMini}>
                        {capitalizeAll(
                          item.request.payData.typePay === "Particular"
                            ? item.request.payData.typePay +
                                ", " +
                                item.request.payData.payMethod
                            : item.request.payData.typePay === "Isapre"
                            ? `Isapre ${
                                item.request.payData.isapre !== undefined &&
                                item.request.payData.isapre
                              }`
                            : item.request.payData.typePay
                        )}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderDocCode]}>
                      <Text style={stylesReport.cellValueMini}>
                        {capitalizeAll(item.request.payData.code) ?? "N/A"}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderAmount]}>
                      <Text style={stylesReport.cellValueMini}>
                        {convertToClp(item.request.payData.amount)}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderStatus]}>
                      <Text style={stylesReport.cellValueMini}>
                        {item.status === "pending"
                          ? "Por atender"
                          : item.status === "waiting"
                          ? "Espera exámenes"
                          : item.status === "canceledByClient"
                          ? "Cancelada"
                          : item.status === "completed"
                          ? "Completada"
                          : "Ausente"}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
          <View style={stylesReceta.footer}>
            <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
              <View style={stylesReceta.columnImageFooter}>
                <Image
                  src={data.watermark}
                  style={{
                    width: 70,
                  }}
                />
                <Image
                  src={logo}
                  style={{
                    width: 100,
                    height: 30,
                  }}
                />
              </View>
              <View style={stylesReceta.column}>
                <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                  {data.name}
                </Text>
                <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                  {data.address}
                </Text>
                <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                  {data.email}
                </Text>
              </View>
            </View>
          </View>
          {/* <View style={[stylesReceta.row, { flexDirection: 'row-reverse', position: 'absolute', bottom: 2, left: 0, right: 0 }]}>
                            <View style={stylesReceta.columnImageFooter}>
                                <Image src={data.watermark} style={{
                                    width: 100
                                }} />
                                <Image src={logo} style={{
                                    width: 100,
                                    height: 40
                                }} />
                            </View>
                            <View style={stylesReceta.column}>
                                <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>
                                    {data.name}
                                </Text>
                                <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>{data.address}</Text>
                                <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>{data.email}</Text>
                            </View>
                        </View> */}
        </Page>
      )}
    </Document>
  );
  try {
    const blob = await pdf(myDoc).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function generatePDFReportingMedicalCenter(data, withDetails) {
  const myDoc = (
    <Document>
      <Page size="A4" style={stylesReport.page}>
        <View style={stylesReport.mainContent}>
          <View>
            <View style={{ flex: 1, justifyContent: "center" }}>
              <Text style={stylesReport.title}>RESUMEN</Text>
            </View>
            <View style={{ justifyContent: "flex-start", marginTop: 5 }}>
              <Text style={stylesReport.date}>{data.date}</Text>
            </View>
          </View>
          <View
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Text style={stylesReport.subTitle}>{data.name}</Text>
          </View>
          <View>
            <Text>Recaudaciones</Text>
          </View>
          <View style={stylesReport.table}>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Recaudación hoy</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Efectivo (${data.dailyData.byType.efectivo})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Tarjetas (${data.dailyData.byType.card})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Isapre (${data.dailyData.byType.isapre})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Fonasa (${data.dailyData.byType.fonasa})`}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.dailyData.byType.totalEfectivo)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.dailyData.byType.totalCard)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.dailyData.byType.totalIsapre)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.dailyData.byType.totalFonasa)}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>Total {data.dailyTotal}</Text>
            </View>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Recaudación semanal</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Efectivo (${data.weeklyData.byType.efectivo})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Tarjetas (${data.weeklyData.byType.card})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Isapre (${data.weeklyData.byType.isapre})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Fonasa (${data.weeklyData.byType.fonasa})`}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.weeklyData.byType.totalEfectivo)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.weeklyData.byType.totalCard)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.weeklyData.byType.totalIsapre)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.weeklyData.byType.totalFonasa)}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>Total {data.weeklyData.totalWeek}</Text>
            </View>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Recaudación mensual</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Efectivo (${data.monthlyData.byType.efectivo})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Tarjetas (${data.monthlyData.byType.card})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Isapre (${data.monthlyData.byType.isapre})`}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text
                  style={stylesReport.cellValue}
                >{`Fonasa (${data.monthlyData.byType.fonasa})`}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.monthlyData.byType.totalEfectivo)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.monthlyData.byType.totalCard)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.monthlyData.byType.totalIsapre)}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {convertToClp(data.monthlyData.byType.totalFonasa)}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>Total {convertToClp(data.monthlyData.totalMonth)}</Text>
            </View>
          </View>
          <View
            style={{
              marginTop: "3%",
            }}
          >
            <Text>Pacientes</Text>
          </View>
          <View style={[stylesReport.table]}>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Atendidos hoy</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Completados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Espera examen"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Cancelados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Ausentes"}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.dailyData.byStatus.completed}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.dailyData.byStatus.waiting}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.dailyData.byStatus.canceled}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.dailyData.byStatus.absent}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>
                Total:{" "}
                {data.dailyData.byStatus.completed +
                  data.dailyData.byStatus.waiting +
                  data.dailyData.byStatus.canceled +
                  data.dailyData.byStatus.absent}
              </Text>
            </View>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Atendidos en la semana</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Completados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Espera examen"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Cancelados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Ausentes"}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.weeklyData.byStatus.completed}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.weeklyData.byStatus.waiting}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.weeklyData.byStatus.canceled}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.weeklyData.byStatus.absent}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>
                Total:{" "}
                {data.weeklyData.byStatus.completed +
                  data.weeklyData.byStatus.waiting +
                  data.weeklyData.byStatus.canceled +
                  data.weeklyData.byStatus.absent}
              </Text>
            </View>
            <View style={[stylesReport.row, stylesReport.header]}>
              <Text style={stylesReport.cell}>Atendidos en el mes</Text>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Completados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Espera examen"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Cancelados"}</Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>{"Ausentes"}</Text>
              </View>
            </View>
            <View style={[stylesReport.rowValue]}>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.monthlyData.byStatus.completed}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.monthlyData.byStatus.waiting}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.monthlyData.byStatus.canceled}
                </Text>
              </View>
              <View style={[stylesReport.subHeader]}>
                <Text style={stylesReport.cellValue}>
                  {data.monthlyData.byStatus.absent}
                </Text>
              </View>
            </View>
            <View style={[stylesReport.row, stylesReport.footer]}>
              <Text>
                Total:{" "}
                {data.monthlyData.byStatus.completed +
                  data.monthlyData.byStatus.waiting +
                  data.monthlyData.byStatus.canceled +
                  data.monthlyData.byStatus.absent}
              </Text>
            </View>
            <View
              style={{
                marginTop: "6%",
              }}
            ></View>
          </View>

          {/* <View style={[stylesReceta.row, { flexDirection: 'row-reverse', position: 'absolute', bottom: 20, left: 0, right: 0 }]}>
                        <View style={stylesReceta.columnImageFooter}>
                            <Image src={data.watermark} style={{ width: 100 }} />
                            <Image src={logo} style={{ width: 100, height: 40 }} />
                        </View>
                        <View style={stylesReceta.column}>
                            <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>
                                {data.name}
                            </Text>
                            <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>{data.address}</Text>
                            <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>{data.email}</Text>
                        </View>
                    </View> */}
        </View>
        <View style={stylesReceta.footer}>
          <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
            <View style={stylesReceta.columnImageFooter}>
              <Image
                src={data.watermark}
                style={{
                  width: 70,
                }}
              />
              <Image
                src={logo}
                style={{
                  width: 100,
                  height: 30,
                }}
              />
            </View>
            <View style={stylesReceta.column}>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.name}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.address}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.email}
              </Text>
            </View>
          </View>
        </View>
      </Page>
      {withDetails && (
        <Page size="A4" style={stylesReport.page}>
          <View style={stylesReport.mainContent}>
            <View style={{ justifyContent: "center" }}>
              <Text style={stylesReport.title}>DETALLES DE CITAS MÉDICAS</Text>
            </View>
            <View
              style={[
                stylesReport.rowBlue,
                stylesReport.rowValue,
                {
                  backgroundColor: "#006686",
                },
              ]}
            >
              <View style={[stylesReport.headerRut]}>
                <Text style={stylesReport.textWhite}>{"Rut"}</Text>
              </View>
              <View style={[stylesReport.headerName]}>
                <Text style={stylesReport.textWhite}>{"Nombre"}</Text>
              </View>
              <View style={[stylesReport.headerTypePay]}>
                <Text style={stylesReport.textWhite}>{"Tipo pago"}</Text>
              </View>
              <View style={[stylesReport.headerDocCode]}>
                <Text style={stylesReport.textWhite}>{"Cód. documento"}</Text>
              </View>
              <View style={[stylesReport.headerAmount]}>
                <Text style={stylesReport.textWhite}>{"Monto"}</Text>
              </View>
              <View style={[stylesReport.headerStatus]}>
                <Text style={stylesReport.textWhite}>{"Estado"}</Text>
              </View>
            </View>
            {data.monthlyData.allData.map((item, index) => {
              return (
                <View key={index}>
                  <View
                    style={[
                      stylesReport.rowValue,
                      {
                        borderBottom: "1px solid #006686",
                      },
                    ]}
                  >
                    <View style={[stylesReport.subHeaderRut]}>
                      <Text style={stylesReport.cellValueMini}>
                        {formatRut(item.doctor.rut)}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderName]}>
                      <Text style={stylesReport.cellValueMini}>
                        {capitalizeAll(item.doctor.name)}{" "}
                        {capitalizeAll(item.doctor.lastName)}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderTypePay]}>
                      <Text style={stylesReport.cellValueMini}>
                        {capitalizeAll(
                          item.request.payData.typePay === "Particular"
                            ? item.request.payData.typePay +
                                ", " +
                                item.request.payData.payMethod
                            : item.request.payData.typePay === "Isapre"
                            ? `Isapre ${
                                item.request.payData.isapre !== undefined &&
                                item.request.payData.isapre
                              }`
                            : item.request.payData.typePay
                        )}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderDocCode]}>
                      <Text style={stylesReport.cellValueMini}>
                        {capitalizeAll(item.request.payData.code) ?? "N/A"}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderAmount]}>
                      <Text style={stylesReport.cellValueMini}>
                        {convertToClp(item.request.payData.amount)}
                      </Text>
                    </View>
                    <View style={[stylesReport.subHeaderStatus]}>
                      <Text style={stylesReport.cellValueMini}>
                        {item.status === "pending"
                          ? "Por atender"
                          : item.status === "waiting"
                          ? "Espera exámenes"
                          : item.status === "canceledByClient"
                          ? "Cancelada"
                          : item.status === "completed"
                          ? "Completada"
                          : "Ausente"}
                      </Text>
                    </View>
                  </View>
                </View>
              );
            })}
          </View>
          <View style={stylesReceta.footer}>
            <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
              <View style={stylesReceta.columnImageFooter}>
                <Image
                  src={data.watermark}
                  style={{
                    width: 70,
                  }}
                />
                <Image
                  src={logo}
                  style={{
                    width: 100,
                    height: 30,
                  }}
                />
              </View>
              <View style={stylesReceta.column}>
                <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                  {data.name}
                </Text>
                <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                  {data.address}
                </Text>
                <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                  {data.email}
                </Text>
              </View>
            </View>
          </View>
          {/* <View style={[stylesReceta.row, { flexDirection: 'row-reverse', position: 'absolute', bottom: 2, left: 0, right: 0 }]}>
                            <View style={stylesReceta.columnImageFooter}>
                                <Image src={data.watermark} style={{
                                    width: 100
                                }} />
                                <Image src={logo} style={{
                                    width: 100,
                                    height: 40
                                }} />
                            </View>
                            <View style={stylesReceta.column}>
                                <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>
                                    {data.name}
                                </Text>
                                <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>{data.address}</Text>
                                <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>{data.email}</Text>
                            </View>
                        </View> */}
        </Page>
      )}
    </Document>
  );
  try {
    const blob = await pdf(myDoc).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function generatePDFDailyMedicalCenter(data, withDetails) {
  const myDoc = (
    <Document>
      <Page size="A4" style={stylesReport.page}>
        <View>
          <View style={{ flex: 1, justifyContent: "center" }}>
            <Text style={stylesReport.title}>RESUMEN</Text>
          </View>
          <View style={{ justifyContent: "flex-start", marginTop: 5 }}>
            <Text style={stylesReport.date}>{data.date}</Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Text style={stylesReport.subTitle}>{data.name}</Text>
        </View>
        <View>
          <Text>Recaudaciones</Text>
        </View>
        <View style={stylesReport.table}>
          <View style={[stylesReport.row, stylesReport.header]}>
            <Text style={stylesReport.cell}>Recaudación hoy</Text>
          </View>
          <View style={[stylesReport.rowValue]}>
            <View style={[stylesReport.subHeader]}>
              <Text
                style={stylesReport.cellValue}
              >{`EFECTIVO (${data.dailyData.byType.efectivo})`}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text
                style={stylesReport.cellValue}
              >{`TARJETAS (${data.dailyData.byType.efectivo})`}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text
                style={stylesReport.cellValue}
              >{`ISAPRE (${data.dailyData.byType.efectivo})`}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text
                style={stylesReport.cellValue}
              >{`FONASA (${data.dailyData.byType.efectivo})`}</Text>
            </View>
          </View>
          <View style={[stylesReport.rowValue]}>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {convertToClp(data.dailyData.byType.totalEfectivo)}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {convertToClp(data.dailyData.byType.totalCard)}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {convertToClp(data.dailyData.byType.totalIsapre)}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {convertToClp(data.dailyData.byType.totalFonasa)}
              </Text>
            </View>
          </View>
          <View style={[stylesReport.row, stylesReport.footer]}>
            <Text style={stylesReport.cellValue}>Total {data.dailyTotal}</Text>
          </View>
        </View>
        <View
          style={{
            marginTop: "3%",
          }}
        >
          <Text>Pacientes</Text>
        </View>
        <View style={[stylesReport.table]}>
          <View style={[stylesReport.row, stylesReport.header]}>
            <Text style={stylesReport.cell}>Atendidos hoy</Text>
          </View>
          <View style={[stylesReport.rowValue]}>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>{"COMPLETADOS"}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>{"ESPERA EXAMEN"}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>{"CANCELADOS"}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>{"AUSENTES"}</Text>
            </View>
          </View>
          <View style={[stylesReport.rowValue]}>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {data.dailyData.byStatus.completed}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {data.dailyData.byStatus.waiting}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {data.dailyData.byStatus.canceled}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {data.dailyData.byStatus.absent}
              </Text>
            </View>
          </View>
          <View style={[stylesReport.row, stylesReport.footer]}>
            <Text style={stylesReport.cellValue}>
              Total:{" "}
              {data.dailyData.byStatus.completed +
                data.dailyData.byStatus.waiting +
                data.dailyData.byStatus.canceled +
                data.dailyData.byStatus.absent}
            </Text>
          </View>
        </View>
        {withDetails && (
          <View
            style={{
              marginTop: "3%",
            }}
          >
            <Text>Detalles</Text>
          </View>
        )}
        {withDetails && (
          <View
            style={[
              stylesReport.rowBlue,
              stylesReport.rowValue,
              {
                backgroundColor: "#006686",
              },
            ]}
          >
            <View style={[stylesReport.rowBlue, stylesReport.header]}>
              <Text style={stylesReport.textWhite}>{"Rut"}</Text>
            </View>
            <View style={[stylesReport.header]}>
              <Text style={stylesReport.textWhite}>{"Nombre"}</Text>
            </View>
            <View style={[stylesReport.header]}>
              <Text style={stylesReport.textWhite}>{"Tipo de pago"}</Text>
            </View>
            <View style={[stylesReport.header]}>
              <Text style={stylesReport.textWhite}>{"Código documento"}</Text>
            </View>
            <View style={[stylesReport.header]}>
              <Text style={stylesReport.textWhite}>{"Monto"}</Text>
            </View>
            <View style={[stylesReport.header]}>
              <Text style={stylesReport.textWhite}>{"Estado"}</Text>
            </View>
          </View>
        )}
        {data.dailyData.allData.length === 0 ? (
          <Text style={stylesReport.cell}>No hay datos</Text>
        ) : (
          data.dailyData.allData.map((item, index) => {
            return (
              <View key={index}>
                <View style={[stylesReport.rowValue]}>
                  <View style={[stylesReport.subHeaderRut]}>
                    <Text style={stylesReport.cellValueMini}>
                      {item.doctor.rut}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderName]}>
                    <Text style={stylesReport.cellValueMini}>
                      {capitalizeAll(item.doctor.name)}{" "}
                      {capitalizeAll(item.doctor.lastName)}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderTypePay]}>
                    <Text style={stylesReport.cellValueMini}>
                      {capitalizeAll(
                        item.request.payData.typePay === "Particular"
                          ? item.request.payData.typePay +
                              ", " +
                              item.request.payData.payMethod
                          : item.request.payData.typePay
                      )}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderDocCode]}>
                    <Text style={stylesReport.cellValueMini}>
                      {capitalizeAll(item.request.payData.code) ?? "N/A"}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderAmount]}>
                    <Text style={stylesReport.cellValueMini}>
                      {item.request.payData.amount}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderStatus]}>
                    <Text style={stylesReport.cellValueMini}>
                      {item.status === "pending"
                        ? "Por atender"
                        : item.status === "waiting"
                        ? "Espera exámenes"
                        : item.status === "canceledByClient"
                        ? "Cancelada"
                        : item.status === "completed"
                        ? "Completada"
                        : "Ausente"}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })
        )}
        <View style={stylesReceta.footer}>
          <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
            <View style={stylesReceta.columnImageFooter}>
              <Image src={logo} />
            </View>
            <View style={stylesReceta.column}>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.name}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.address}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.email}
              </Text>
            </View>
          </View>
        </View>
        {/* <View style={[stylesReceta.row, { flexDirection: 'row-reverse', position: 'absolute', bottom: 2, left: 0, right: 0 }]}>
                    <View style={stylesReceta.columnImageFooter}>
                        <Image src={data.watermark} style={{
                            width: 100
                        }} />
                        <Image src={logo} style={{
                            width: 100,
                            height: 40
                        }} />
                    </View>
                    <View style={stylesReceta.column}>
                        <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>
                            {data.name}
                        </Text>
                        <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>{data.address}</Text>
                        <Text style={{ fontSize: 10, margin: 2, color: '#004860' }}>{data.email}</Text>
                    </View>
                </View> */}
      </Page>
    </Document>
  );
  try {
    const blob = await pdf(myDoc).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function generatePDFDailyDoctor(data, withDetails) {
  const myDoc = (
    <Document>
      <Page size="A4" style={stylesReport.page}>
        <View>
          <View style={{ justifyContent: "center" }}>
            <Text style={stylesReport.title}>RESUMEN</Text>
          </View>
          <View style={{ justifyContent: "flex-start", marginTop: 5 }}>
            <Text style={stylesReport.date}>{data.date}</Text>
          </View>
        </View>
        <View
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Text style={stylesReport.subTitle}>{data.name}</Text>
        </View>
        <View>
          <Text>Recaudaciones</Text>
        </View>
        <View style={stylesReport.table}>
          <View style={[stylesReport.row, stylesReport.header]}>
            <Text style={stylesReport.cell}>Recaudación hoy</Text>
          </View>
          <View style={[stylesReport.rowValue]}>
            <View style={[stylesReport.subHeader]}>
              <Text
                style={stylesReport.cellValue}
              >{`EFECTIVO (${data.dailyData.byType.efectivo})`}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text
                style={stylesReport.cellValue}
              >{`TARJETAS (${data.dailyData.byType.efectivo})`}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text
                style={stylesReport.cellValue}
              >{`ISAPRE (${data.dailyData.byType.efectivo})`}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text
                style={stylesReport.cellValue}
              >{`FONASA (${data.dailyData.byType.efectivo})`}</Text>
            </View>
          </View>
          <View style={[stylesReport.rowValue]}>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {convertToClp(data.dailyData.byType.totalEfectivo)}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {convertToClp(data.dailyData.byType.totalCard)}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {convertToClp(data.dailyData.byType.totalIsapre)}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {convertToClp(data.dailyData.byType.totalFonasa)}
              </Text>
            </View>
          </View>
          <View style={[stylesReport.row, stylesReport.footer]}>
            <Text style={stylesReport.cellValue}>Total {data.dailyTotal}</Text>
          </View>
        </View>
        <View
          style={{
            marginTop: "3%",
          }}
        >
          <Text>Pacientes</Text>
        </View>
        <View style={[stylesReport.table]}>
          <View style={[stylesReport.row, stylesReport.header]}>
            <Text style={stylesReport.cell}>Atendidos hoy</Text>
          </View>
          <View style={[stylesReport.rowValue]}>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>{"COMPLETADOS"}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>{"ESPERA EXAMEN"}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>{"CANCELADOS"}</Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>{"AUSENTES"}</Text>
            </View>
          </View>
          <View style={[stylesReport.rowValue]}>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {data.dailyData.byStatus.completed}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {data.dailyData.byStatus.waiting}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {data.dailyData.byStatus.canceled}
              </Text>
            </View>
            <View style={[stylesReport.subHeader]}>
              <Text style={stylesReport.cellValue}>
                {data.dailyData.byStatus.absent}
              </Text>
            </View>
          </View>
          <View style={[stylesReport.row, stylesReport.footer]}>
            <Text style={stylesReport.cellValue}>
              Total:{" "}
              {data.dailyData.byStatus.completed +
                data.dailyData.byStatus.waiting +
                data.dailyData.byStatus.canceled +
                data.dailyData.byStatus.absent}
            </Text>
          </View>
        </View>
        {withDetails && (
          <View
            style={{
              marginTop: "3%",
            }}
          >
            <Text>Detalles</Text>
          </View>
        )}
        {withDetails && (
          <View
            style={[
              stylesReport.rowBlue,
              stylesReport.rowValue,
              {
                backgroundColor: "#006686",
              },
            ]}
          >
            <View style={[stylesReport.rowBlue, stylesReport.headerRut]}>
              <Text style={stylesReport.textWhite}>{"Rut"}</Text>
            </View>
            <View style={[stylesReport.headerName]}>
              <Text style={stylesReport.textWhite}>{"Nombre"}</Text>
            </View>
            <View style={[stylesReport.headerTypePay]}>
              <Text style={stylesReport.textWhite}>{"Tipo de pago"}</Text>
            </View>
            <View style={[stylesReport.headerDocCode]}>
              <Text style={stylesReport.textWhite}>{"Cód. documento"}</Text>
            </View>
            <View style={[stylesReport.headerAmount]}>
              <Text style={stylesReport.textWhite}>{"Monto"}</Text>
            </View>
            <View style={[stylesReport.headerStatus]}>
              <Text style={stylesReport.textWhite}>{"Estado"}</Text>
            </View>
          </View>
        )}
        {withDetails && data.dailyData.allData.length === 0 ? (
          <Text style={stylesReport.cell}>No hay datos</Text>
        ) : (
          withDetails &&
          data.dailyData.allData.map((item, index) => {
            return (
              <View key={index}>
                <View
                  style={[
                    stylesReport.rowValue,
                    {
                      borderBottom: "1px solid #006686",
                    },
                  ]}
                >
                  <View style={[stylesReport.subHeaderRut]}>
                    <Text style={stylesReport.cellValueMini}>
                      {formatRut(item.doctor.rut)}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderName]}>
                    <Text style={stylesReport.cellValueMini}>
                      {capitalizeAll(item.doctor.name)}{" "}
                      {capitalizeAll(item.doctor.lastName)}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderTypePay]}>
                    <Text style={stylesReport.cellValueMini}>
                      {capitalizeAll(
                        item.request.payData.typePay === "Particular"
                          ? item.request.payData.typePay +
                              ", " +
                              item.request.payData.payMethod
                          : item.request.payData.typePay === "Isapre"
                          ? `Isapre ${
                              item.request.payData.isapre !== undefined
                                ? item.request.payData.isapre
                                : ""
                            }`
                          : item.request.payData.typePay
                      )}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderDocCode]}>
                    <Text style={stylesReport.cellValueMini}>
                      {capitalizeAll(item.request.payData.code) ?? "N/A"}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderAmount]}>
                    <Text style={stylesReport.cellValueMini}>
                      {convertToClp(item.request.payData.amount)}
                    </Text>
                  </View>
                  <View style={[stylesReport.subHeaderStatus]}>
                    <Text style={stylesReport.cellValueMini}>
                      {item.status === "pending"
                        ? "Por atender"
                        : item.status === "waiting"
                        ? "Espera exámenes"
                        : item.status === "canceledByClient"
                        ? "Cancelada"
                        : item.status === "completed"
                        ? "Completada"
                        : "Ausente"}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })
        )}
        <View style={stylesReceta.footer}>
          <View style={[stylesReceta.row, { flexDirection: "row-reverse" }]}>
            <View style={stylesReceta.columnImageFooter}>
              <Image
                src={data.watermark}
                style={{
                  width: 70,
                }}
              />
              <Image
                src={logo}
                style={{
                  width: 100,
                  height: 30,
                }}
              />
            </View>
            <View style={stylesReceta.column}>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.name}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.address}
              </Text>
              <Text style={{ fontSize: 10, margin: 2, color: "#004860" }}>
                {data.email}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
  try {
    const blob = await pdf(myDoc).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
