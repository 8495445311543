import React, { useEffect, useState } from "react";
import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  Grid,
  Paper,
  ListItemIcon,
  ListItemText,
  Typography,
  Box,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  Add,
  ArrowRight,
  CheckCircleTwoTone,
  Payment,
} from "@mui/icons-material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { capitalize, capitalizeAll, getActualDay } from "../utils/functions";
import { getMyRequestsByDay } from "../database/request";
import { getDailyGain, getWeeklyGains } from "../database/reports";
import { Button } from "@mui/material";
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer } from "recharts";

//REDUX IMPORTS
import { useSelector } from "react-redux";
import DialogCalendar from "../components/dialog_calendar";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
// import NewDrawerComponent from "../components/new_drawer";
import DialogDailyGains from "../components/dialog_show_daily";
import { useNavigate } from "react-router-dom";
import TopBar from "../components/top_bar";
import moment from "moment";

export default function Home() {
  const dataDoctor = useSelector((state) => state.auth.userData);
  const role = useSelector((state) => state.auth.userData.role);
  const myId = useSelector((state) => state.auth.idUser);
  const [dayName, dayNumber, formattedDate] = getActualDay();
  const [dataRequests, setDataRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const [payingRequest, setPayingRequest] = useState(null);
  const [gainsDaily, setGainsDaily] = useState(0);
  const [dataChart, setDataChart] = useState([]);
  const [showDaily, setShowDaily] = useState(false);

  const navigate = useNavigate();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const puntoDeQuiebre = 1300;
  const breakVariable = windowWidth >= puntoDeQuiebre;
  const currentTime = moment();
  // const handleInfoClick = () => {
  //   Swal.fire({
  //     title: "Recaudaciones del día",
  //     text: "Este gráfico representa las ganancias en base a las solicitudes completadas o en espera de exámenes. Si no aparece la ganancia de la fecha actual, es porque no se ha completado ninguna solicitud en el día de hoy.",
  //     icon: "info",
  //     confirmButtonText: "Entendido",
  //   });
  // };

  const handleCloseDaily = () => {
    setShowDaily(false);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Verifica si payload es definido y tiene al menos un elemento
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "5px",
            border: "1px solid #ccc",
            borderRadius: 5,
          }}
        >
          <p>{`${label} : ${payload[0].value.toLocaleString("es-CL", {
            style: "currency",
            currency: "CLP",
          })}`}</p>
        </div>
      );
    }
    return null;
  };

  const CustomBar = (props) => {
    const { fill, x, y, width, height } = props;
    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          fill={fill}
          rx={5}
          ry={5}
        />
      </g>
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      if (dataDoctor.role === "doctor") {
        let res = await getMyRequestsByDay(formattedDate, myId);
        setDataRequests(res);
        let gains = await getDailyGain(myId, formattedDate);
        setGainsDaily(gains);
        let weeklyGains = await getWeeklyGains(myId);
        weeklyGains.dataChart.sort((a, b) => a.day - b.day);
        weeklyGains.dataChart.forEach((item) => {
          const date = new Date(item.day + "T00:00:00");
          const dayOfWeek = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"][
            date.getDay()
          ];
          const dayOfMonth = date.getDate();
          item.day = `${dayOfWeek} ${dayOfMonth}`;
        });
        setDataChart(weeklyGains.dataChart);
        Swal.close();
      } else {
        let res = await getMyRequestsByDay(formattedDate, dataDoctor.idDoctor);
        setDataRequests(res);
        let gains = await getDailyGain(dataDoctor.idDoctor, formattedDate);

        setGainsDaily(gains);
        let weeklyGains = await getWeeklyGains(dataDoctor.idDoctor);

        weeklyGains.dataChart.sort((a, b) => a.day - b.day);
        weeklyGains.dataChart.forEach((item) => {
          const date = new Date(item.day + "T00:00:00");
          const dayOfWeek = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"][
            date.getDay()
          ];
          const dayOfMonth = date.getDate();
          item.day = `${dayOfWeek} ${dayOfMonth}`;
        });
        setDataChart(weeklyGains.dataChart);
      }
      Swal.close();
    }
    getData();

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [formattedDate]);

  async function loadData() {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    if (dataDoctor.role === "doctor") {
      let res = await getMyRequestsByDay(formattedDate, myId);
      setDataRequests(res);
      let gains = await getDailyGain(myId, formattedDate);
      setGainsDaily(gains);
    } else {
      let res = await getMyRequestsByDay(formattedDate, dataDoctor.idDoctor);
      setDataRequests(res);
      let gains = await getDailyGain(dataDoctor.idDoctor, formattedDate);
      setGainsDaily(gains);
    }
    Swal.close();
  }

  const handleComplete = async () => {
    const datosCodificados = encodeURIComponent(
      JSON.stringify(dataRequests[0])
    );
    const nuevaPestana = window.open("", "_blank");
    nuevaPestana.location.href = `/patientEntry?data=${datosCodificados}`;
  };

  const theme = useTheme();
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container spacing={2} sx={{ flex: 1 }}>
        <Grid container item spacing={2} xs={12} sx={{ flex: 1 }}>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: "20px",
                width: "100%",
                padding: { xs: "16px", md: "32px" },
                backgroundColor: "white",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "flex-start",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "primary",
                    fontSize: { xs: "16px", md: "24px", lg: "32px" },
                  }}
                >
                  {capitalize(dayName)}
                </Typography>
                <Typography variant="displayLarge" color="primary">
                  {dayNumber}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyItems: "space-between",
                  width: "100%",
                  height: "100%",
                }}
              >
                <Typography
                  sx={{
                    color: "#006686",
                    fontSize: { xs: "20px", lg: "22px" },
                    marginBottom: "0px",
                  }}
                >
                  {role === "doctor"
                    ? `Hola Dr. ${dataDoctor.name}`
                    : `Bienvenida ${dataDoctor.name}`}
                </Typography>
                <Typography
                  sx={{
                    color: "#006686",
                    fontSize: { xs: "14px", lg: "22px" },
                    marginBottom: "0px",
                  }}
                >
                  {dataRequests.length > 0
                    ? role === "doctor"
                      ? `Tienes ${dataRequests.length} citas`
                      : `El doctor tiene ${
                          dataRequests.filter(
                            (item) => item.status === "accepted"
                          ).length
                        } cita(s) hoy.`
                    : role === "doctor"
                    ? "No tienes pacientes hoy."
                    : "El doctor no tiene citas para hoy."}
                </Typography>
                <Typography
                  sx={{
                    color: "#006686",
                    fontSize: { xs: "14px", lg: "22px" },
                    marginBottom: "0px",
                  }}
                >
                  programadas para hoy
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={8} lg={8}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: "25px",
                backgroundColor: "white",
                height: "100%",
                padding: { xs: "16px", md: "32px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    paddingLeft: "4%",
                    color: "#006686",
                    fontSize: "1.5em",
                    marginBottom: "0px",
                  }}
                >
                  Recaudaciones del día
                </Typography>
                <Button
                  sx={{ color: "#006686", marginRight: "4%" }}
                  onClick={() => navigate("/reporting")}
                >
                  Ver más
                </Button>
              </Box>
              <Typography
                sx={{ paddingLeft: "4%", fontSize: "2em", fontWeight: "bold" }}
              >
                Total hoy{" "}
                {gainsDaily !== 0
                  ? gainsDaily.total.toLocaleString("es-CL", {
                      style: "currency",
                      currency: "CLP",
                    })
                  : "0"}
              </Typography>
              <ResponsiveContainer width="100%" height="60%">
                <BarChart
                  data={dataChart}
                  margin={{ right: 30, left: 20, bottom: 15 }}
                >
                  <XAxis dataKey="day" />
                  <Tooltip content={<CustomTooltip />} />
                  <Bar
                    shape={CustomBar}
                    background
                    name=""
                    dataKey="Ganancias"
                    fill="#006686"
                  />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>

        <Grid container item spacing={2} xs={12} sx={{ flex: 1 }}>
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: "25px",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                backgroundColor: "white",
                height: "100%",
                padding: { xs: "16px", md: "32px" },
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#006686",
                  fontSize: { md: "21px", lg: "32px" },
                  fontStyle: "normal",
                  fontWeight: "200",
                }}
              >
                Siguiente paciente
              </Typography>

              {dataRequests.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "85%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      alt=""
                      src={dataRequests[0]?.client?.imageProfile}
                      sx={{
                        width: { md: "40px", lg: "60px" },
                        height: { md: "40px", lg: "60px" },
                        mr: 2,
                      }}
                    />
                    <Box
                      sx={{
                        alignContent: "center",
                        alignItems: "start",
                        justifyContent: "center",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "black",
                          fontWeight: "400",
                          fontSize: { md: "18px", lg: "23px" },
                        }}
                      >
                        {`${capitalizeAll(
                          dataRequests[0]?.client?.name
                        )} ${capitalizeAll(dataRequests[0]?.client?.lastName)}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          color: "#006686",
                          fontSize: { md: "13px", lg: "18px" },
                          fontWeight: "400",
                        }}
                      >
                        Nuevo paciente
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      mt: 2,
                    }}
                  >
                    <Button
                      onClick={() => {
                        setPayingRequest(dataRequests[0]);
                        setOpen(true);
                      }}
                      sx={{
                        backgroundColor: "#006686",
                        color: "#77F8E0",
                        minWidth: "50%",
                        fontFamily: "Nunito",
                        fontSize: "13px",
                        borderRadius: "25px",
                        padding: "5px 10px",
                        textTransform: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {dataDoctor.role !== "secretary" &&
                        dataRequests.some(
                          (item) =>
                            item.status === "accepted" &&
                            item.request.payData.status !== "pending"
                        ) && (
                          <Add
                            sx={{
                              color: "#77F8E0",
                              width: { md: "13px", lg: "18px" },
                            }}
                          />
                        )}
                      {dataDoctor.role === "secretary" &&
                      dataRequests.some(
                        (item) =>
                          item.status === "accepted" &&
                          item.request.payData.status === "pending"
                      )
                        ? "Pagar"
                        : "Ingresar datos"}
                    </Button>
                  </Box>
                </Box>
              )}
              {dataRequests.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "80%",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: { md: "21px", lg: "27px" },
                      fontStyle: "bold",
                      fontWeight: "200",
                    }}
                  >
                    Sin pacientes
                  </Typography>
                  <Typography
                    sx={{
                      color: "#006686",
                      fontSize: { md: "15px", lg: "20px" },
                      fontStyle: "normal",
                      fontWeight: "200",
                    }}
                  >
                    Agende una cita
                  </Typography>
                </Box>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: "25px",
                backgroundColor: "white",
                height: "100%",
                overflowY: "hidden",
                padding: { xs: "16px", md: "32px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#006686",
                  fontSize: { md: "21px", lg: "32px" },
                  fontStyle: "normal",
                  fontWeight: "200",
                }}
              >
                {windowWidth >= 1300
                  ? "Citas restantes para hoy"
                  : "Citas de hoy"}
              </Typography>

              {dataRequests.filter((item) => item.status === "accepted")
                .length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    height: "70%",
                  }}
                >
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: { md: "21px", lg: "27px" },
                      fontStyle: "bold",
                      fontWeight: "200",
                    }}
                  >
                    Sin pacientes
                  </Typography>
                  <Typography
                    sx={{
                      color: "#006686",
                      fontSize: { md: "15px", lg: "20px" },
                      fontStyle: "normal",
                      fontWeight: "200",
                    }}
                  >
                    Agende una cita
                  </Typography>
                </Box>
              )}
              <Box sx={{ maxHeight: "90%", overflowY: "auto" }}>
                <List>
                  {dataRequests
                    .filter((item) => item.status === "accepted")
                    .filter((item) => {
                      const requestTime = moment(item.request.hour, "HH:mm");
                      return requestTime.isAfter(currentTime);
                    })
                    .slice(0, 4) // Limita a los primeros 5 elementos después de filtrar
                    .map((request, index) => (
                      <ListItem
                        key={request.id}
                        sx={{
                          cursor:
                            request.request.payData.status === "pending"
                              ? "pointer"
                              : "default",
                          ":hover": {
                            backgroundColor:
                              request.request.payData.status === "pending"
                                ? "rgb(0, 180, 250, 0.2)"
                                : "white",
                            color:
                              request.request.payData.status === "pending"
                                ? "white"
                                : "black",
                          },
                        }}
                        onClick={() => {
                          setPayingRequest(request);
                          setOpen(true);
                        }}
                        button={request.request.payData === "pending"}
                      >
                        <ListItemAvatar>
                          <Avatar
                            alt=""
                            src={request?.client?.imageProfile}
                            sx={{
                              width: { md: "40px", lg: "45px" },
                              height: { md: "40px", lg: "45px" },
                            }}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primaryTypographyProps={{
                            color: "Black",
                            fontWeight: "bold",
                            fontFamily: "Nunito",
                          }}
                          primary={`${
                            windowWidth >= 1300
                              ? `${capitalizeAll(
                                  request?.client?.name
                                )} ${capitalizeAll(request?.client?.lastName)}`
                              : `${capitalizeAll(request?.client?.name)}`
                          } `}
                          secondary={`${
                            windowWidth >= 1300
                              ? `Hoy a las ${request?.request?.hour} hrs.`
                              : `Hora: ${request?.request?.hour}`
                          }`}
                        />
                        {windowWidth >= 1300 && (
                          <>
                            {request.request.payData.status === "pending" ? (
                              <ListItemIcon>
                                <Payment
                                  sx={{
                                    color: "#006686",
                                    fontSize: "25px",
                                    ml: 3,
                                  }}
                                />
                              </ListItemIcon>
                            ) : (
                              <ListItemIcon>
                                <CheckCircleTwoTone
                                  sx={{
                                    color: "green",
                                    fontSize: "25px",
                                    ml: 3,
                                  }}
                                />
                              </ListItemIcon>
                            )}
                          </>
                        )}
                        {dataDoctor.role === "doctor" && (
                          <ListItemIcon>
                            <ArrowRight
                              sx={{ color: "black", fontSize: "25px", ml: 3 }}
                            />
                          </ListItemIcon>
                        )}
                      </ListItem>
                    ))}
                </List>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{
                borderRadius: "25px",
                backgroundColor: "white",
                height: "100%",
                padding: { xs: "16px", md: "32px" },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#006686",
                  fontSize: { md: "21px", lg: "32px" },
                  fontStyle: "normal",
                  fontWeight: "200",
                }}
              >
                Pacientes
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <List sx={{ width: "100%" }}>
                  {windowWidth >= 1300 && (
                    <ListItemText
                      primaryTypographyProps={{
                        color: "Black",
                        fontFamily: "Nunito",
                        fontSize: "1.8em",
                      }}
                      primary={`${dataRequests.length} citas programadas`}
                    />
                  )}

                  <ListItemText
                    primaryTypographyProps={{
                      color: "Black",
                      fontFamily: "Nunito",
                      fontSize: { md: "19px", lg: "24px" },
                    }}
                    primary={`Atendidos: ${
                      dataRequests.filter(
                        (item) =>
                          item.status === "completed" ||
                          item.status === "waiting"
                      ).length
                    }`}
                  />
                  <ListItemText
                    primaryTypographyProps={{
                      color: "Black",
                      fontFamily: "Nunito",
                      fontSize: { md: "19px", lg: "24px" },
                    }}
                    primary={`Ausentes: ${
                      dataRequests.filter(
                        (item) => item.status === "clientAbsent"
                      ).length
                    }`}
                  />
                  <ListItemText
                    primaryTypographyProps={{
                      color: "Black",
                      fontFamily: "Nunito",
                      fontSize: { md: "19px", lg: "24px" },
                    }}
                    primary={`Por atender: ${
                      dataRequests.filter((item) => item.status === "accepted")
                        .length
                    }`}
                  />
                  <ListItemText
                    primaryTypographyProps={{
                      color: "Black",
                      fontFamily: "Nunito",
                      fontSize: { md: "19px", lg: "24px" },
                    }}
                    primary={`Canceladas: ${
                      dataRequests.filter(
                        (item) => item.status === "canceledByClient"
                      ).length
                    }`}
                  />
                </List>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {open && (
          <DialogCalendar
            open={open}
            close={handleClose}
            data={payingRequest}
            myId={myId}
            loadData={loadData}
          />
      )}
      {showDaily && (
        <DialogDailyGains
          open={showDaily}
          close={handleCloseDaily}
          myId={myId}
        />
      )}
    </Box>
  );
}
