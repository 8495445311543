import React, { createContext, useContext, useState, useEffect } from "react";
import { getMySecretaries } from "../database/secretaries";
import { useSelector } from "react-redux";

const UpdateSecretaries = createContext();

export const UpdateSecretariesProvider = ({ children }) => {
  const [secretariesData, setSecretariesData] = useState(null);
  const [myId, setMyId] = useState("");
  const userData = useSelector((state) => state.auth.userData);

  const updateSecretariesAsync = async () => {
    if (myId !== "") {
      const data = await getMySecretaries(myId);
      setSecretariesData(data);
    }
  };

  useEffect(() => {
    let idToUpdate = "";
    if (userData?.role === "secretary") {
      idToUpdate = userData?.idDoctor;
      // console.log("userDataContext", idToUpdate);
    } else if (
      userData?.role === "doctor" ||
      userData?.role === "medicalCenter"
    ) {
      idToUpdate = userData?.id;
    }
    setMyId(idToUpdate);
  }, [userData]);

  useEffect(() => {
    updateSecretariesAsync();
  }, [myId]);

  return (
    <UpdateSecretaries.Provider
      value={{ secretariesData, updateSecretariesAsync }}
    >
      {children}
    </UpdateSecretaries.Provider>
  );
};

export const useUpdateSecretaries = () => useContext(UpdateSecretaries);
