import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Container,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  Typography,
  Grid,
  IconButton,
  Avatar,
  Box,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Files from "react-files";
import Switch from "@mui/material/Switch";
import { getIsapres, createDoctor } from "../database/doctors";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import logo from "../assets/logo.svg";
import medicine from "../assets/Medicine.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { ArrowDropDown, DeleteOutlineOutlined } from "@mui/icons-material";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import loadingSVG from "../assets/logo_Loading.gif";
import { updateClinic, uploadFiles } from "../database/clinic";

import { styled } from "@mui/material/styles";
import { auth } from "../database/config";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
// import xlsxBase64 from "../assets/xlsxBase64";

//REDUX IMPORTS
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";
import XlsxIcon from "../assets/xlsx_icon.svg";
import { location } from "../utils/locations";
export default function CompleteProfile() {
  const styleImage = {
    display: "flex",
    width: "60%",
    height: "30vh",
    padding: "0px 164px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    background: "white",
    border: "1px dashed #006686",
    color: "white",
    borderRadius: "25px 25px 25px 25px",
    cursor: "pointer",
    marginTop: "20px",
    marginBottom: "20px",
  };

  const bankOptions = [
    { value: "Banco estado" },
    { value: "Banco Chile/Edwards" },
    { value: "Banco Internacional" },
    { value: "Banco Scotiabank" },
    { value: "Banco BCI" },
    { value: "Banco do Brasil S.A." },
    { value: "Corpbanca" },
    { value: "Banco BICE" },
    { value: "Banco HSBC Bank" },
    { value: "Banco Santander" },
    { value: "Banco ITAU" },
    { value: "Banco Security" },
    { value: "Banco Falabella" },
    { value: "Banco Ripley" },
    { value: "Banco Security" },
    { value: "RaboBank" },
    { value: "Banco Consorcio" },
    { value: "Banco Paris" },
    { value: "BBVA" },
    { value: "Banco del Desarrollo" },
    { value: "Coopeuch" },
    { value: "Prepago Los Heroes" },
    { value: "Tenpo Prepago" },
    { value: "TAPP Caja Los Andes" },
    { value: "Copec Pay" },
    { value: "Mercado Pago" },
  ];

  const typeAccountOptions = [
    { value: "Cuenta vista" },
    { value: "Cuenta corriente" },
    { value: "Cuenta de ahorro" },
  ];

  const docId = useSelector((state) => state.auth.idUser);

  useEffect(() => {
    const getAllIsapres = async () => {
      const datas = await getIsapres();
      const tempOptions = datas.map((element) => ({
        value: element.name,
      }));
      setIsapres(tempOptions);
    };
    getAllIsapres();
  }, []);

  const navigate = useNavigate();

  const [region, setRegion] = useState("0");
  // const [lockComuna, setFocusComuna] = useState(false)
  const [comuna, setComuna] = useState("0");
  const [address, setAddress] = useState("");
  const [fonasa, setFonasa] = useState(false);
  const [isapre, setIsapre] = useState(false);
  const [isapres, setIsapres] = useState([]);
  const [particular, setParticular] = useState(false);
  const [isapreSeleccted, setIsapreSelected] = useState([]);
  const [isapreArray, setIsapreArray] = useState([]);
  const [bankSelected, setBankSelected] = useState(0);
  const [typeAccountSelected, setTypeAccountSelected] = useState(0);
  const [numAccount, setNumAccount] = useState("");
  const [view, setView] = useState("firstView");
  const [exelDocs, setExelDocs] = useState(null);
  const [xlsxName, setXlsxName] = useState(null);
  const [centerMedLogo, setCenterMedLogo] = useState("");
  const [centerMedImage, setCenterMedImage] = useState("");
  const [centerMedDescription, setCenterMedDescription] = useState("");
  const [presencial, setPresencial] = useState(true);
  const [domicilio, setDomicilio] = useState(false);
  const [videollamada, setVideollamada] = useState(false);

  const AntSwitch0 = styled(Switch)(({ theme }) => ({
    width: 50, // Ancho más grande
    height: 28, // Altura más grande
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(16px)", // Desplazamiento cuando está activado
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 4, // Espaciado dentro del switch
      "&.Mui-checked": {
        transform: "translateX(24px)", // Desplazamiento cuando está activado
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#006686",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20, // Tamaño del pulgar del switch
      height: 20, // Tamaño del pulgar del switch
      borderRadius: 15, // Radio de borde del pulgar
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16, // Radio de borde del track
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const AntSwitch1 = styled(Switch)(({ theme }) => ({
    width: 50, // Ancho más grande
    height: 28, // Altura más grande
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(16px)", // Desplazamiento cuando está activado
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 4, // Espaciado dentro del switch
      "&.Mui-checked": {
        transform: "translateX(24px)", // Desplazamiento cuando está activado
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#006686",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20, // Tamaño del pulgar del switch
      height: 20, // Tamaño del pulgar del switch
      borderRadius: 15, // Radio de borde del pulgar
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16, // Radio de borde del track
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleSelectIsapre = (e) => {
    if (isapreSeleccted !== "" && isapreSeleccted !== 0) {
      if (isapreArray.length === 0 && isapreSeleccted !== "") {
        setIsapreArray([...isapreArray, { value: isapreSeleccted }]);
        setIsapreSelected("0");
      } else {
        if (isapreArray.some((option) => option.value === isapreSeleccted)) {
          Swal.fire({
            icon: "info",
            title: "Oops...",
            text: "Ya seleccionaste esta isapre",
          });
        } else {
          if (isapreSeleccted !== 0) {
            setIsapreArray([...isapreArray, { value: isapreSeleccted }]);
            setIsapreSelected("0");
          }
        }
      }
    }
  };
  const validarRutChileno = (rut) => {
    try {
      const cleanRut = rut
        .toString()
        .replace(/[^\dKk]/g, "")
        .toUpperCase();
      if (!/^\d{7,8}[0-9Kk]{1}$/.test(cleanRut))
        return { clean: null, boolean: false };
      const rutDigits = cleanRut.slice(0, -1);
      const verificador = cleanRut.slice(-1);
      const digitoVerificador =
        verificador === "K" ? 10 : parseInt(verificador);

      const factor = [2, 3, 4, 5, 6, 7, 2, 3];
      let suma = 0;
      for (let i = rutDigits.length - 1, j = 0; i >= 0; i--, j++) {
        suma += parseInt(rutDigits[i]) * factor[j];
      }
      const resto = suma % 11;
      const dv = 11 - resto;

      if (dv === digitoVerificador) return { clean: cleanRut, boolean: true };

      return {
        clean: cleanRut,
        boolean: false,
      };
    } catch (error) {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "El RUT ingresado no es válido. Por favor, verifíquelo1.",
      });
      return { clean: null, boolean: false };
    }
  };

  function renombrarPropiedades(objeto) {
    console.log("objeto", objeto);
    let rutObj = validarRutChileno(objeto.RUT);
    console.log("objt.rut", rutObj);
    if (!rutObj.clean && rutObj.boolean) {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: "El RUT ingresado no es válido. Por favor, verifíquelo2.",
      });
      return null;
    }

    let password = rutObj.clean ? rutObj.clean.slice(0, 8) : "";
    let numeroDeSerie = objeto["FECHA DE NACIMIENTO"];
    const fechaBase = new Date(1900, 0, 1);
    const diasAgregados = numeroDeSerie - 2;

    fechaBase.setDate(fechaBase.getDate() + diasAgregados);

    return {
      lastName: objeto.APELLIDOS || "",
      email: objeto.EMAIL || "",
      nationality: objeto.NACIONALIDAD || "",
      name: objeto.NOMBRES || "",
      genre: objeto.GENERO || "",
      serialNumber: objeto["NUMERO DE DOCUMENTO"] || "",
      registerNumber: objeto["NUMERO DE REGISTRO"] || "",
      birthdate: objeto["FECHA DE NACIMIENTO"] || "",
      valueHour: objeto["VALOR DE LA RESERVA"] || "",
      rut: rutObj.clean || "",
      password: password || "",
      medicalCenterId: docId,
      firstTime: true,
    };
  }

  const validator = (data) => {
    let uncomplete = [];

    if (address === "") {
      uncomplete.push("Dirección");
    }
    if (region === "0") {
      uncomplete.push("Región");
    }
    if (comuna === "0") {
      uncomplete.push("Comuna");
    }

    if (fonasa === false && isapre === false && particular === false) {
      uncomplete.push("Tipo de pago recibido");
    }
    if (isapre === true && isapreArray.length === 0) {
      uncomplete.push("Isapres");
    }

    if (bankSelected === 0) {
      uncomplete.push("Banco");
    }
    if (typeAccountSelected === 0) {
      uncomplete.push("Tipo de cuenta");
    }
    if (numAccount === "") {
      uncomplete.push("Numero de cuenta");
    }

    if (uncomplete.length > 0) {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: `Por favor complete el/los campo(s): ${uncomplete.join(", ")}`,
      });
      return false;
    } else {
      return true;
    }
  };

  const handleCompleteProfile = async () => {
    const docs = exelDocs.map(renombrarPropiedades);
    console.log(docs);
    if (docs.length === 0) {
      Swal.fire({
        title: "Upss...",
        text: "verifica que tu documento tenga datos",
        icon: "warning",
      });
    } else {
      let invalidFields = [];

      docs.forEach((obj, index) => {
        const emptyFields = [];
        for (const [key, value] of Object.entries(obj)) {
          if (value === undefined || value === "") {
            emptyFields.push(key);
          }
        }
        if (emptyFields.length > 0) {
          invalidFields.push({ index, emptyFields });
        }
      });

      if (invalidFields.length > 0) {
        let errorMessage = "Campos vacíos o undefined encontrados:<br>";
        invalidFields.forEach((item) => {
          errorMessage += `<br>Al profesional N° ${
            item.index + 1
          } le falta rellenar los siguientes campos: ${item.emptyFields.join(
            ", "
          )}<br>`;
        });

        const content = document.createElement("div");
        content.innerHTML = errorMessage;

        Swal.fire({
          title: "Error",
          html: content,
          icon: "error",
        });
      } else {
        console.log("Validación exitosa", view);
        if (view === "secondView") {
          setView("thirdView");
        } else {
          let data = {
            location: {
              address: address,
              region: region,
              comuna: comuna,
            },
            previsions: {
              fonasa: fonasa,
              isapre: isapre,
              particular: particular,
            },
            queryTypes: {
              presencial: presencial,
              domicilio: domicilio,
              videollamada: videollamada,
            },
            selectedIsapre: isapreArray,
            firstTime: false,
            docDescription: centerMedDescription,
            logo: centerMedLogo,
          };
          let validate = validator(data);
          if (!validate) {
            Swal.fire({
              icon: "info",
              title: "Oops...",
              text: `Por favor complete el/los campo(s) `,
            });
          } else {
            if (centerMedLogo === "" || centerMedImage === "") {
              let text;
              if (centerMedLogo === "")
                text = "Estas seguro de que quieres continuar sin un logo?";
              if (centerMedImage === "")
                text = "Estas seguro de que quieres continuar sin una imagen?";
              if (centerMedLogo === "" && centerMedImage === "")
                text =
                  "Estas seguro de que quieres continuar sin un logo y sin una imagen?";

              Swal.fire({
                icon: "warning",
                title: "Falta/n Imagen/es",
                text: text,
                showCancelButton: true,
                confirmButtonText: "Continuar",
              }).then(async (e) => {
                if (e.isConfirmed) {
                  Swal.fire({
                    imageUrl: loadingSVG,
                    imageWidth: 300,
                    imageHeight: 300,
                    title: "Cargando...",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                  });
                  let logo;
                  let image;
                  if (centerMedLogo === "") {
                    image = await uploadFiles(centerMedImage);
                    data.imageProfile = image;
                  }
                  if (centerMedImage === "") {
                    logo = await uploadFiles(centerMedLogo);
                    data.logo = logo;
                  }
                  await updateClinic(data, docId);
                  for (const doc of docs) {
                    try {
                      const password = doc.rut.slice(0, 8);
                      const res = await createUserWithEmailAndPassword(
                        auth,
                        doc.email,
                        password
                      );
                      await createDoctor(doc, res.user.uid);
                      await sendEmailVerification(res.user);
                      if (docs.indexOf(doc) === docs.length - 1) {
                        Swal.fire({
                          icon: "success",
                          title: "¡Éxito!",
                          text: `Se ha subido correctamente la lista de profesionales`,
                        }).then(() => {
                          navigate("/");
                        });
                      }
                    } catch (error) {
                      console.error(error);
                      Swal.close();
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: `Ocurrió un error al subir los datos de ${doc.name} ${doc.lastName}`,
                      });
                    }
                  }
                }
              });
            } else {
              Swal.fire({
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
              });
              const image = await uploadFiles(centerMedImage);
              const logo = await uploadFiles(centerMedLogo);
              if (image && logo) {
                data.imageProfile = image;
                data.logo = logo;
                console.log("image profile center med", data);
                console.log("docId", docId);
                await updateClinic(data, docId);
              }

              for (const doc of docs) {
                try {
                  const password = doc.rut.slice(0, 8);
                  const res = await createUserWithEmailAndPassword(
                    auth,
                    doc.email,
                    password
                  );
                  await createDoctor(doc, res.user.uid);
                  await sendEmailVerification(res.user);
                  if (docs.indexOf(doc) === docs.length - 1) {
                    Swal.fire({
                      icon: "success",
                      title: "¡Éxito!",
                      text: `Se ha subido correctamente la lista de profesionales`,
                    }).then(() => {
                      navigate("/");
                    });
                  }
                } catch (error) {
                  console.error(error);
                  Swal.close();
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Ocurrió un error al subir los datos de ${doc.name} ${doc.lastName}`,
                  });
                }
              }
            }
          }
        }
      }
    }
  };

  const handleEditImageLogo = (e, type) => {
    let file = e[0];
    if (type === "centerMedLogo") {
      setCenterMedLogo(file);
    } else {
      setCenterMedImage(file);
    }
  };

  const handleFileUpload = async (e) => {
    const file = e[0];
    if (!file) {
      console.error("No se seleccionó ningún archivo.");
      return;
    }
    console.log("file", file);
    setXlsxName(file.name);
    const data = new Uint8Array(await file.arrayBuffer());
    const workbook = XLSX.read(data, { type: "array" });

    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];

    const jsonData = XLSX.utils.sheet_to_json(sheet);

    const countDefinedProperties = (obj) => {
      return Object.keys(obj).filter(
        (key) => obj[key] !== undefined && key !== "__rowNum__"
      ).length;
    };

    const filteredData = jsonData.filter(
      (element) => countDefinedProperties(element) >= 4
    );

    filteredData.forEach((element) => {
      if (element.APELLIDOS) {
        element.APELLIDOS = element.APELLIDOS.toLowerCase()
          .normalize("NFD")
          .replace(
            /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
            "$1"
          );
      }

      if (element.NOMBRES) {
        element.NOMBRES = element.NOMBRES.toLowerCase()
          .normalize("NFD")
          .replace(
            /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
            "$1"
          );
      }

      if (element.NACIONALIDAD) {
        element.NACIONALIDAD = element.NACIONALIDAD.toLowerCase()
          .normalize("NFD")
          .replace(
            /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,
            "$1"
          );
      }
    });

    console.log(filteredData);
    setExelDocs(filteredData);
  };

  function handleViews(data) {
    let a = validator();
    if (a) {
      setView(data);
    }
  }

  function AddDocs() {
    return (
      <>
        <Box
          sx={{
            width: "700px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            style={{
              marginTop: "20px",
              width: "180px",
              marginBottom: "50px",
            }}
          />
          {/* <Typography
            sx={{
              color: "var( #006686)",
              textAlign: "center",
              fontSize: "35px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "42px",
              marginBottom: "15px",
            }}
          >
            Agregar profesionales
          </Typography> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          <Card
            id="card"
            sx={{
              mt: 2,
              borderRadius: "28px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: 3, md: 4, lg: 6 },
              gap: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginBottom: "12px",
              }}
              align="center"
            >
              Añade profesionales fácilmente en GetMed. Descarga la plantilla
              Excel, llena los datos y súbelos aquí. Gestiona tu equipo médico
              de manera rápida y sin complicaciones.
            </Typography>
            <br />
            <Typography
              variant="body1"
              sx={{
                marginBottom: "20px",
              }}
              align="center"
            >
              1. Descarga la plantilla Excel: Haz clic para descargar la
              plantilla Excel y agrega fácilmente los detalles de tus
              profesionales.
            </Typography>
            <br />
            <Button
              variant="outlined"
              onClick={() => {
                window.open(
                  "https://firebasestorage.googleapis.com/v0/b/dfinder-5f8ae.appspot.com/o/GetMED%20Plantilla%20Base.xlsx?alt=media&token=404f4112-b441-4b77-85af-01a860f6a089",
                  "_blank"
                );
              }}
            >
              Descargar plantilla
            </Button>
            <br />

            <Typography
              variant="body1"
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
              }}
              align="center"
            >
              2. Sube tu archivo completado: Al completar la plantilla, sube el
              archivo directamente aquí. Nosotros nos encargaremos del resto.
            </Typography>

            <Typography
              variant="body1"
              sx={{
                marginTop: "40px",
                marginBottom: "20px",
              }}
              align="center"
            >
              3. ¡Listo! Puedes cerrar esta ventana. Cuando vuelvas, tendrás la
              opción de cargar otra plantilla o editar los profesionales
              existentes.
            </Typography>

            <Files
              className="files-dropzone"
              onChange={(e) => {
                handleFileUpload(e);
              }}
              // onError={handleError}
              accepts={[".xlsx"]}
              clickable
              style={{
                width: "100%",
                height: "320px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(248, 249, 251, 1)",
                border: "2px solid rgba(242, 244, 246, 1)",
                borderRadius: "28px",
                cursor: "pointer",
                marginTop: "20px",
                marginBottom: "20px",
                fontSize: "20px",
              }}
              maxFiles={1}
            >
              {exelDocs ? (
                <>
                  <img src={XlsxIcon} style={{ width: "40%", height: "45%" }} />
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      width: "70%",
                    }}
                    textAlign={"center"}
                  >
                    {xlsxName}
                  </Typography>
                </>
              ) : (
                <>
                  <FileUploadIcon sx={{ fontSize: "90px" }} />
                  Archivo Excel
                  <Typography
                    variant="body1"
                    sx={{
                      marginTop: "20px",
                      marginBottom: "20px",
                      width: "70%",
                    }}
                    textAlign={"center"}
                  >
                    Arrastre o haga click para seleccionar
                  </Typography>
                </>
              )}
            </Files>
            {/* 
            <input
              required
              type="file"
              accept=".xlsx"
              className="input-field"
              // onChange={({ target: { files } }) => {
              //   files[0] && setFileName(files[0].name);
              //   if (files) {

              //     setImage(files[0]);
              //   }
              // }}
              onChange={(e) => {
                handleFileUpload(e);
              }}
            />
            <Typography
              variant="body1"
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                width: "70%",
              }}
              textAlign={"center"}
            >
              Arrastre o haga click para seleccionar
            </Typography> */}

            <br />
          </Card>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "50px",
            marginBottom: "50px",
            width: "450px",
          }}
        >
          {/* <Button
            variant="outlined"
            sx={{ color: "#006686", borderRadius: "25px", width: "200px" }}
            onClick={() => handleViews("fourthView")}
          >
            Mas tarde
          </Button> */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#006686",
              borderRadius: "25px",
              width: "200px",
            }}
            onClick={() => {
              // handleViews("thirdView");
              handleCompleteProfile();
            }}
          >
            Verificar
          </Button>
        </Box>
      </>
    );
  }

  function Docs() {
    return (
      <>
        <Box
          sx={{
            width: "700px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            style={{
              marginTop: "20px",
              width: "150px",
              marginBottom: "90px",
            }}
          />
          <Typography
            sx={{
              color: "var( #006686)",
              textAlign: "center",
              fontSize: "35px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "42px",
              marginBottom: "15px",
            }}
          >
            Agregar profesionales
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "50px",
            }}
          >
            <Card
              id="card"
              sx={{
                mt: 2,
                borderRadius: "28px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                p: { xs: 2, md: 4, lg: 6 },
                gap: 2,
              }}
            >
              <Box
                sx={{
                  marginTop: "20px",
                  marginBottom: "20px",
                  border: "2px solid rgba(192, 199, 205, 1)",
                  borderRadius: "12px",
                  backgroundColor: "rgb(238,239,238)",
                  width: "40vw",
                  height: "5vh",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    // color: "var( #006686)",
                    textAlign: "center",
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "42px",
                    marginLeft: "20px",
                    // color: "rgba(81, 84, 178, 1)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <AttachFileIcon /> {xlsxName}
                </Typography>

                <CloseOutlinedIcon
                  sx={{
                    marginRight: "20px",
                    color: "rgba(186, 26, 26, 1)",
                    fontSize: "30px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setExelDocs(null);
                    setView("secondView");
                  }}
                />
              </Box>
              <Box
                sx={{
                  border: "2px solid rgba(192, 199, 205, 1)",
                  width: "40vw",
                  borderRadius: "12px",
                  minHeight: "5vh",
                  marginBottom: "30px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    marginLeft: "20px",
                    marginTop: "10px",
                    fontSize: "15px",
                    fontStyle: "normal",
                  }}
                >
                  {exelDocs.length} Profesionales encontrados
                </Typography>
                <br />

                {/* {exelDocs.map((doc, index) => (
                  <div
                    key={index}
                    style={{
                      // backgroundColor: "rgba(81, 84, 178, 1)",
                      marginTop: "10px",
                      width: "100%",
                      height: "5vh",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      borderTop: "1px solid rgba(192, 199, 205, 1)",
                      // marginBottom: "2px",
                    }}
                  >
                    <Avatar
                      sx={{ backgroundColor: "gray", marginLeft: "20px" }}
                    >
                      {doc.NOMBRES.charAt(0) + doc.APELLIDOS.charAt(0)}
                    </Avatar>
                    <div>
                      <Typography
                        sx={{
                          marginLeft: "20px",
                          fontWeight: "normal",
                          fontSize: "17px",
                          marginTop: "3px",
                        }}
                      >
                        {doc.NOMBRES + " " + doc.APELLIDOS}
                      </Typography>
                      <Typography
                        sx={{
                          marginLeft: "20px",
                          fontWeight: "normal",
                          fontSize: "12px",
                          color: "#9b9b9b",
                        }}
                      >
                        {doc.RUT}
                      </Typography>
                    </div>
                  </div>
                ))} */}

                {exelDocs.map((doc, index) => (
                  <Grid
                    key={index}
                    container
                    alignItems="center"
                    sx={{
                      marginTop: "1vh",
                      borderTop: "1px solid rgba(192, 199, 205, 1)",
                      padding: "10px",
                      boxSizing: "border-box",
                      width: "100%",
                    }}
                  >
                    <Grid item xs={1} sm={1} md={1}>
                      <Avatar
                        sx={{ backgroundColor: "gray", marginLeft: "1vw" }}
                      >
                        {doc.NOMBRES.charAt(0) + doc.APELLIDOS.charAt(0)}
                      </Avatar>
                    </Grid>
                    <Grid item xs={11} sm={11} md={11}>
                      <Box sx={{ marginLeft: "1vw" }}>
                        <Typography
                          sx={{
                            fontWeight: "normal",
                            fontSize: { xs: "14px", sm: "16px", md: "18px" },
                            marginTop: "3px",
                          }}
                        >
                          {doc.NOMBRES + " " + doc.APELLIDOS}
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: "normal",
                            fontSize: { xs: "12px", sm: "14px", md: "16px" },
                            color: "#9b9b9b",
                          }}
                        >
                          {doc.RUT}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Card>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              // marginTop: "10px",
              marginBottom: "50px",
              width: "450px",
            }}
          >
            {/* <Button
              variant="outlined"
              sx={{ color: "#006686", borderRadius: "25px", width: "200px" }}
              onClick={() => handleViews("fourthView")}
            >
              Mas tarde
            </Button> */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#006686",
                borderRadius: "25px",
                width: "200px",
              }}
              onClick={() => {
                handleViews("fourthView");
              }}
            >
              Continuar
            </Button>
          </Box>
        </Box>
      </>
    );
  }

  const handlePresencial = (e) => {
    setPresencial(e);
  };

  // const handleDomicilio = (e) => {
  //   console.log("Domicilio", e);
  //   setDomicilio(e);
  // };

  const handleVideollamada = (e) => {
    setVideollamada(e);
  };

  // function AddDescMedCenter() {
  //   return <></>;
  // }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: { xs: "100%", lg: "800px" },
        py: 3,
      }}
    >
      {/* <a href="../DoctoresPlantilla.xlsx">aaaaa</a> */}

      {view == "firstView" && (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              sx={{
                marginTop: "24px",
                width: "120px",
                marginBottom: "30px",
              }}
            />
            <Typography
              variant="displaySmall"
              sx={{
                color: "#006686",
                textAlign: "center",
              }}
            >
              Bienvenido a GetMED, a continuación configuraremos tu perfil
              medico
            </Typography>
            <img
              src={medicine}
              style={{
                marginTop: "50px",
                width: "320px",
                marginBottom: "30px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "50px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "95%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#006686",
                  textAlign: "center",
                  fontSize: "35px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "42px",
                }}
              >
                1 de 2
              </Typography>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "35px" }}
                color="disabled"
              />
            </Box>
            <Card
              id="card"
              sx={{
                mt: 2,
                borderRadius: "28px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                p: { xs: 2, md: 4, lg: 6 },
                gap: 2,
              }}
            >
              <Typography
                variant="titleLarge"
                sx={{
                  marginBottom: "20px",
                }}
              >
                Datos Faltantes
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: 2,
                }}
              >
                <Typography
                  variant="titleMedium"
                  sx={{
                    // marginTop: "50px",
                    // marginBottom: "20px"
                    color: "#006686",
                    marginBottom: "12px",
                  }}
                >
                  Necesitamos algunos datos para continuar
                </Typography>
              </Box>
              <Select
                required
                variant="filled"
                fullWidth
                startAdornment={
                  <InputAdornment position="start" sx={{ color: "black" }}>
                    <MapOutlinedIcon />
                  </InputAdornment>
                }
                defaultValue={region}
                onChange={(e) => {
                  setRegion(e.target.value);
                  setComuna("0");
                }}
              >
                <MenuItem value={0}>Seleccione región</MenuItem>
                {location.map((e, i) => (
                  <MenuItem value={e.region} key={i}>
                    {e.region}
                  </MenuItem>
                ))}
              </Select>

              <Select
                required
                variant="filled"
                fullWidth
                startAdornment={
                  <InputAdornment position="start" sx={{ color: "black" }}>
                    <ExploreOutlinedIcon />
                  </InputAdornment>
                }
                value={comuna}
                defaultValue={"0"}
                onChange={(e) => {
                  setComuna(e.target.value);
                }}
              >
                <MenuItem value={"0"}>Seleccione su comuna</MenuItem>
                {location.map((e, i) => {
                  if (e.region === region) {
                    return e.comunas.map((comuna, i) => (
                      <MenuItem value={comuna} key={i}>
                        {comuna}
                      </MenuItem>
                    ));
                  }
                })}
              </Select>

              <TextField
                required
                placeholder="direccion"
                // value={rut}
                type="text"
                onChange={(e) => setAddress(e.target.value)}
                variant="filled"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ color: "black" }}>
                      <PlaceOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Card>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "50px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "95%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "var( #006686)",
                  textAlign: "center",
                  fontSize: "35px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "42px",
                }}
              >
                2 de 3
              </Typography>
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: "35px",
                  color:
                    presencial || videollamada || domicilio ? "green" : "grey",
                }}
              />
            </Box>
            <Card
              id="card"
              sx={{
                mt: 2,
                borderRadius: "28px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                p: { xs: 2, md: 4, lg: 6 },
                gap: 2,
              }}
            >
              <Typography
                variant="titleLarge"
                sx={{
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                ¿Cómo te gustaría atender a tus pacientes?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <Typography
                    sx={{
                      color: "var( #006686)",
                      textAlign: "center",
                      fontSize: "18px",
                      fontStyle: "normal",
                    }}
                  >
                    Tipos de atención (Seleccione al menos una opción)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "25px",
                  }}
                >
                  <AntSwitch0
                    inputProps={{ "aria-label": "ant design0" }}
                    checked={presencial}
                    onChange={(e) => {
                      handlePresencial(e.target.checked);
                    }}
                    disabled
                  />
                  <Typography variant="body" sx={{ ml: 3 }}>
                    Presencial
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "24px",
                  }}
                >
                  <AntSwitch1
                    inputProps={{ "aria-label": "ant design1" }}
                    checked={videollamada}
                    onChange={(e) => {
                      handleVideollamada(e.target.checked);
                    }}
                  />
                  <Typography variant="body" sx={{ ml: 3 }}>
                    Videollamada
                  </Typography>
                </Box>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginBottom: "25px",
                  }}
                >
                  <AntSwitch2
                    inputProps={{ "aria-label": "ant design2" }}
                    checked={domicilio}
                    onChange={(e) => {
                      handleDomicilio(e.target.checked);
                    }}
                  />
                  <Typography variant="body" sx={{ ml: 3 }}>
                    A domicilio
                  </Typography>
                </div> */}
              </Box>
              <Typography
                variant="body2"
                sx={{ marginBottom: 2, marginTop: 2 }}
              >
                Se puede cambiar en configuraciones más tarde *
              </Typography>
            </Card>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "50px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "95%",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#006686",
                  textAlign: "center",
                  fontSize: "35px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "42px",
                }}
              >
                3 de 3
              </Typography>
              <CheckCircleOutlineIcon
                sx={{ fontSize: "35px" }}
                color="disabled"
              />
            </Box>

            <Card
              sx={{
                mt: 2,
                borderRadius: "28px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                p: { xs: 2, md: 4, lg: 6 },
                gap: 2,
              }}
            >
              <Typography
                variant="titleLarge"
                sx={{
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Datos bancarios y medios de pago
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#006686",
                      textAlign: "center",
                      fontSize: "15px",
                      fontStyle: "normal",
                      marginBottom: "10px",
                    }}
                  >
                    Métodos de pagos aceptados
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "12px",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setParticular(e.target.checked);
                      }}
                      sx={{
                        color: "#006686",
                        "&.Mui-checked": {
                          color: "#006686",
                        },
                      }}
                    />
                    <Typography variant="body" sx={{ ml: 3 }}>
                      Particular
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "12px",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setFonasa(e.target.checked);
                      }}
                      sx={{
                        color: "#006686",
                        "&.Mui-checked": {
                          color: "#006686",
                        },
                      }}
                    />
                    <Typography variant="body" sx={{ ml: 3 }}>
                      Fonasa
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "12px",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setIsapre(e.target.checked);
                        setIsapreArray([]);
                      }}
                      sx={{
                        color: "#006686",
                        "&.Mui-checked": {
                          color: "#006686",
                        },
                      }}
                    />
                    <Typography variant="body" sx={{ ml: 3 }}>
                      Isapre
                    </Typography>
                  </Box>
                </Box>

                {isapre && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        color: "#006686",
                        textAlign: "center",
                        fontSize: "15px",
                        fontStyle: "normal",
                        marginBottom: "10px",
                      }}
                    >
                      Al seleccionar Isapre, deberá indicar las que aceptas
                    </Typography>
                  </Box>
                )}
                {isapreArray.map((el, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        fontSize: "20px",
                      }}
                      variant="body1"
                    >
                      {el.value}
                    </Typography>
                    <IconButton sx={{ color: "black" }}>
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </Box>
                ))}

                {isapre && (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Select
                      required
                      variant="filled"
                      fullWidth
                      startAdornment={
                        <InputAdornment
                          position="start"
                          sx={{ color: "black" }}
                        >
                          <AddBoxOutlinedIcon />
                        </InputAdornment>
                      }
                      defaultValue={"0"}
                      onChange={(e) => {
                        setIsapreSelected(e.target.value);
                      }}
                    >
                      <MenuItem value={"0"}>Seleccione Isapre</MenuItem>
                      {isapres.map((e, i) => (
                        <MenuItem value={e.value || ""} key={i}>
                          {e.value.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button
                      sx={{
                        mt: 2,
                      }}
                      variant="outlined"
                      fullWidth
                      onClick={() => {
                        handleSelectIsapre();
                      }}
                    >
                      Añadir
                    </Button>
                  </Box>
                )}

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      color: "#006686",
                      textAlign: "center",
                      fontSize: "15px",
                      fontStyle: "normal",
                      marginBottom: "10px",
                    }}
                  >
                    Datos bancarios
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    gap: 2,
                  }}
                >
                  <Select
                    required
                    variant="filled"
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start" sx={{ color: "black" }}>
                        <AccountBalanceOutlinedIcon />
                      </InputAdornment>
                    }
                    value={bankSelected}
                    defaultValue={0}
                    onChange={(e) => {
                      setBankSelected(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>Seleccione banco</MenuItem>
                    {bankOptions.map((e, i) => (
                      <MenuItem value={e.value || i} key={i}>
                        {e.value.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                  <Select
                    required
                    variant="filled"
                    fullWidth
                    value={typeAccountSelected}
                    defaultValue={0}
                    onChange={(e) => {
                      setTypeAccountSelected(e.target.value);
                    }}
                  >
                    <MenuItem value={0}>Seleccione tipo de cuenta</MenuItem>
                    {typeAccountOptions.map((e, i) => (
                      <MenuItem value={e.value || i} key={i}>
                        {e.value.toUpperCase()}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    required
                    placeholder="Número de cuenta"
                    // value={rut}
                    type="number"
                    onChange={(e) => setNumAccount(e.target.value)}
                    variant="filled"
                    fullWidth
                  />
                </Box>
                <Typography
                  variant="body2"
                  sx={{ marginBottom: 2, marginTop: 2 }}
                >
                  Se puede cambiar en configuraciones más tarde *
                </Typography>
              </Box>
            </Card>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 6,
              marginBottom: 6,
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              disableElevation
              fullWidth
              onClick={() => {
                handleViews("secondView");
              }}
            >
              Siguiente
            </Button>
          </Box>
        </>
      )}
      {view == "secondView" && <AddDocs />}
      {view == "thirdView" && <Docs />}
      {view == "fourthView" && (
        <>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={logo}
              style={{
                marginTop: "20px",
                width: "180px",
                marginBottom: "90px",
              }}
            />
            <Typography
              sx={{
                color: "var( #006686)",
                textAlign: "center",
                fontSize: "35px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "42px",
                marginBottom: "15px",
              }}
            >
              Agregar profesionales
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "50px",
              width: "100%",
            }}
          >
            <Card
              id="card"
              sx={{
                mt: 2,
                borderRadius: "28px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                p: { xs: 2, md: 4, lg: 6 },
                gap: 2,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  marginTop: "50px",
                  marginBottom: "10px",
                  width: "100%",
                }}
                align="center"
              >
                Es necesario que agregue una foto de perfil para que los
                usuarios puedan reconocerlo fácilmente
              </Typography>

              {centerMedImage !== "" ? (
                <Container sx={styleImage}>
                  <img
                    style={{
                      objectFit: "contain",
                      width: "25vw",
                      height: "25vh",
                    }}
                    className="files-gallery-item"
                    src={centerMedImage.preview.url}
                    alt="photo"
                    onClick={() => {
                      setCenterMedImage("");
                    }}
                  />
                </Container>
              ) : (
                <Files
                  className="files-dropzone"
                  onChange={(e) => {
                    handleEditImageLogo(e, "centerMedImage");
                  }}
                  // onError={handleError}
                  accepts={["image/*"]}
                  clickable
                  style={{
                    width: "60%",
                    height: "27vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    border: "1px solid #006686",
                    color: "#006686",
                    borderRadius: "25px 25px 25px 25px",
                    cursor: "pointer",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  maxFiles={1}
                >
                  Arrastre o haga click para seleccionar
                </Files>
              )}

              <Typography
                variant="h5"
                sx={{
                  marginTop: "50px",
                  marginBottom: "10px",
                  width: "100%",
                }}
                align="center"
              >
                Puede añadir un logo para los documentos que le genera el
                sistema.
              </Typography>

              {centerMedLogo !== "" ? (
                <Container sx={styleImage}>
                  <img
                    style={{
                      objectFit: "contain",
                      width: "25vw",
                      height: "25vh",
                    }}
                    className="files-gallery-item"
                    src={centerMedLogo.preview.url}
                    alt="photo"
                    onClick={() => {
                      setCenterMedLogo("");
                    }}
                  />
                </Container>
              ) : (
                <Files
                  className="files-dropzone"
                  onChange={(e) => {
                    handleEditImageLogo(e, "centerMedLogo");
                  }}
                  // onError={handleError}
                  accepts={["image/*"]}
                  clickable
                  style={{
                    width: "60%",
                    height: "27vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    border: "1px solid #006686",
                    color: "#006686",
                    borderRadius: "25px 25px 25px 25px",
                    cursor: "pointer",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  maxFiles={1}
                >
                  Arrastre o haga click para seleccionar
                </Files>
              )}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "32vw",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "var( #006686)",
                      textAlign: "center",
                      fontSize: "25px",
                      fontStyle: "normal",
                      fontWeight: "200",
                      lineHeight: "42px",
                    }}
                  >
                    Cuéntanos de tí
                  </Typography>
                </Box>
                <ArrowDropDownOutlinedIcon sx={{ fontSize: "35px" }} />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextField
                  sx={{
                    width: "80%",
                    backgroundColor: "#DEE3EB",
                  }}
                  required
                  placeholder="Descripción del centro medico"
                  type="text"
                  onChange={(e) => setCenterMedDescription(e.target.value)}
                  variant="standard"
                  multiline
                  rows={8}
                  inputProps={{
                    style: {
                      paddingLeft: "10px",
                    },
                  }}
                />
              </Box>

              <br />
            </Card>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // justifyContent: "space-between",
              marginTop: "50px",
              marginBottom: "50px",
              width: "450px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#006686",
                borderRadius: "25px",
                width: "200px",
              }}
              onClick={() => {
                // handleViews("fifthView");
                handleCompleteProfile();
              }}
            >
              Finalizar
            </Button>
          </Box>
        </>
      )}
    </Container>
  );
}
