import React, { useEffect, useState, useRef } from "react";
import {
  Avatar,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Box,
  TextField,
  capitalize,
} from "@mui/material";
// import { createStyles, makeStyles } from "@emotion/styled"
import {
  getMessages,
  markMessagesAsRead,
  sendMessageChat,
} from "../database/chats";
import MessageChat from "./messages_component";
import { Send } from "@mui/icons-material";

//REDUX IMPORTS
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export default function CardViewChat({
  idChat,
  idClient,
  dataClient,
  setLastMessage,
}) {
  // console.log("idChat", idChat);
  const [data, setData] = useState([]);
  const [messageText, setMessageText] = useState("");
  const containerRef = useRef(null);
  const [handleMessageButton, setHandleMessageButton] = useState(true);

  const id = useSelector((state) => state.auth.idUser);

  const sendMessage = async () => {
    setHandleMessageButton(false);
    try {
      if (messageText != "") {
        sendMessageChat(messageText, idChat, id, idClient);
        setMessageText("");
      }
      setHandleMessageButton(true);
    } catch (error) {
      console.log("Error al enviar mensaje", error);
      Swal.fire({
        icon: "error",
        title: "Error al enviar mensaje",
        text: "Intente de nuevo",
      }).then(() => {
        setHandleMessageButton(true);
      });
    }
  };

  useEffect(() => {
    if (idChat != null) {
      async function getData() {
        // console.log('init');
        // console.log("id", id);
        let a = await getMessages(idChat, setData);
        await markMessagesAsRead(idChat, id);
        // console.log("a", a);
        // console.log('end');
      }
      // console.log("before get data");
      getData();
    }
  }, [idChat, id]);

  useEffect(() => {
    if (data.length > 0 && containerRef.current) {
      const lastMessage = containerRef.current.lastChild;
      // console.log("lastMessage", lastMessage);
      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: "smooth" });
        // setLastMessage(data[data.length - 1].message);
        // console.log("lastMessage", data[data.length - 1].message);
      }
    }
  }, [data]);

  return (
    <Box style={{ height: "100%" }}>
      {idChat !== null && (
        <Box
          ref={containerRef}
          sx={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            justifyContent: "start",
            overflowY: "scroll",
            padding: 0,
            maxWidth: "100%",
            minHeight: "100%",
          }}
        >
          {/* {console.log("idSender", data[2], "id", id)} */}
          {data.map((message, i) =>
            message.type == "initMessage" ? (
              console.log("message tiped")
            ) : message.idSender == id ? (
              <MessageChat
                key={i}
                isMine={true}
                message={message.message}
                date={message.createdAt}
                type={message.type}
                user="doctor"
                data={message}
              />
            ) : (
              <MessageChat
                key={i}
                isMine={false}
                message={message.message}
                date={message.createdAt}
                type={message.type}
                user="client"
              />
            )
          )}
        </Box>
      )}

      {idChat !== null && (
        <Box
          sx={{
            backgroundColor: "white",
            position: "sticky",
            bottom: "0",
            width: "100%",
          }}
        >
          <Divider />
          <TextField
            sx={{
              px: { xs: 2, sm: 3, md: 4 },
              py: 3,
              borderRadius: "25px",
              display: "flex",
              flexDirection: "row",
              bottom: "0px",
              width: "100%",
            }}
            autoComplete="off"
            value={messageText}
            disabled={!handleMessageButton}
            onChange={(e) => {
              setMessageText(e.target.value);
            }}
            fullWidth
            id="outlined-basic"
            placeholder="Escriba un mensaje"
            variant="outlined"
            InputProps={{
              style: { borderRadius: "50px" },
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={sendMessage}
                    edge="end"
                  >
                    <Send
                      sx={
                        messageText != ""
                          ? {
                              color: "#006686",
                            }
                          : {
                              color: "grey",
                            }
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage();
                e.preventDefault();
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
}
