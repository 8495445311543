import { Button, Container, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { updateSettingsPOS } from '../database/doctors';
import Swal from 'sweetalert2';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import loadingSVG from '../assets/loading.svg';

export default function AdminPaysComponent({ doctorSelected, reload }) {

    //Data pagos
    const [codePos, setCodePos] = useState('');
    const [rutPos, setRutPos] = useState('');
    const [secretKey, setSecretKey] = useState('');
    const [secretKeyPOS, setSecretKeyPOS] = useState('');
    const [accId, setAccId] = useState('');
    const [showSecretKey, setShowSecretKey] = useState(false);

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const toggleShowSecretKey = () => {
        setShowSecretKey(!showSecretKey);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeCode = (event) => {
        const newValue = event.target.value;
        setCodePos(newValue);
    };

    const handleChangeRut = (event) => {
        const newValue = event.target.value;
        setRutPos(newValue);
    }

    const handleChangeSecretKeyPos = (event) => {
        const newValue = event.target.value;
        setSecretKeyPOS(newValue);
    }

    const handleChangeSecretKey = (event) => {
        const newValue = event.target.value;
        setSecretKey(newValue);
    }

    const handleChangeAccId = (event) => {
        const newValue = event.target.value;
        setAccId(newValue);
    }

    const saveValue = async () => {
        const res = await updateSettingsPOS(doctorSelected.id, codePos, rutPos, accId, secretKey, secretKeyPOS);
        if (res) {
            // toast.success('Valor guardado');
            Swal.fire({
                icon: 'success',
                title: 'Valor guardado',
                showConfirmButton: true,
            }).then(() => {
                reload();
            });
        } else {
            // toast.error('Error al guardar el valor');
            Swal.fire({
                icon: 'error',
                title: 'Error al guardar el valor',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    useEffect(() => {
        async function getData() {
            Swal.fire({
                imageUrl: loadingSVG,
                imageWidth: 300,
                imageHeight: 300,
                title: "Cargando...",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
            // console.log(doctorSelected);
            if (doctorSelected) {
                setCodePos(doctorSelected.codePos === undefined ? '' : doctorSelected.codePos);
                setRutPos(doctorSelected.rutPos === undefined ? '' : doctorSelected.rutPos);
                setSecretKey(doctorSelected.secretKey === undefined ? '' : doctorSelected.secretKey);
                setAccId(doctorSelected.accountId === undefined ? '' : doctorSelected.accountId);
                setSecretKeyPOS(doctorSelected.secretKeyPOS === undefined ? '' : doctorSelected.secretKeyPOS);
            }
            Swal.close();
        }

        getData();

    }, [])

    return (
        <Container>
            <Typography sx={{ fontFamily: 'Nunito', fontSize: "1.2em", marginLeft: '5%', marginTop: '3%' }}>Datos del POS</Typography>
            <Container
                sx={{
                    marginTop: "5px",
                    width: "100%", // Ancho del Container al 100%
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "5px",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                }}
            >
                <TextField
                    sx={{ marginTop: '1vh', width: '45%', backgroundColor: '#DCE3E9', color: 'black' }}
                    type="text"
                    value={codePos}
                    onChange={handleChangeCode}
                    label="Código del POS"
                    variant="filled"
                    placeholder="Ingrese el código del POS"
                />
                <TextField
                    sx={{ marginTop: '1vh', width: '45%', backgroundColor: '#DCE3E9', color: 'black' }}
                    type="text"
                    value={rutPos}
                    onChange={handleChangeRut}
                    label="Rut del comercio"
                    variant="filled"
                    placeholder="Ingrese el rut del comercio sin puntos y con guión"
                />

            </Container >
            <Container sx={{
                marginLeft: "5%",
                marginTop: "5px",
                width: "100%", // Ancho del Container al 100%
                display: "flex",
                flexDirection: "row",
                marginBottom: "5px",
                alignItems: "center",
                justifyContent: "start",
            }}>
                <TextField
                    sx={{ marginTop: '1vh', width: '90%', backgroundColor: '#DCE3E9', color: 'black' }}
                    type="text"
                    value={secretKeyPOS}
                    onChange={handleChangeSecretKeyPos}
                    label="Clave secreta POS"
                    variant="filled"
                    placeholder="Ingrese la clave secreta para el equipo (POS)"
                />
            </Container>
            <Typography sx={{ fontFamily: 'Nunito', fontSize: "1.2em", marginLeft: '5%', marginTop: '3%' }}>Datos de Webpay</Typography>
            <Container
                sx={{
                    marginTop: "5px",
                    width: "100%", // Ancho del Container al 100%
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "5px",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                }}
            >
                <TextField
                    sx={{ marginTop: '1vh', width: '45%', backgroundColor: 'rgba(220, 227, 233, 1)', color: 'black' }}
                    type="text"
                    value={accId}
                    onChange={handleChangeAccId}
                    label="Serial number"
                    variant="filled"
                    placeholder="Ingrese el código del POS"
                />
                <TextField
                    sx={{ marginTop: '1vh', width: '45%', backgroundColor: 'rgba(220, 227, 233, 1)', color: 'black' }}
                    type={showSecretKey ? 'text' : 'password'}
                    value={secretKey}
                    onChange={handleChangeSecretKey}
                    label="Clave secreta Webpay"
                    variant="filled"
                    placeholder="Ingrese el rut del comercio con puntos y guión"
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={toggleShowSecretKey} edge="end">
                                {showSecretKey ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        ),
                    }}
                />

            </Container >
            <div style={{
                height: '30%'
            }} />
            <div
                style={{
                    width: "100%",
                    // height: "10%",
                    display: "flex",
                    alignItems: "flex-end",
                    flexDirection: "row-reverse",
                    alignItems: "center",
                    borderTop: "1px solid rgba(0,0,0,0.3)",
                }}
            >
                <Button
                    onClick={saveValue}
                    style={{
                        marginTop: "50px",
                        // marginBottom: "15px",
                        width: "13%",
                        backgroundColor: "#006686",
                        color: "#77F8E0",
                        borderRadius: "25px",
                        padding: "10px",
                        marginRight: "10px",
                        textDecoration: "none",
                        textTransform: "none",
                    }}
                >
                    Guardar
                </Button>
            </div>
        </Container>
    )
}
