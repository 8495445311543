import { addDoc, collection, doc, serverTimestamp, updateDoc, getDocs, where, query } from "firebase/firestore";
import { db } from "./config";

export async function saveInvoiceResponse(data, idRequest) {
    try {
        const docRef = collection(db, "invoices");
        await addDoc(docRef, { data: data, idRequest: idRequest, createdAt: serverTimestamp()});
        return true;
    } catch (error) {
        console.error("Error creating clinic register request:", error);
    }
}

export async function getInvoiceByIdRequest(idRequest) {
    const querySnapshot = await getDocs(query(collection(db, "invoices"), where("idRequest", "==", idRequest)));
    const invoices = [];
    querySnapshot.forEach((doc) => {
        invoices.push({
            id: doc.id,
            ...doc.data(),
        });
    });
    return invoices;
}

export async function updateSettingsSII(idDoctor, keySII, tokenSII) {
    try {
        const docRef = doc(collection(db, "users"), idDoctor);
        await updateDoc(docRef, { keySII: keySII, tokenSII: tokenSII});
        return true;
    } catch (error) {
        console.error("Error updating settings SII:", error);
        return false;
    }
}