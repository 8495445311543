import React, { useEffect, useState } from 'react';

const MapComponent = ({ ubication }) => {
    const [coordinates, setCoordinates] = useState(null);

    useEffect(() => {
        async function getLocation() {
            return new Promise((resolve, reject) => {
                const geocoder = new window.google.maps.Geocoder();
                geocoder.geocode({ address: ubication }, (results, status) => {
                    if (status === 'OK' && results[0]) {
                        const { lat, lng } = results[0].geometry.location;
                        resolve({ lat: lat(), lng: lng() });
                    } else {
                        reject('La ubicación no se pudo encontrar');
                    }
                });
            });
        }

        async function fetchLocation() {
            try {
                const result = await getLocation();
                setCoordinates(result);
            } catch (error) {
                alert(error);
            }
        }

        fetchLocation();
    }, [ubication]);


    return (
        <div>
            {coordinates && (
                <div style={{ width: '100%', height: '100%' }}>
                    <iframe
                        title="map"
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                        src={`https://maps.google.com/maps?q=${coordinates.lat},${coordinates.lng}&z=15&output=embed`}
                    />
                </div>
            )}
        </div>
    );
};

export default MapComponent;
