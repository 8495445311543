import {
  addDoc,
  collection,
  doc,
  serverTimestamp,
  updateDoc,
  query,
  where,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db, storage } from "./config";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export async function createClinicRegisterRequest(data) {
  try {
    const docRef = collection(db, "register_requests");
    await addDoc(docRef, {
      status: "pending",
      createdAt: serverTimestamp(),
      typeRequest: "medicalCenter",
      data,
    });
    return true;
  } catch (error) {
    console.error("Error creating clinic register request:", error);
  }
}
export async function getClinicById(id) {
  const queryData = query(
    collection(db, "users"),
    where("role", "==", "medicalCenter"),
    where("id", "==", id)
  );
  const clicnic = await getDocs(queryData);
  return clicnic.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
}
export async function uploadFiles(file) {
  const storageRef = ref(storage, `medicalCenter/${file.name}-${Date.now()}`);

  try {
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Error al subir archivo:", error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            console.error("Error al obtener el URL de descarga:", error);
            reject(error);
          }
        }
      );
    });
  } catch (error) {
    console.error("Error al subir el archivo:", error);
    return null;
  }
}

export async function createCenterMed(data, userId) {
  // console.log(data);
  try {
    const docRef = doc(collection(db, "users"), userId);
    const ress = await setDoc(docRef, {
      ...data,
      role: "medicalCenter",
      createdAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function updateClinic(data, userId) {
  try {
    const docRef = doc(collection(db, "users"), userId);
    const clinicData = { ...data, id: userId };

    await updateDoc(docRef, clinicData);

    return true;
  } catch (error) {
    console.error("Error al actualizar la clinica:", error);
    return false;
  }
}

export async function getAllClinic() {
  const queryData = query(
    collection(db, "users"),
    where("role", "==", "medicalCenter")
  );
  const doctors = await getDocs(queryData);
  return doctors.docs.map((doc) => {
    return {
      id: doc.id,
      ...doc.data(),
    };
  });
}
