import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { Line } from 'rc-progress';

export default function LoadingInvoice({ open, onClose }) {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState('Enviando datos para generar la boleta...');

  useEffect(() => {
    const timer = setInterval(() => {
      if (progress < 100) {
        setProgress(progress + 1);
      } else {
        onClose();
        clearInterval(timer);
      }
    }, 180);

    return () => {
      clearInterval(timer);
    };
  }, [progress, onClose]);

  useEffect(() => {
    setProgress(0);
  }, [open]);

  useEffect(() => {
    const statusTimer = setTimeout(() => {
      setStatus('Ingresando al SII...');
    }, 3000);

    const statusTimer2 = setTimeout(() => {
      setStatus('Ingresando datos...');
    }, 7000);

    const statusTimer3 = setTimeout(() => {
      setStatus('Creando boleta de honorarios...');
    }, 10000);

    return () => {
      clearTimeout(statusTimer);
      clearTimeout(statusTimer2);
      clearTimeout(statusTimer3);
    };
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>{status}</DialogTitle>
      <DialogContent>
        <Line percent={progress} strokeWidth={4} strokeColor="#006686" />
      </DialogContent>
    </Dialog>
  );
}
