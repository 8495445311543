import React, { useEffect } from 'react';

function CameraAndMicrophonePermissions() {
  useEffect(() => {
    const requestPermissions = async () => {
      try {
        // Verificar si el navegador admite la API de MediaDevices
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          // Obtener permisos para acceder a la cámara y al micrófono
          const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          // El usuario ha dado permisos, puedes utilizar el stream para la cámara y el micrófono
          console.log('Permisos de cámara y micrófono concedidos');
          // Aquí puedes hacer lo que necesites con el stream, como mostrar la cámara o grabar audio
        } else {
          console.error('La API de MediaDevices no es compatible con este navegador');
        }
      } catch (error) {
        // El usuario ha denegado los permisos o ha ocurrido un error
        console.error('Error al acceder a la cámara y al micrófono:', error);
      }
    };

    requestPermissions();
  }, []);

  return null; // Puedes devolver null ya que este componente no renderiza nada visible
}

export default CameraAndMicrophonePermissions;