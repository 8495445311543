import React, { useState } from "react";
import {
  Button,
  Card,
  TextField,
  capitalize,
  Typography,
  List,
  ListItemText,
  ListItem,
  ListItemAvatar,
  Avatar,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useEffect } from "react";
import Swal from "sweetalert2";
import FingerprintOutlinedIcon from "@mui/icons-material/FingerprintOutlined";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { getRegisterRequest } from "../database/register_request";
import background from "../assets/backgroundRequest.svg";
import { CalendarMonthOutlined, CircleOutlined } from "@mui/icons-material";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
// import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import { auth } from "../database/config";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { createDoctor } from "../database/doctors";
import {
  acceptRegisterRequest,
  declinedRegisterRequest,
} from "../database/register_request";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { createCenterMed } from "../database/clinic";
import loadingSVG from "../assets/logo_Loading.gif";

export default function CompleteRegister() {
  const navigate = useNavigate();
  const [registerList, setRegisterList] = useState([]);
  const [dataToShow, setDataToshow] = useState([]);
  const [selectedTab, setSelectedTab] = useState("doctor");
  const [requestListFiltered, setRequestListFiltered] = useState([]);

  useEffect(() => {
    const getRegister = async () => {
      const data = await getRegisterRequest();

      const temp = data.map((e) => {
        if (e.typeRequest === "doctor") {
          e.data.rut = formatearRut(e.data.rut);
        }
        return e;
      });
      setRegisterList(temp); // Asignamos el nuevo array modificado
      setRequestListFiltered(temp.filter((e) => e.typeRequest === "doctor"));
    };

    function formatearRut(rut) {
      rut = rut.replace(/[^0-9kK]/g, "");
      const numero = rut.slice(0, -1);
      const digitoVerificador = rut.slice(-1).toUpperCase();
      const rutFormateado = numero.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return `${rutFormateado}-${digitoVerificador}`;
    }
    getRegister();
  }, [navigate]);

  function handleChangeDataToShow(prop) {
    let a = registerList.filter((word) => word.typeRequest === prop);
    setRequestListFiltered(a);
  }
  function eliminarrequestPorId(id) {
    const nuevoArray = registerList.filter((dato) => dato.id !== id);
    setRegisterList(nuevoArray);
  }

  const validarRutChileno = (rut) => {
    // console.log("Este es el rut", rut);
    return rut.replace(/[^0-9kK]/g, "").toUpperCase();
  };
  const acceptClinicRequest = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    try {
      let clinicData = {
        name: dataToShow.data.clinicName,
        email: dataToShow.data.clinicEmail,
        status: "disabled",
        firstTime: true,
      };
      const res = await createUserWithEmailAndPassword(
        auth,
        dataToShow.data.clinicEmail,
        dataToShow.data.clinicPassword
      );
      await sendEmailVerification(res.user);
      await acceptRegisterRequest(dataToShow.id);
      let createClinic = await createCenterMed(clinicData, res.user.uid);

      if (createClinic) {
        Swal.close();
        eliminarrequestPorId(dataToShow.id);
        Swal.fire({
          icon: "success",
          title: "Registro de centro médico aceptado",
          text: "El registro del centro médico ha sido aceptado correctamente.",
        });
      } else {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "Error al aceptar el registro",
          text: "Hubo un problema al intentar aceptar la solicitud de registro del centro médico. Por favor, inténtalo de nuevo.",
        });
      }
    } catch (error) {
      Swal.close();
      Swal.fire({
        icon: "error",
        title: "Error al aceptar el registro",
        text: "Hubo un problema al intentar aceptar la solicitud de registro del centro médico. Por favor, inténtalo de nuevo.",
      });
      console.log("no se pudo aceptar la request", error);
    }
  };

  const acceptRequest = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    let rut = validarRutChileno(dataToShow.data.rut);
    try {

      const res = await createUserWithEmailAndPassword(
        auth,
        dataToShow.data.email,
        dataToShow.data.password
      );
      await sendEmailVerification(res.user);
      await acceptRegisterRequest(dataToShow.id);
      const createDoc = await createDoctor(
        {
          rut: rut.clean ? rut.clean : rut,
          name: dataToShow.data.name,
          lastName: dataToShow.data.lastName,
          email: dataToShow.data.email,
          serialNumber: dataToShow.data.serialNumber,
          birthdate: dataToShow.data.birthdate,
          genre: dataToShow.data.genre,
          nationality: dataToShow.data.nationality,
          registerNumber: dataToShow.data.registerNumber,
          status: "disabled",
          firstTime: true,
        },
        res.user.uid
      );

      if (createDoc) {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Doctor creado",
          text: "El doctor se ha creado correctamente ",
        });
        eliminarrequestPorId(dataToShow.id);
        setDataToshow([]);
      } else {
        Swal.close();
        Swal.fire({
          icon: "error",
          title: "¡Ups...",
          text: "Se ha producido un error al crear el perfil del doctor.",
        });
      }
    } catch (error) {
      console.log("El error", error.code || error);
      if (error.code || error === "auth/email-already-in-use") {
        Swal.close();
        Swal.fire({
          icon: "info",
          title: "Correo ya registrado",
          text: "El correo electrónico proporcionado ya está asociado a una cuenta en nuestro sistema.",
          showCancelButton: true,
          cancelButtonText: "Eliminar solicitud",
          cancelButtonColor: "#B80000",
        }).then(async (e) => {
          if (e.isDismissed) {
            const res = await declinedRegisterRequest(dataToShow.id);
            eliminarrequestPorId(dataToShow.id);
            setDataToshow([]);
            if (res) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Registro de doctor rechazado",
                text: "La solicitud de registro del doctor ha sido rechazada correctamente.",
              });
            } else {
              Swal.close();
              Swal.fire({
                icon: "error",
                title: "Error al rechazar el registro",
                text: "Hubo un problema al intentar rechazar la solicitud de registro del doctor. Por favor, inténtalo de nuevo.",
              });
            }
          }
          // console.log(e);
        });
      }
    }
  };
  const declinedRequest = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    let namecomplete = dataToShow.data.name + " " + dataToShow.data.lastName;
    const res = await declinedRegisterRequest(dataToShow.id);
    eliminarrequestPorId(dataToShow.id);
    const resMail = await axios.post(
      "https://us-central1-dfinder-5f8ae.cloudfunctions.net/app/sendMailDeclined",
      {
        email: dataToShow.data.email,
        name: namecomplete,
        subject: "Tu solicitud a GetMed fue rechazada",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Access-Control-Allow-Origin",
        },
      }
    );

    setDataToshow([]);
    if (res && resMail) {
      Swal.close();
      eliminarrequestPorId(dataToShow.id);

      Swal.fire({
        icon: "success",
        title: "Registro de doctor rechazado",
        text: "La solicitud de registro del doctor ha sido rechazada correctamente.",
      });
    } else {
      Swal.close();

      Swal.fire({
        icon: "error",
        title: "Error al rechazar el registro",
        text: "Hubo un problema al intentar rechazar la solicitud de registro del doctor. Por favor, inténtalo de nuevo.",
      });
    }

  };
  return (
    <Card
      sx={{
        backgroundColor: "#FFFFFF",
        borderRadius: "25px 25px 25px 25px",
        height: "85vh",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "space-between",
      }}
    >
      <div
        style={{
          top: "0px",
          width: "20vw",
          borderRight: "1px solid rgba(0,0,0,0.2)",
          height: "84vh",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
        }}
      >
        <div
          style={{
            width: "100%",
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            // borderBottom: '1px solid rgba(192, 199, 205, 1)'
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
              setDataToshow([]);

              handleChangeDataToShow(newValue);
            }}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flex: 1,
              width: "100%",
              borderBottom: "1px solid rgba(192, 199, 205, 1)",
            }}
          >
            <Tab
              value={"doctor"}
              sx={{
                flex: 1,
              }}
              label="Doctores"
            />
            <Divider orientation="vertical" />
            <Tab
              sx={{
                flex: 1,
              }}
              value={"medicalCenter"}
              label="Centros medicos"
            />
          </Tabs>
        </div>

        <List
          component="nav"
          aria-label="secondary mailbox folder"
          sx={{
            width: "100%",
            borderRight: "1px solid rgba(0,0,0,0.2)",
            height: "84vh",
            overflow: "auto",
          }}
        >
          {requestListFiltered.map((element, i) => {
            if (element.status === "pending") {
              // console.log("Hola", e.data.name);
              return (
                <ListItem
                  button
                  key={i}
                  onClick={() => {
                    setDataToshow(element);
                  }}
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        width: "45px",
                        height: "45px",
                        backgroundColor: "#006686",
                      }}
                      alt={
                        element.data.name
                          ? element.data.name
                          : element.data.clinicName
                      }
                    >
                      {element.data.name
                        ? element.data.name.charAt(0).toUpperCase()
                        : element.data.clinicName.charAt(0).toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      capitalize(
                        element.data.name
                          ? element.data.name
                          : element.data.clinicName
                      ) +
                      " " +
                      capitalize(
                        element.data.lastName ? element.data.lastName : ""
                      )
                    }
                    secondary={element.data.clinicEmail}
                  />
                  <ArrowRightIcon />
                </ListItem>
              );
            }
          })}
        </List>
      </div>
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        {dataToShow.length === 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={background}
              style={{ marginTop: "10vh", marginBottom: "2vh" }}
            />
            <Typography
              sx={{
                color: "var(--m-3-sys-light-primary, #006686)",
                fontFamily: "Nunito",
                textAlign: "center",
                fontSize: "25px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "42px",
                marginBottom: "10vh",
                width: "60%",
              }}
            >
              Seleccione un usuario para ver la solicitud de registro
            </Typography>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontFamily: "Nunito",
                color: "#006686",
                marginTop: "2vh",
                textAlign: "center",
                fontStyle: "normal",
                fontWeight: "bold",
                marginBottom: "5vh",
              }}
            >
              Solicitud de registro
            </Typography>
            <Typography
              variant="h5"
              sx={{
                color: "#006686",
                fontFamily: "Nunito",
                marginTop: "2vh",
                marginLeft: "3vw",
                textAlign: "start",
                fontStyle: "normal",
                fontWeight: "400",
                marginBottom: "5vh",
              }}
            >
              {selectedTab !== "doctor"
                ? "datos de la clinica"
                : "Datos de doctor"}
            </Typography>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                marginBottom: "25px",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  marginBottom: "25px",
                  width: "30vw",
                  height: "100%",
                }}
              >
                {selectedTab !== "doctor" ? (
                  <>
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      variant="standard"
                      margin="normal"
                      disabled
                      value={dataToShow.data.clinicName}
                      placeholder="nombre de la clinica"
                      type="text"
                      autoComplete="clinicName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlinedIcon />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                    />{" "}
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      variant="standard"
                      margin="normal"
                      disabled
                      value={dataToShow.data.rut}
                      placeholder="nombre de la clinica"
                      type="text"
                      autoComplete="clinicName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlinedIcon />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                    />
                  </>
                ) : (
                  <>
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      margin="normal"
                      disabled
                      placeholder="Rut"
                      value={dataToShow.data.rut}
                      type="text"
                      variant="standard"
                      endad
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FingerprintOutlinedIcon />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                    />
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      variant="standard"
                      margin="normal"
                      disabled
                      value={dataToShow.data.name}
                      placeholder="Nombre"
                      type="text"
                      autoComplete="name"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlinedIcon />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                    />
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      margin="normal"
                      disabled
                      placeholder="Apellido"
                      type="text"
                      autoComplete="lastName"
                      value={dataToShow.data.birthdate}
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CalendarMonthOutlined />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                    />
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      margin="normal"
                      disabled
                      placeholder="Nacionalidad"
                      type="text"
                      autoComplete="nacionalidad"
                      value={
                        dataToShow.data.nationality
                          ? dataToShow.data.nationality
                          : "**"
                      }
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <FlagOutlinedIcon />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                    />
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      margin="normal"
                      disabled
                      hiddenLabel
                      placeholder="Numero de registro"
                      value={dataToShow.data.registerNumber}
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersOutlinedIcon />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                      // onChange={(e) => setRegisterNumber(e.target.value)}
                    />
                  </>
                )}
                <div style={{ height: "100%" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    alignItems: "center",
                    width: "40vw",
                    marginTop: "30px",
                  }}
                >
                  <Button
                    variant="text"
                    sx={{ color: "#006686", width: "150px" }}
                    onClick={() => declinedRequest()}
                  >
                    Rechazar
                  </Button>
                  <Button
                    variant="contained"
                    sx={{
                      background: "#006686",
                      color: "#77F8E0",
                      borderRadius: "25px 25px 25px 25px",
                      width: "150px",
                      marginLeft: "30px",
                    }}
                    onClick={() => {
                      if (selectedTab === "doctor") {
                        acceptRequest();
                      } else {
                        acceptClinicRequest();
                      }
                    }}
                  >
                    Aceptar
                  </Button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "start",
                  alignItems: "center",
                  marginBottom: "25px",
                  width: "30vw",
                  height: "100%",
                }}
              >
                {selectedTab !== "doctor" ? (
                  <TextField
                    sx={{
                      width: "80%",
                      backgroundColor: "#DEE3EB",
                    }}
                    margin="normal"
                    disabled
                    placeholder="Email de la clinica"
                    type="text"
                    variant="standard"
                    value={dataToShow.data.clinicEmail}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <MailOutlineIcon />
                        </InputAdornment>
                      ),
                      style: {
                        fontSize: "1.2em", // Cambia el tamaño de la fuente
                        padding: "3%", // Aumenta el padding
                      },
                    }}
                    inputProps={{
                      style: {
                        height: "90%", // Aumenta la altura
                      },
                    }}
                  />
                ) : (
                  <>
                    {" "}
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      margin="normal"
                      disabled
                      placeholder="Numero de serie de cedula de indentidad"
                      type="text"
                      variant="standard"
                      value={dataToShow.data.serialNumber}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <BadgeOutlinedIcon />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                    />
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      variant="standard"
                      margin="normal"
                      disabled
                      value={dataToShow.data.lastName}
                      placeholder="Apellido"
                      type="text"
                      autoComplete="lastName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonOutlinedIcon />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                    />
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      margin="normal"
                      disabled
                      placeholder="Email"
                      type="text"
                      autoComplete="email"
                      value={
                        dataToShow.data.genre ? dataToShow.data.genre : "**"
                      }
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CircleOutlined />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                    />
                    <TextField
                      sx={{
                        width: "80%",
                        backgroundColor: "#DEE3EB",
                      }}
                      margin="normal"
                      disabled
                      hiddenLabel
                      placeholder="Correo Electrónico"
                      value={dataToShow.data.email}
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <MailOutlineIcon />
                          </InputAdornment>
                        ),
                        style: {
                          fontSize: "1.2em", // Cambia el tamaño de la fuente
                          padding: "3%", // Aumenta el padding
                        },
                      }}
                      inputProps={{
                        style: {
                          height: "90%", // Aumenta la altura
                        },
                      }}
                      // onChange={(e) => setEmail(e.target.value)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </Card>
  );
}
