import { Box, Button, CardActions, Container, Divider, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getDoctorById, updateHourValue } from "../database/doctors";
import { formatValueClp } from "../utils/functions";

export default function ValueHour() {
  const [value, setValue] = useState("");
  const [lastValue, setLastValue] = useState("");
  const myId = useSelector((state) => state.auth.idUser);
  const myData = useSelector((state) => state.auth.userData);
  const [idDoctor, setIdDoctor] = useState("");

  useEffect(() => {
    const first = async () => {
      try {
        let res;
        if (myData.role === "doctor") {
          res = await getDoctorById(myId);
        } else {
          res = await getDoctorById(myData.idDoctor);

          setIdDoctor(res);
        }
        setLastValue(formatValueClp(res.valueHour) ?? "$0");
        setValue(formatValueClp(res.valueHour) ?? "$0");
      } catch (error) {
        console.error("Error en la primera llamada", error);
      }
    };
    first();
  }, []);

  const handleChange = (event) => {
    const newValue = formatValueClp(event.target.value);
    setValue(newValue);
  };

  const saveValue = async () => {
    const res = await updateHourValue(
      myData.role === "secretary" ? idDoctor.id : myId,
      value
    );
    if (res) {
      setLastValue(value);
      toast.success("Valor guardado");
    } else {
      toast.error("Error al guardar el valor");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p:0,
        height: "100%",
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 12,
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        <Box sx={{display: "flex", py: 2}}>
                <Typography variant="titleSmall">
                El valor actual es:{" "}
          {
            <span
              style={{
                fontSize: "1.5rem",
                color: "rgba(0, 102, 134, 1)",
              }}
            >
              {lastValue}
            </span>
          }
                </Typography>
              </Box>
    
        <TextField
          sx={{
            width: "100%",
            backgroundColor: "rgba(220, 227, 233, 1)",
            color: "black",
          }}
          type="text"
          value={value}
          onChange={handleChange}
          label="Valor de la reserva en peso chileno"
          variant="filled"
          placeholder="Ingrese el monto en peso chileno"
        />
      </Box>
      
      <ToastContainer />
      <Box
        sx={{
          zIndex: "200",
          width: "100%",
          backgroundColor: "white",
          position: "sticky",
          bottom: { xs: "0px", sm: "0px", md: "60px" },
        }}
      >
        <Divider/>
        <CardActions
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          py: 3,
          px: 2,
          width: "100%",
        }}
        >
         <Button
          variant="contained"
          disableElevation
          onClick={saveValue}
        >
          Guardar valor
        </Button>
        </CardActions>
      </Box>
    </Box>
  );
}
