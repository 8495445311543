import { Box, Button, CardActions, Container, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import CardSale from "../components/card_sale_component";
import { getSales } from "../database/sale";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
//REDUX IMPORTS
import { useSelector } from "react-redux";
import { useUpdateSales } from "../context/UpdateSales";

export default function Sales({
  handleOpenSalesDialog,
  setSalesData,
  setType,
  dataSaleDoctor,
}) {
  const { salesData } = useUpdateSales();
  const [open, setOpen] = useState(false);
  const [sales, setSales] = useState(salesData);
  const userData = useSelector((state) => state.auth.userData);
  const [myId, setMyId] = useState(
    userData.idDoctor ? userData.idDoctor : userData.id
  );

  const closeDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    setSalesData({ id: myId });

    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setSales(salesData);
      Swal.close();
    }
    getData();
  }, [myId, salesData]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 0,
        height: "100%",
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 12,
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        {sales.length > 0 ? (
          <Grid container>
            {sales.map((sale) => (
              <Grid item key={sale.url}>
                <CardSale
                  key={sale.createdAt}
                  setData={setSalesData}
                  data={sale}
                  openSaleDialog={handleOpenSalesDialog}
                  cerrar={closeDialog}
                  setType={setType}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          ""
        )}
      </Box>

      <Box
        sx={{
          zIndex: "200",
          width: "100%",
          backgroundColor: "white",
          position: "sticky",
          bottom: { xs: "0px", sm: "0px", md: "60px" },
        }}
      >
        <Divider />
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            py: 3,
            px: 2,
            width: "100%",
          }}
        >
          <Button
            onClick={() => {
              handleOpenSalesDialog();
            }}
            variant="contained"
          >
            Nueva oferta
          </Button>
        </CardActions>
      </Box>
    </Box>
  );
}
