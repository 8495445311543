// import { useState } from "react";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  TextField,
} from "@mui/material";
import { updateRequestStatusComplete } from "../database/request";
import { useState } from "react";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import CloseIcon from "@mui/icons-material/Close";

export default function DialogCompletedRequest({
  close,
  open,
  data,
  cerrar,
  handleOpened,
  loadData,
}) {
  // console.log("open",open)
  const [diagnostico, setDiagnostico] = useState("");
  const [tratamiento, setTratamiento] = useState("");
  // console.log("data", data);
  const handleChangeDiagnostico = (e) => {
    setDiagnostico(e.target.value);
    // console.log("diagnostico", diagnostico);
  };
  const handleChangeTratamiento = (e) => {
    setTratamiento(e.target.value);
    // console.log("tratamiento", tratamiento);
  };

  //   const [open, setOpen] = useState(false)
  const handleClose = () => {
    close(false);
  };
  const handleComplete = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const complete = {
      diagnostico: diagnostico,
      tratamiento: tratamiento,
    };
    // console.log("complete", complete);
    try {
      if (diagnostico.length > 0 && tratamiento.length > 0) {
        const res = await updateRequestStatusComplete(
          data.id,
          "completed",
          complete,
          data
        );
        // console.log("res", res);
        if (res) {
          Swal.close();
          loadData();
          handleClose();
          cerrar();
          Swal.fire({
            icon: "success",
            title: "Hecho",
            text: "Se ha concluido la reserva",
          });
        }
      } else {
        Swal.close();
        handleClose();
        cerrar();
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Debes completar los campos",
        }).then((result) => {
          if (result.isConfirmed) {
            handleOpened();
          } else {
            console.log("no confirmado");
          }
          // console.log("result", result);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container sx={{ width: "990vw" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#EEE8F4",
            borderRadius: "25px 25px 25px 25px",
          },
        }}
      >
        <DialogTitle
          sx={{
            // width: "400px",
            minWidth: "500px",

            fontSize: "20px",
          }}
        >
          Completando reserva
        </DialogTitle>
        <CloseIcon
          sx={{
            top: "2vh",
            right: "2vh",
            position: "absolute",
            color: "black",
            cursor: "pointer",
            ":hover": { backgroundColor: "rgba(0,0,0,0.1)" },
          }}
          onClick={handleClose}
        />
        <DialogContent>
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              alignItems: "center",
              margin: "0px 0px 0px",
              paddingLeft: "0px !important",
              paddingRight: "0px !important",
            }}
          >
            <TextField
              sx={{ width: "100%" }}
              id="outlined-multiline-static"
              label="Diagnostico *"
              placeholder="Describe el diagnostico"
              multiline
              rows={4}
              onChange={(e) => {
                handleChangeDiagnostico(e);
              }}
              // defaultValue="Defaultddd Value"
            />
            <br />
            <TextField
              sx={{ width: "100%" }}
              id="outlined-multiline-static"
              label="Tratamiento *"
              placeholder="Describe el tratamiento"
              multiline
              rows={4}
              onChange={(e) => {
                handleChangeTratamiento(e);
              }}
              // defaultValue="Defaultddd Value"
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={handleComplete}
            variant="contained"
            sx={{ borderRadius: "25px 25px 25px 25px" }}
          >
            Concluir
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
