import React, { useEffect } from "react";
import {
  Paper,
  CardContent,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const NavPaperVideocall = ({
  paperTitle,
  paperNav,
  paperContentHeader,
  paperContent,
  showList,
  setShowList,
  showUsersVideocall,
  setShowUsersVideocall,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumAndUp = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (isMediumAndUp) {
      setShowList(true);
    }
  }, [isMediumAndUp, setShowList]);

  return (
    <Paper
      elevation={0}
      sx={{
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        maxHeight: "100%",
        color: "#006686",
        borderRadius: "20px",
        flexGrow: 1,
        overflow: "hidden",
        scrollbarWidth: "none",
      }}
    >
      {showUsersVideocall && !isMobile && paperTitle && (
        <CardContent sx={{ maxWidth: "100vw", position: "sticky" }}>
          <IconButton
            onClick={() => setShowList(true)}
            sx={{ marginRight: 1, display: { md: "none" } }}
          >
            <ArrowBack />
          </IconButton>
          {paperTitle}
        </CardContent>
      )}

      <Divider variant="fullWidth" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "100%",
          overflow: "hidden",
          scrollbarWidth: "none",
        }}
      >
        {showUsersVideocall && (
          <Box
            sx={{
              width: { xs: "100%", md: "28%" },
              display: { xs: showList ? "block" : "none", md: "block" },
              maxHeight: "100%",
              overflow: "hidden",
              scrollbarWidth: "none",
            }}
          >
            {isMobile && paperTitle && (
              <CardContent sx={{ maxWidth: "100vw", position: "sticky" }}>
                {paperTitle}
              </CardContent>
            )}
            {isMobile && paperTitle && <Divider variant="fullWidth" />}

            <Box
              sx={{
                maxHeight: "100%",
                overflow: "auto",
                scrollbarWidth: "none",
              }}
            >
              {paperNav}
            </Box>
          </Box>
        )}

        <Box
          sx={{
            width: { xs: "100%", md: "100%" },
            display: { xs: showList ? "none" : "flex", md: "flex" },
            borderLeft: { md: "1px solid rgba(192, 199, 205, 1)" },

            flexDirection: "column",
            position: "relative",
            height: "100%",
          }}
        >
          {paperContentHeader && (
            <Box
              sx={{
                position: "sticky",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  px: { xs: 1, md: 3 },
                  py: 2,
                }}
              >
                {!showList && (
                  <IconButton
                    onClick={() => setShowUsersVideocall(true)}
                    sx={{ marginRight: 1 }}
                  >
                    <ArrowBack />
                  </IconButton>
                )}
                {paperContentHeader}
              </Box>
              <Divider />
            </Box>
          )}

          <Box
            sx={{
              height: "100%",
              p: 0,
              overflowY: "auto",
              scrollbarWidth: "thin",
              position: "relative",
            }}
          >
            {paperContent}
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default NavPaperVideocall;
