import {
  Button,
  Container,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { getDoctorById, updateSettingsPOS } from "../database/doctors";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { updateSettingsSII } from "../database/invoices";

export default function SettingsSII() {
  const [keySII, setKeySII] = useState("");
  const [tokenSII, setTokenSII] = useState("");
  const myId = useSelector((state) => state.auth.idUser);
  const myData = useSelector((state) => state.auth.userData);
  const [showSecretKey, setShowSecretKey] = useState(false);

  const toggleShowSecretKey = () => {
    setShowSecretKey(!showSecretKey);
  };

  useEffect(() => {
    const first = async () => {
      try {
        let res;
        if (myData.role === "doctor") {
          res = await getDoctorById(myId);
        } else {
          res = await getDoctorById(myData.idDoctor);
        }
        setKeySII(res.keySII ?? "0");
      } catch (error) {
        console.error("Error en la primera llamada", error);
      }
    };
    first();
  }, [myId, myData.role, myData.idDoctor]);

  const handleChangeSecretKeySII = (event) => {
    const newValue = event.target.value;
    setKeySII(newValue);
  };

  const handleChangeTokenSII = (event) => {
    const newValue = event.target.value;
    setTokenSII(newValue);
  };

  const saveValue = async () => {
    const res = await updateSettingsSII(
      myData.role === "doctor" ? myId : myData.idDoctor,
      keySII,
      tokenSII
    );
    if (res) {
      toast.success("Valor guardado");
    } else {
      toast.error("Error al guardar el valor");
    }
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: "1.2em",
          marginLeft: "5%",
          marginTop: "3%",
        }}
      >
        Clave secreta SII
      </Typography>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: "1.0em",
          marginLeft: "5%",
          marginTop: "1%",
        }}
      >
        Esta será utilizada para la emisión y obtención de boletas de honorarios
        electrónicas.{" "}
      </Typography>

      <Container
        sx={{
          marginTop: "5px",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          marginBottom: "5px",
          alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <TextField
          sx={{
            marginTop: "1vh",
            width: "25vw",
            backgroundColor: "rgba(220, 227, 233, 1)",
            color: "black",
          }}
          type={showSecretKey ? "text" : "password"} // Cambia el tipo de entrada entre 'text' y 'password'
          value={keySII}
          onChange={handleChangeSecretKeySII}
          label="Clave secreta SII"
          variant="filled"
          placeholder="Clave secreta SII"
          InputProps={{
            endAdornment: (
              <IconButton onClick={toggleShowSecretKey} edge="end">
                {showSecretKey ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
        {/* <TextField
                    sx={{ marginTop: '1vh', width: '25vw', backgroundColor: 'rgba(220, 227, 233, 1)', color: 'black' }}
                    type={'text'} // Cambia el tipo de entrada entre 'text' y 'password'
                    value={tokenSII}
                    onChange={handleChangeTokenSII}
                    label="Token SII"
                    variant="filled"
                    placeholder="Token SII"
                /> */}
      </Container>
      <div
        style={{
          height: "100%",
        }}
      />

      <div
        style={{
          width: "100%",
          height: "10%",
          display: "flex",
          flexDirection: "row-reverse",
          alignItems: "center",
          borderTop: "1px solid rgba(0,0,0,0.3)",
        }}
      >
        <Button
          onClick={saveValue}
          style={{
            marginTop: "15px",
            marginBottom: "15px",
            width: "13%",
            backgroundColor: "#006686",
            color: "#77F8E0",
            borderRadius: "25px",
            padding: "10px",
            marginRight: "10px",
            textDecoration: "none",
            textTransform: "none",
          }}
        >
          Guardar
        </Button>
      </div>
      <ToastContainer />
    </>
  );
}
