import { format } from "date-fns";
import { es } from "date-fns/locale";

export const capitalize = (text) => {
  if (text === undefined) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const capitalizeAll = (text) => {
  if (text === undefined) return text;
  return text
    .split(" ")
    .map((word) => capitalize(word))
    .join(" ");
};

export const getActualDay = () => {
  const dateNow = new Date();
  const dayName = format(dateNow, "EEEE", { locale: es });
  const dayNumber = format(dateNow, "d");
  const formattedDate = format(dateNow, "yyyy-MM-dd");
  return [dayName, dayNumber, formattedDate];
};

export const formatValueClp = (value) => {
  if (value === undefined) return "$0";
  const newValue = value.replace(/[^\d]/g, "");
  let res = new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(newValue);
  return res;
};
export const limpiarRut = (rut) => {
  var rutLimpio = rut.replace(/\./g, "").replace(/-/g, "");
  var rutSinDigitoVerificador = rutLimpio.slice(0, -1);
  return rutSinDigitoVerificador + "-" + rutLimpio.slice(-1);

  // console.log(limpiarRut(rut1)); // Salida: "11111111-1"
  // console.log(limpiarRut(rut2)); // Salida: "1111111-1"
};
