import React, { useEffect, useState } from "react";
import startInactivityTimer from "../utils/inactivity_logout";
import { useNavigate } from "react-router-dom";
import DrawerAdminComponent from "../components/drawer_admin";
import TopBarAdmin from "../components/top_bar_admin";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { changeStatusDoctor } from "../database/doctors";
import {
  ArrowBack,
  ArrowRight,
  Hail,
  HomeOutlined,
  MoreVert,
  VideocamOutlined,
} from "@mui/icons-material";
import imageUnselected from "../assets/request_select.svg";
import { capitalize, capitalizeAll } from "../utils/functions";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import AdminPaysComponent from "../components/admin_pays_component";
import AdminViewDataComponent from "../components/admin_view_data_component";
import AdminViewBankData from "../components/admin_view_bank_data_component";
import { getAllClinic } from "../database/clinic";
import { toast } from "react-toastify";

export default function CenterMedicalAdmin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.userData);
  const [doctors, setDoctors] = useState([]);
  const [doctorSelected, setDoctorSelected] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Activos");
  const [selectedOption, setSelectedOption] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const reloadData = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const doctors = await getAllClinic(
      selectedTab === "Activos"
        ? "enabled"
        : selectedTab === "Desactivados"
        ? "disabled"
        : "rejected"
    );
    setDoctors(doctors);
    Swal.close();
  };

  useEffect(() => {
    async function getDoctors() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      const doctors = await getAllClinic(
        selectedTab === "Activos"
          ? "enabled"
          : selectedTab === "Desactivados"
          ? "disabled"
          : "rejected"
      );
      setDoctors(doctors);
      Swal.close();
    }
    getDoctors();
  }, []);

  useEffect(() => {
    const cleanup = startInactivityTimer({
      navigate: navigate,
      dispatch: dispatch,
    });
    return cleanup;
  }, [navigate]);

  const handleChangeStatusDoctor = async () => {
    const response = await changeStatusDoctor(
      doctorSelected.id,
      selectedTab === "Desactivados" ? "enabled" : "disabled"
    );

    if (response) {
      const doctors = await getAllClinic();
      Swal.fire({
        icon: "success",
        title: "¡Listo!",
        text: `El estado del centro médico ha sido cambiado exitosamente`,
      });
      setDoctors(doctors);
      setDoctorSelected(null);
    } else {
      console.error("Error al cambiar el estado del doctor");
    }
  };

  async function loadData(status) {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    const doctors = await getAllClinic();
    const filteredDoctors = doctors.filter((doctor) => {
      return doctor.status === status; // Cambia a "Desactivados" si deseas filtrar por doctores desactivados
    });

    setDoctors(filteredDoctors);
    Swal.close();
  }

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      {/* <DrawerAdminComponent route={"MedicalCenterAdmin"} /> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          backgroundColor: "#FFFFFF",
          borderRadius: "25px",
        }}
      >
        <div
          style={{
            height: "85vh",
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <List
            sx={{
              padding: "0px",
              margin: "0px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              maxWidth: "16vw",
              flex: 1,
              borderRight: "1px solid rgba(192, 199, 205, 1)",
            }}
          >
            <div
              style={{
                width: "100%",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
                // borderBottom: '1px solid rgba(192, 199, 205, 1)'
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={(event, newValue) => {
                  setSelectedTab(newValue);
                  loadData(newValue);
                  setDoctorSelected(null);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flex: 1,
                  width: "100%",
                  borderBottom: "1px solid rgba(192, 199, 205, 1)",
                }}
              >
                <Tab
                  value={"enabled"}
                  sx={{
                    flex: 1,
                  }}
                  label="Activos"
                />
                <Divider orientation="vertical" />
                <Tab
                  sx={{
                    flex: 1,
                  }}
                  value={"disabled"}
                  label="Desactivados"
                />
              </Tabs>
            </div>
            {doctors.length === 0 ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flex={1}
              >
                <Typography
                  variant="h6"
                  sx={{
                    textAlign: "center",
                    marginTop: "20px",
                    color: "#006686",
                    fontFamily: "Nunito",
                  }}
                >
                  No hay clientes {selectedTab.toLocaleLowerCase()} registrados
                </Typography>
              </Box>
            ) : (
              doctors.map((doctor) => (
                <ListItem
                  key={doctor.id}
                  sx={{
                    height: "10%",
                    ":hover": { backgroundColor: "rgba(0, 102, 134, 0.6)" },
                    backgroundColor:
                      doctorSelected?.id === doctor.id
                        ? "rgba(0, 102, 134, 0.9)"
                        : "white",
                  }}
                  onClick={() => {
                    if (doctor.firstTime !== true) {
                      setDoctorSelected(doctor);
                      setSelectedOption(null);
                    } else {
                      Swal.fire({
                        icon: "info",
                        title: "¡Atención!",
                        text: "El doctor no ha completado su registro, por favor, pídale que complete su información para poder verla.",
                      });
                    }
                  }}
                  button
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        border: "1px solid white",
                        backgroundColor:
                          doctorSelected?.id === doctor.id
                            ? "rgba(0, 102, 134, 0.1)"
                            : "rgba(0, 102, 134, 0.3)",
                      }}
                      src={doctor.imageProfile}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        fontSize: "1rem",
                        color:
                          doctorSelected?.id === doctor.id
                            ? "#77F8E0"
                            : "black",
                      },
                    }}
                    secondaryTypographyProps={{
                      style: {
                        fontSize: "0.8rem",
                        color:
                          doctorSelected?.id === doctor.id ? "#77F8E0" : "grey",
                      },
                    }}
                    primary={capitalizeAll(`${doctor.name}`)}
                    secondary={doctor.email}
                  />
                  <ListItemIcon>
                    <ArrowRight
                      sx={{
                        fontSize: "25px",
                        ml: 3,
                        color:
                          doctorSelected?.id === doctor.id
                            ? "#77F8E0"
                            : "black",
                      }}
                    />
                  </ListItemIcon>
                </ListItem>
              ))
            )}
          </List>
          {doctorSelected && doctorSelected.firstTime !== true ? (
            <Box
              width="100%"
              display="flex"
              flexDirection="column"
              justifyContent="start"
              alignItems="start"
              flex={1}
            >
              <Box
                width="100%"
                height="8%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  flex={1}
                  display="flex"
                  flexDirection="row"
                  sx={
                    selectedOption !== null
                      ? {
                          borderBottom: "1px solid rgba(192, 199, 205, 1)",
                        }
                      : {}
                  }
                  justifyContent={
                    selectedOption !== null ? "space-between" : "end"
                  }
                  alignItems="center"
                >
                  {selectedOption !== null && (
                    <Container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "start",
                      }}
                    >
                      <ArrowBack
                        sx={{
                          color: "#006686",
                          marginRight: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedOption(null)}
                      />
                      <Typography
                        sx={{
                          color: "#006686",
                          fontFamily: "Nunito",
                          fontSize: "1.5em",
                        }}
                      >
                        {capitalize(selectedOption)}
                      </Typography>
                    </Container>
                  )}
                  <IconButton
                    sx={{ color: "#006686", marginRight: "10px" }}
                    onClick={handleClick}
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        handleChangeStatusDoctor();
                        handleClose();
                      }}
                    >
                      {selectedTab === "Desactivados"
                        ? "Habilitar Centro Medico"
                        : "Deshabilitar Centro Medico"}
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>

              {selectedOption === "Datos personales" ? (
                <AdminViewDataComponent doctorSelected={doctorSelected} />
              ) : selectedOption === "Datos bancarios" ? (
                <AdminViewBankData doctorSelected={doctorSelected} />
              ) : selectedOption === "Configuración de pagos" ? (
                <AdminPaysComponent
                  doctorSelected={doctorSelected}
                  reload={reloadData}
                />
              ) : (
                <Box
                  width={"100%"}
                  display="flex"
                  flex={1}
                  flexDirection="column"
                  justifyContent="start"
                  alignItems="center"
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  <Avatar
                    sx={{
                      border: "1px solid white",
                      width: "100px",
                      height: "100px",
                      backgroundColor: "rgba(0, 102, 134, 0.3)",
                      marginBottom: "2px",
                    }}
                    src={doctorSelected.imageProfile}
                  />
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "Nunito",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    {capitalizeAll(doctorSelected.name)}{" "}
                    {capitalizeAll(doctorSelected.lastName)}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "black",
                      fontFamily: "Nunito",
                      paddingLeft: "10px",
                      fontSize: "1.1rem",
                    }}
                  >
                    {capitalize(doctorSelected.mainSpecialty)}
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      color: "grey",
                      fontFamily: "Nunito",
                      paddingLeft: "10px",
                      fontSize: "1rem",
                    }}
                  >
                    #{capitalize(doctorSelected.registerNumber)}
                  </Typography>
                  <Container
                    sx={{
                      backgroundColor: "#F0F4F8",
                      borderRadius: "20px",
                      marginTop: "10px",
                      padding: "10px",
                      width: "90%",
                      // height: '10%',
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#006686",
                        fontFamily: "Nunito",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      Valor consulta
                    </Typography>
                    <Typography
                      sx={{
                        color: "#006686",
                        fontFamily: "Nunito",
                        fontSize: "2rem",
                        fontWeight: "bold",
                        marginBottom: "10px",
                      }}
                    >
                      {doctorSelected.valueHour
                        ? `${doctorSelected.valueHour}`
                        : "No especificado"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "#006686",
                        fontFamily: "Nunito",
                        fontSize: "1rem",
                      }}
                    >
                      Tipos de consultas disponibles
                    </Typography>
                    <Container
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        marginTop: "10px",
                      }}
                    >
                      <Container
                        sx={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <VideocamOutlined
                          sx={{
                            color: doctorSelected.queryTypes.videollamada
                              ? "#006686"
                              : "#AAABAD",
                            fontSize: "2rem",
                          }}
                        />
                        <Typography
                          sx={{
                            color: doctorSelected.queryTypes.videollamada
                              ? "#006686"
                              : "#AAABAD",
                            fontFamily: "Nunito",
                            fontSize: "1rem",
                          }}
                        >
                          Telemedicina
                        </Typography>
                      </Container>
                      <Divider orientation="vertical" />
                      <Container
                        sx={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Hail
                          sx={{
                            color: doctorSelected.queryTypes.presencial
                              ? "#006686"
                              : "#AAABAD",
                            fontSize: "2rem",
                          }}
                        />
                        <Typography
                          sx={{
                            color: doctorSelected.queryTypes.presencial
                              ? "#006686"
                              : "#AAABAD",
                            fontFamily: "Nunito",
                            fontSize: "1rem",
                          }}
                        >
                          Presencial
                        </Typography>
                      </Container>
                      <Divider orientation="vertical" />
                      <Container
                        sx={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <HomeOutlined
                          sx={{
                            color: doctorSelected.queryTypes.domicilio
                              ? "#006686"
                              : "#AAABAD",
                            fontSize: "2rem",
                          }}
                        />
                        <Typography
                          sx={{
                            color: doctorSelected.queryTypes.domicilio
                              ? "#006686"
                              : "#AAABAD",
                            fontFamily: "Nunito",
                            fontSize: "1rem",
                          }}
                        >
                          A domicilio
                        </Typography>
                      </Container>
                    </Container>
                  </Container>
                  <Container
                    sx={{
                      width: "95%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#006686",
                        fontFamily: "Nunito",
                        fontSize: "1rem",
                        marginTop: "10px",
                      }}
                    >
                      Datos del doctor
                    </Typography>
                    <br />
                    <ListItem
                      button
                      onClick={() => {
                        setSelectedOption("Datos personales");
                      }}
                    >
                      <ListItemText
                        primary="Datos personales"
                        primaryTypographyProps={{
                          color: "grey",
                          fontWeight: 400,
                          fontFamily: "Nunito",
                        }}
                      />
                      <ListItemIcon>
                        <ArrowRight sx={{ color: "black" }} />
                      </ListItemIcon>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        setSelectedOption("Datos bancarios");
                      }}
                    >
                      <ListItemText
                        primary="Datos bancarios"
                        primaryTypographyProps={{
                          color: "grey",
                          fontWeight: 400,
                          fontFamily: "Nunito",
                        }}
                      />
                      <ListItemIcon>
                        <ArrowRight sx={{ color: "black" }} />
                      </ListItemIcon>
                    </ListItem>
                    <ListItem
                      button
                      onClick={() => {
                        setSelectedOption("Configuración de pagos");
                      }}
                    >
                      <ListItemText
                        primary="Configuración de pagos"
                        primaryTypographyProps={{
                          color: "grey",
                          fontWeight: 400,
                          fontFamily: "Nunito",
                        }}
                      />
                      <ListItemIcon>
                        <ArrowRight sx={{ color: "black" }} />
                      </ListItemIcon>
                    </ListItem>
                  </Container>
                </Box>
              )}
            </Box>
          ) : (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              flex={1}
            >
              <img
                src={imageUnselected}
                alt="selectimage"
                style={{ alignSelf: "center" }}
              />
              <Typography
                variant="h5"
                sx={{
                  textAlign: "center",
                  marginTop: "20px",
                  fontWeight: "bold",
                  color: "black",
                  fontFamily: "Nunito",
                }}
              >
                Selecciona un cliente para ver su información
              </Typography>
            </Box>
          )}
        </div>
      </div>
    </Box>
  );
}
