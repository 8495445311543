import { db } from "./config";
import {
  collection,
  addDoc,
  doc,
  serverTimestamp,
  getDocs,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import "firebase/compat/storage";

export async function getMedicalRecordsByDoctor(idDoctor) {
  try {
    const queryData = query(
      collection(db, "medical_records"),
      where("idDoctor", "==", idDoctor)
    );
    const types = await getDocs(queryData);
    return types.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    return false;
  }
}

export async function createMedicalRecord(data) {
  try {
    const notificationsCollectionRef = collection(db, "medical_records");
    const ress = await addDoc(notificationsCollectionRef, {
      ...data,
      createdAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function updateMedicalRecord(data, userId) {
  // console.log(data);
  try {
    const docRef = doc(collection(db, "medical_records"), userId);
    const res = await updateDoc(docRef, { ...data });
    return res;
  } catch (error) {
    return false;
  }
}
