import {
  collection,
  doc,
  serverTimestamp,
  updateDoc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "./config";

export async function getFolioById(folioId) {
  try {
    const docRef = doc(collection(db, "documents"), folioId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export async function createFolio(userId) {
  try {
    const docRef = doc(collection(db, "documents"), userId);
    const savedData = await setDoc(docRef, {
      atentionFolio: 0,
      prescriptionFolio: 0,
      createdAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
export async function updateFolio(folioId, newData) {
  try {
    const docRef = doc(collection(db, "documents"), folioId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      // Si el documento existe, actualiza sus datos con newData
      await updateDoc(docRef, newData);
      return true; // Devuelve true para indicar que la actualización fue exitosa
    } else {
      // Si el documento no existe, puedes manejarlo según tus necesidades, por ejemplo, lanzar un error
      throw new Error("Nan");
    }
  } catch (error) {
    console.log(error);
    return false; // En caso de error, devuelve false o maneja el error según tus necesidades
  }
}
