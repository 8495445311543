// UpdateSales.js
import React, { createContext, useContext, useState, useEffect } from "react";

const OpenChangeImage = createContext();

export const OpenChangeImageProvider = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [type, setType] = useState("");
  const [image, setImage] = useState("");

  const open = () => {
    setDialogOpen(true);
  };

  const close = () => {
    setDialogOpen(false);
  };

  return (
    <OpenChangeImage.Provider
      value={{
        dialogOpen,
        image,
        setImage,
        open,
        close,
        setType,
        type,
      }}
    >
      {children}
    </OpenChangeImage.Provider>
  );
};

export const useOpenChangeImage = () => useContext(OpenChangeImage);
