import {
  Button,
  Container,
  Dialog,
  DialogTitle,
  MenuItem,
  Select,
  Typography,
  capitalize,
} from "@mui/material";
import Swal from "sweetalert2";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import axios from "axios";
import {
  AccountBalanceOutlined,
  AttachMoneyOutlined,
  MailOutline,
  Payment,
  Payments,
  PersonOutline,
} from "@mui/icons-material";
import { payRequest, payRequestPOS } from "../database/request";
import loadingSVG from "../assets/logo_Loading.gif";

export default function DialogPay({ open, close, data, closeMain, loadData }) {
  const name =
    capitalize(data.client.name) + " " + capitalize(data.client.lastName);
  const [statusPay, setStatusPay] = useState("Pending");
  const [payType, setPayType] = useState(data.client.payment);
  const [paymentMetod, setPaymentMetod] = useState("");
  const [numberDocument, setNumberDocument] = useState("");
  const [payMethodFinal, setPayMethodFinal] = useState("Efectivo");
  const [textSwal, setTextSwal] = useState("Iniciando pago...");

  const payMethods = [
    {
      name: "Efectivo",
      value: "Efectivo",
    },
    {
      name: "Tarjeta",
      value: "Tarjeta",
    },
  ];

  const handleClose = () => {
    close(false);
  };

  const handlePaymentMetod = (e) => {
    setPaymentMetod(e.target.value);
  };

  const getPay = async (requestId) => {
    const apiKey =
      "LsWOpRYKv3IAwlElSxdftpdOBLhJ7FIq3gnKIDCVsirZvwbii0wuliUQXSn7Pjj2mx1zB0SgfMemAf7CLhS38nRxSxUAnqlck9Z0lPNiRbbBbRGH8kEIxF0BtASvWj";


    try {
      const response = await axios.get(
        `https://us-central1-dfinder-5f8ae.cloudfunctions.net/getDataPay?paymentId=${requestId}`,
        {
          method: "GET",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": apiKey,
          },
        }
      );

      setStatusPay(response.data.paymentRequest.status);
      return response.data;
    } catch (error) {
      console.error("Error al obtener el estado del pago:", error);
      // Manejar el error aquí
      return "error";
    }
  };

  const payPos = async () => {
    // const apiKey = "LsWOpRYKv3IAwlElSxdftpdOBLhJ7FIq3gnKIDCVsirZvwbii0wuliUQXSn7Pjj2mx1zB0SgfMemAf7CLhS38nRxSxUAnqlck9Z0lPNiRbbBbRGH8kEIxF0BtASvWj";
    const swalInstance = Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: `${textSwal}`,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    try {
     
      const response = await axios.post(
        `https://us-central1-dfinder-5f8ae.cloudfunctions.net/pay?apiKey=${data.doctor.secretKey}`,
        {
          data: {
            device: data.doctor.codePos,
            amount: parseInt(
              data.doctor.valueHour.replace("$", "").replace(".", "")
            ),
            dteType: 48,
            extraData: {
              taxIdnValidation: data.doctor.rutPos,
              sourceName: "GetMED",
              sourceVersion: "2023.01.20-6",
              customFields: [
                {
                  name: "idXX",
                  value: "245023-2342-2",
                  print: true,
                },
              ],
            },
          },
          apiKey: data.doctor.secretKeyPOS,
        },
        {
          method: "POST",
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": data.doctor.secretKeyPOS,
          },
        }
      );


      const checkPaymentStatus = async () => {
        const responsePay = await getPay(response.data.paymentRequestId);
        let statusPayGet = responsePay.paymentRequest.status;
        setStatusPay(statusPayGet);
        if (statusPayGet === "Pending") {
          setTimeout(checkPaymentStatus, 5000); // Realiza una nueva llamada cada 5 segundos
        } else if (statusPayGet === "Sent") {
          setTimeout(checkPaymentStatus, 5000); // Realiza una nueva llamada cada 5 segundos
          swalInstance.update({
            title: "Esperando el pago",
          });
        } else if (statusPayGet === "Processing") {
          setTimeout(checkPaymentStatus, 5000); // Realiza una nueva llamada cada 5 segundos
          swalInstance.update({
            title: "Procesando pago",
          });
        } else if (statusPayGet === "Completed") {
          const res = await payRequestPOS(data.id, data, responsePay);
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Pago realizado",
            text: "El pago se ha realizado con éxito",
          }).then((value) => {
            closeMain();
            loadData();
          });
          handleClose();
        } else if (statusPayGet === "Canceled") {
          Swal.close();
          Swal.fire({
            icon: "error",
            title: "Pago cancelado",
            text: "El pago se ha cancelado",
          });
        } else if (statusPayGet === "Failed") {
          Swal.fire({
            icon: "error",
            title: "Pago fallido",
            text: "El pago ha fallado. Inténtalo de nuevo.",
          });
        }
      };

      checkPaymentStatus(); // Comienza el proceso de verificación del estado del pago
    } catch (error) {
      console.error("Error al realizar el pago:", error);
      // Manejar el error aquí
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "#EEE8F4",
            borderRadius: "25px 25px 25px 25px",
            width: "50vw",
            heigh: "auto",
          },
        }}
      >
        <DialogTitle
          sx={{
            color: "black",
            fontSize: "20px",
            fontWeight: 300,
            display: "flex",
            flexDirection: "row",
          }}
        >
          Pagando reserva de {name}
        </DialogTitle>
        <Container sx={{ marginTop: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                borderBottom: "1px solid #ccc",
                paddingBottom: "5px",
                marginBottom: "10px",
              }}
            >
              <PersonOutline
                sx={{
                  fontSize: "25px",
                  marginRight: "5px",
                }}
              />
              <div>
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "12px",
                  }}
                >
                  Nombre
                </Typography>
                <Typography>{capitalize(name)}</Typography>
              </div>
            </div>
            {/* <TextField
              label="Nombre"
              value={name}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlineOutlinedIcon />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="standard"
            />{" "}
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,0.4)", // Estilos para la línea horizontal
                margin: "10px 0", // Ajusta el margen según sea necesario
              }}
            /> */}
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                borderBottom: "1px solid #ccc",
                paddingBottom: "5px",
                marginBottom: "10px",
              }}
            >
              <MailOutline
                sx={{
                  fontSize: "25px",
                  marginRight: "5px",
                }}
              />
              <div>
                <Typography
                  sx={{
                    color: "grey",
                    fontSize: "12px",
                  }}
                >
                  Email
                </Typography>
                <Typography>
                  {capitalize(data.client.email ? data.client.email : "**")}
                </Typography>
              </div>
            </div>
            {/* <TextField
              label="Correo"
              value={capitalize(data.client.email ? data.client.email : "**")}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailOutlinedIcon />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="standard"
            />
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,0.4)", // Estilos para la línea horizontal
                margin: "10px 0", // Ajusta el margen según sea necesario
              }}
            /> */}
            {data.request.payData.typePay === "Particular" && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <AttachMoneyOutlined
                    sx={{
                      fontSize: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <div>
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      Monto
                    </Typography>
                    <Typography>
                      {data.request.payData.amount
                        ? data.request.payData.amount
                        : "N/A"}
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <Payments
                    sx={{
                      fontSize: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <Select
                    sx={{
                      width: "100%",
                      height: "52px",
                      border: "none", // Quita el borde
                      "& .MuiOutlinedInput-root": {
                        // Estilos para el contenedor del input
                        border: "none", // Quita el borde
                      },
                      "& .MuiOutlinedInput-input": {
                        // Estilos para el input
                        padding: "16px 14px", // Ajusta el padding
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        // Estilos para el borde de enfoque
                        border: "none", // Quita el borde
                      },
                      "& .MuiSelect-select": {
                        // Estilos para el elemento de selección
                        paddingLeft: "0px", // Ajusta el padding
                      },
                    }}
                    defaultValue={0}
                    value={payMethodFinal}
                    onChange={(e) => {
                      setPayMethodFinal(e.target.value);
                    }}
                  >
                    {payMethods.map((e, i) => (
                      <MenuItem value={e.value} key={i}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </>
            )}
            {}
            {/* <TextField
              label="Monto"
              value={capitalize(
                data.request.payData.amount ? data.request.payData.amount : "**"
              )}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:after": {
                  borderBottom: "none",
                },
                "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                  borderBottom: "none",
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoneyIcon />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              variant="standard"
            />
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,0.4)", // Estilos para la línea horizontal
                margin: "10px 0", // Ajusta el margen según sea necesario
              }}
            /> */}
            {/* <Select
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceOutlined />
                </InputAdornment>
              }
              sx={{
                my: 1
              }}
              variant="standard"
              value={paymentMetod}
              placeholder="Tipo de pago"
              label="Tipo de pago"
              onChange={(e) => {
                console.log("ee", e.target.value);
                handlePaymentMetod(e)
              }}
            >
              <MenuItem value={"Efectivo"}>Efectivo</MenuItem>
              <MenuItem value={"Debito"}>Debito</MenuItem>
              <MenuItem value={"Credito"}>Credito</MenuItem>
            </Select> */}
            {/* <Select
              sx={{
                my: 2,
              }}
              value={payType}
              label="Isapres"
              disabled
              placeholder="Isapres"
              variant="standard"
              onChange={(e) => {
                console.log("ee", e.target.value);
                console.log("payType", payType);

                setPayType(e.target.value);
              }}
              startAdornment={
                <InputAdornment position="start">
                  <LocalHospitalOutlinedIcon />
                </InputAdornment>
              }
            >
              <MenuItem value={"FONASA"}>Fonasa</MenuItem>
              <MenuItem value={"Isapre"}>Isapre</MenuItem>
              <MenuItem value={"Particular"}>Particular</MenuItem>
            </Select> */}
            {data.request.payData.typePay === "Fonasa" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "5px",
                  marginBottom: "10px",
                  maxHeight: "50px",
                }}
              >
                <TextField
                  value={numberDocument}
                  onChange={(e) => setNumberDocument(e.target.value)}
                  fullWidth
                  // required
                  label="Código de documento Fonasa"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndOutlinedIcon />
                      </InputAdornment>
                    ),
                    // readOnly: true,
                  }}
                  // value={description}
                  type="text"
                  // multiline
                  // rows={4}
                  variant="standard"
                  inputProps={{ maxLength: 20 }}
                />
              </div>
            )}
            {data.request.payData.typePay === "Isapre" && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "5px",
                    marginBottom: "10px",
                  }}
                >
                  <PersonOutline
                    sx={{
                      fontSize: "25px",
                      marginRight: "5px",
                    }}
                  />
                  <div>
                    <Typography
                      sx={{
                        color: "grey",
                        fontSize: "12px",
                      }}
                    >
                      Isapre
                    </Typography>
                    <Typography>
                      {data.request.payData.isapre
                        ? capitalize(data.request.payData.isapre)
                        : ""}
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "5px",
                    marginBottom: "10px",
                    maxHeight: "50px",
                  }}
                >
                  <TextField
                    fullWidth
                    value={numberDocument}
                    onChange={(e) => {
                      setNumberDocument(e.target.value);
                    }}
                    // required
                    label="Código de documento Isapre"
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottom: "none",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "none",
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "none",
                      },
                      // mt: 2,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AssignmentIndOutlinedIcon />
                        </InputAdornment>
                      ),
                    }}
                    type="text"
                    variant="standard"
                    inputProps={{ maxLength: 20 }}
                  />
                </div>
              </>
            )}

            <Container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                marginY: "2vh",
              }}
            >
              <Button
                sx={{ fontSize: "15px", textTransform: "none" }}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancelar
              </Button>
              <Button
                sx={{ fontSize: "15px", textTransform: "none" }}
                onClick={async () => {
                  if (
                    (numberDocument === "" &&
                      data.request.payData.typePay === "Fonasa") ||
                    (data.request.payData.typePay === "Isapre" &&
                      numberDocument === "")
                  ) {
                    Swal.fire({
                      icon: "error",
                      title: "Error",
                      text: "Debe ingresar un código de documento",
                    });
                  } else {
                    if (payMethodFinal === "Efectivo") {
                      Swal.fire({
                        imageUrl: loadingSVG,
                        imageWidth: 300,
                        imageHeight: 300,
                        title: "Cargando...",
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                      });
                      const res = await payRequest(
                        data.id,
                        data,
                        numberDocument,
                        payMethodFinal
                      );
                      Swal.close();
                      if (res) {
                        Swal.fire({
                          icon: "success",
                          title: "Pago realizado",
                          text: "El pago se ha realizado con éxito",
                        }).then((value) => {
                          closeMain();
                          loadData();
                        });
                        handleClose();
                      } else {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: "No se ha podido realizar el pago",
                        });
                      }
                    } else {
                      await payPos();
                    }
                  }
                }}
              >
                Pagar
              </Button>
            </Container>
          </div>
        </Container>
      </Dialog>
      {/* <DialogCompletedRequest loadData={loadData} open={openComplete} close={setOpenComplete} data={data} cerrar={close} handleOpened={handleOpened} /> */}
    </>
  );
}
