import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

const NewHourModal = ({ open, onClose, date, hours, setHours }) => {
  const [time, setTime] = useState("");

  const handleClose = () => {
    onClose();
  };

  const handleAddAppointment = () => {
    console.log("Fecha:", date);
    console.log("Hora:", time ? time : "No se ha seleccionado hora");
    console.log("Horas:", hours);
    let newHour = {
      date: date,
      time: time,
      status: "free",
    };
    if (time) {
      const isHourAlreadyAdded = hours.some(
        (hour) => hour.date === newHour.date && hour.time === newHour.time
      );

      if (isHourAlreadyAdded) {
        toast.error("La hora ya está registrada");
      } else {
        // hours.sort((a, b) => a.time - b.time)
        const sortedHours = [...hours, newHour].sort((a, b) => {
          const timeA = new Date(`2000-01-01 ${a.time}`);
          const timeB = new Date(`2000-01-01 ${b.time}`);
          return timeA - timeB;
        });
        setHours(sortedHours);
        handleClose();
      }
    } else {
      toast.warning("Selecciona una hora");
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        PaperProps={{
          style: {
            bgcolor: "background.paper",
            borderRadius: "28px",
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Agregar hora</DialogTitle>
        <DialogContent>
          <Box>
            <TextField
              id="date"
              label="Fecha"
              type="date"
              value={date}
              disabled
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal"
            />
            <TextField
              id="time"
              label="Hora"
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              margin="normal"
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  handleAddAppointment();
                }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: 2,
            py: 2,
          }}
        >
          <Button variant="text" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            disableElevation
            variant="contained"
            onClick={handleAddAppointment}
          >
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default NewHourModal;
