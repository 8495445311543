import React from "react";
import {
  Card,
  CardActions,
  CardHeader,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Avatar,
  Button,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { deleteSecretary } from "../database/secretaries";
import Swal from "sweetalert2";
import { useUpdateSecretaries } from "../context/UpdateSrecretaries";

export default function CardSecretary({
  data,
  setType,
  handleOpenDialog,
  setSecretarieData,
}) {
  console.log("data", data);
  // console.log("loadData", loadData);
  const { updateSecretariesAsync } = useUpdateSecretaries();

  let selectedSecretarieData = data;
  // console.log("secretariesData", selectedSecretarieData);
  async function handleDelete() {
    Swal.fire({
      title: "¿Estás seguro de que quieres eliminar tu secretaria?",
      text: "No podrás revertir esta acción",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí, borrar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire(
          "Borrado",
          "La secretaria ha sido eliminada exitosamente",
          "success"
        );
        await deleteSecretary(selectedSecretarieData.id);
        updateSecretariesAsync();
      }
    });
  }
  async function handleEdit() {
    setType("edit");
    setSecretarieData(data);
    handleOpenDialog();
  }

  return (
    <>
      <Card
        elevation={5}
        sx={{
          minWidth: 300,
          maxWidth: 300,
          margin: 3,
          backgroundColor: "transparent",
          borderRadius: "10px",
        }}
      >
        <CardHeader
          avatar={
            <Avatar sx={{}} aria-label="recipe">
         {selectedSecretarieData.name.charAt(0)}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title="Secretaria"
        />

        <CardMedia
          component="img"
          height="200"
          image={
            selectedSecretarieData.image
              ? selectedSecretarieData.image
              : "https://www.thermaxglobal.com/wp-content/uploads/2020/05/image-not-found.jpg"
          }
          alt="CardImg"
        />

        <CardContent sx={{ width: "100%", height: "10vh" }}>
          <Typography>
            {selectedSecretarieData.name +
              " " +
              selectedSecretarieData.lastName}
          </Typography>
          <Typography variant="body2" color={"rgba(0,0,0,0.5)"}>
            Ultima edición: 00/00/0000
          </Typography>
          <br />
          <Typography variant="body2" color={"rgba(0,0,0,0.5)"}>
            Numero: {selectedSecretarieData.phone}
          </Typography>

          <Typography variant="body2" color={"rgba(0,0,0,0.5)"}>
            Email: {selectedSecretarieData.email}
          </Typography>
        </CardContent>
        <br />

        <CardActions
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            alignItems: "center",
          }}
        >
          {" "}
          <Button
            variant="contained"
            onClick={() => handleEdit()}
            sx={{
              backgroundColor: "#006686",
              color: "#77F8E0",
              // width: '100%',
              fontFamily: "Nunito",
              fontSize: "13px",
              borderRadius: "25px",

              textTransform: "none", // Evita la transformación a mayúsculas
              marginLeft: "10px",
            }}
          >
            Editar
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "#006686",
              // width: '100%',
              fontFamily: "Nunito",
              fontSize: "13px",
              borderRadius: "25px",

              textTransform: "none", // Evita la transformación a mayúsculas
            }}
            onClick={() => {
              handleDelete();
            }}
          >
            Eliminar
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
