import React, { useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  Container,
  InputAdornment,
  MenuItem,
  TextField,
  Select,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import Select from "react-select";
import { getTypes, getOperations } from "../database/types";
import { uploadFile, getIsapres, updateDoctor } from "../database/doctors";
import { useEffect } from "react";
import Switch from "@mui/material/Switch";
import Files from "react-files";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import logo from "../assets/logo.svg";
import medicine from "../assets/Medicine.svg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
// import Checkbox from '@mui/material/Checkbox';
import { styled } from "@mui/material/styles";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import ExploreOutlinedIcon from "@mui/icons-material/ExploreOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";

//REDUX IMPORTS
import { useSelector } from "react-redux";
import { location } from "../utils/locations";

export default function CompleteProfile() {
  const bankOptions = [
    { value: "Banco estado" },
    { value: "Banco Chile/Edwards" },
    { value: "Banco Internacional" },
    { value: "Banco Scotiabank" },
    { value: "Banco BCI" },
    { value: "Banco do Brasil S.A." },
    { value: "Corpbanca" },
    { value: "Banco BICE" },
    { value: "Banco HSBC Bank" },
    { value: "Banco Santander" },
    { value: "Banco ITAU" },
    { value: "Banco Security" },
    { value: "Banco Falabella" },
    { value: "Banco Ripley" },
    { value: "Banco Security" },
    { value: "RaboBank" },
    { value: "Banco Consorcio" },
    { value: "Banco Paris" },
    { value: "BBVA" },
    { value: "Banco del Desarrollo" },
    { value: "Coopeuch" },
    { value: "Prepago Los Heroes" },
    { value: "Tenpo Prepago" },
    { value: "TAPP Caja Los Andes" },
    { value: "Copec Pay" },
    { value: "Mercado Pago" },
  ];

  const typeAccountOptions = [
    { value: "Cuenta vista" },
    { value: "Cuenta corriente" },
    { value: "Cuenta de ahorro" },
  ];

  const navigate = useNavigate();
  const styleImage = {
    display: "flex",
    width: "60%",
    height: "30vh",
    padding: "0px 164px",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "stretch",
    background: "white",
    border: "1px dashed #006686",
    color: "white",
    borderRadius: "25px 25px 25px 25px",
    cursor: "pointer",
    marginTop: "20px",
    marginBottom: "20px",
  };
  const AntSwitch0 = styled(Switch)(({ theme }) => ({
    width: 50, // Ancho más grande
    height: 28, // Altura más grande
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(16px)", // Desplazamiento cuando está activado
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 4, // Espaciado dentro del switch
      "&.Mui-checked": {
        transform: "translateX(24px)", // Desplazamiento cuando está activado
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#006686",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20, // Tamaño del pulgar del switch
      height: 20, // Tamaño del pulgar del switch
      borderRadius: 15, // Radio de borde del pulgar
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16, // Radio de borde del track
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const AntSwitch1 = styled(Switch)(({ theme }) => ({
    width: 50, // Ancho más grande
    height: 28, // Altura más grande
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(16px)", // Desplazamiento cuando está activado
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 4, // Espaciado dentro del switch
      "&.Mui-checked": {
        transform: "translateX(24px)", // Desplazamiento cuando está activado
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#006686",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20, // Tamaño del pulgar del switch
      height: 20, // Tamaño del pulgar del switch
      borderRadius: 15, // Radio de borde del pulgar
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16, // Radio de borde del track
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const AntSwitch2 = styled(Switch)(({ theme }) => ({
    width: 50, // Ancho más grande
    height: 28, // Altura más grande
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(16px)", // Desplazamiento cuando está activado
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 4, // Espaciado dentro del switch
      "&.Mui-checked": {
        transform: "translateX(24px)", // Desplazamiento cuando está activado
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#006686",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 20, // Tamaño del pulgar del switch
      height: 20, // Tamaño del pulgar del switch
      borderRadius: 15, // Radio de borde del pulgar
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16, // Radio de borde del track
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const [operations, setOperations] = useState([]);
  const [speciatlity, setSpeciality] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [selectedOperated, setSelectedOperated] = useState("");
  const [operated, setOperated] = useState([]);
  const [domicilio, setDomicilio] = useState(false);
  const [presencial, setPresencial] = useState(false);
  const [videollamada, setVideollamada] = useState(false);
  const [region, setRegion] = useState("0");
  const [comuna, setComuna] = useState("0");
  const [address, setAddress] = useState("");
  const [fonasa, setFonasa] = useState(false);
  const [isapre, setIsapre] = useState(false);
  const [isapres, setIsapres] = useState([]);
  const [particular, setParticular] = useState(false);
  const [isapreSeleccted, setIsapreSelected] = useState([]);
  const [isapreArray, setIsapreArray] = useState([]);
  const [mainSpecialty, setMainSpecialty] = useState("");
  const [bankSelected, setBankSelected] = useState(0);
  const [typeAccountSelected, setTypeAccountSelected] = useState(0);
  const [numAccount, setNumAccount] = useState("");
  const [imageProfile, setImageProfile] = useState("");
  const [logoDoc, setLogoDoc] = useState("");
  const [docDescription, setDocDescription] = useState("");
  const [valueHour, setValueHour] = useState("");
  const docId = useSelector((state) => state.auth.idUser);
  const dataDoctor = useSelector((state) => state.auth.userData);

  const addNewOperation = async (operation) => {
    const { value: name } = await Swal.fire({
      title: "Ingrese el nombre de la operación:",
      input: "text",
      inputPlaceholder: "Nombre de la operación",
      showCancelButton: true,
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      inputValidator: (value) => {
        if (!value) {
          return "¡Debe ingresar un nombre!";
        }
      },
    });

    if (name) {
      if (operated.some((option) => option.value === name)) {
        Swal.fire({
          icon: "info",
          title: "Oops...",
          text: "Ya seleccionaste esta operación",
        });
      } else {
        setOperated([...operated, { value: name }]);
        setSelectedOperated(0);
      }
      Swal.fire(
        "Operación Guardada",
        `Nombre de la operación: ${name}`,
        "success"
      );
    } else {
      Swal.fire(
        "Operación Cancelada",
        "No se ingresó un nombre de operación.",
        "error"
      );
    }
  };

  useEffect(() => {
    const getDatos = async () => {
      const datas = await getOperations();
      const tempOptions = datas.map((element) => ({
        value: element.operation,
      }));
      setOperations(tempOptions);
    };
    const getSpeciatlity = async () => {
      const datas = await getTypes();
      const tempOptions = datas.map((element) => ({
        value: element.name,
      }));
      setSpeciality(tempOptions);
    };
    const getAllIsapres = async () => {
      const datas = await getIsapres();
      const tempOptions = datas.map((element) => ({
        value: element.name,
      }));
      setIsapres(tempOptions);
    };
    getAllIsapres();
    getDatos();
    getSpeciatlity();
  }, [docId, dataDoctor]);

  const handleEditImageProfile = (e) => {
    let file = e[0];
    setImageProfile(file);
  };

  const handleEditImageLogo = (e) => {
    let file = e[0];
    setLogoDoc(file);
  };

  const handleSelectSpreciality = (e) => {
    if (mainSpecialty === "") {
      if (selectedSpecialty === "" || selectedSpecialty === 0) {
        Swal.fire({
          icon: "info",
          title: "Oops...",
          text: "Selecciona una especialidad",
        });
      } else {
        setMainSpecialty(selectedSpecialty);
        setSpecialties([{ value: selectedSpecialty }]);
        setSelectedSpecialty(0);
      }
    } else {
      if (selectedSpecialty !== "" && selectedSpecialty !== 0) {
        if (!specialties.some((option) => option.value === selectedSpecialty)) {
          setSpecialties([...specialties, { value: selectedSpecialty }]);
          setSelectedSpecialty(0);
        } else {
          Swal.fire({
            icon: "info",
            title: "Oops...",
            text: "Ya seleccionaste esta especialidad",
          });
        }
      }
    }
  };

  const handleSelectOperation = (e) => {
    if (selectedOperated !== "" && selectedOperated !== 0) {
      if (operated.length === 0) {
        setOperated([...operated, { value: selectedOperated }]);
        setSelectedOperated(0);
      } else {
        if (operated.some((option) => option.value === selectedOperated)) {
          Swal.fire({
            icon: "info",
            title: "Oops...",
            text: "Ya seleccionaste esta operación",
          });
        } else {
          setOperated([...operated, { value: selectedOperated }]);
          setSelectedOperated(0);
        }
      }
    }
  };

  const handleDeleteOperation = (valueToDelete) => {
    // Filtrar el array para excluir el elemento con el valor dado
    const updatedOperated = operated.filter(
      (operation) => operation.value !== valueToDelete
    );
    setOperated(updatedOperated);
  };

  const handleSelectIsapre = (e) => {
    if (isapreSeleccted !== "" && isapreSeleccted !== 0) {
      if (isapreArray.length === 0 && isapreSeleccted !== "") {
        setIsapreArray([...isapreArray, { value: isapreSeleccted }]);
        setIsapreSelected("");
      } else {
        if (isapreArray.some((option) => option.value === isapreSeleccted)) {
          Swal.fire({
            icon: "info",
            title: "Oops...",
            text: "Ya seleccionaste esta isapre",
          });
        } else {
          if (isapreSeleccted !== 0) {
            setIsapreArray([...isapreArray, { value: isapreSeleccted }]);
            setIsapreSelected("");
          }
        }
      }
    }
  };

  const handleDeleteIsapre = (valueToDelete) => {
    // Filtrar el array de isapres para excluir el elemento con el valor dado
    const updatedIsapreArray = isapreArray.filter(
      (isapre) => isapre.value !== valueToDelete
    );
    setIsapreArray(updatedIsapreArray);
  };

  const handlePresencial = (e) => {
    setPresencial(e);
  };

  const handleDomicilio = (e) => {
    setDomicilio(e);
  };

  const handleVideollamada = (e) => {
    setVideollamada(e);
  };

  const handleCompleteProfile = async () => {
    let data = {
      location: {
        address: address,
        region: region,
        comuna: comuna,
      },
      docDescription: docDescription,
      mainSpecialty: mainSpecialty,
      typeDoctor: specialties,
      operationsList: operated,
      queryTypes: {
        domicilio: domicilio,
        presencial: presencial,
        videollamada: videollamada,
      },
      previsions: {
        fonasa: fonasa,
        isapre: isapre,
        particular: particular,
      },
      bankAccount: {
        bankSelected: bankSelected,
        typeAccountSelected: typeAccountSelected,
        numAccount: numAccount,
      },
      status: "enabled",
      selectedIsapre: isapreArray,
      firstTime: false,
      valueHour: valueHour,
    };
    const dataDoctorCenterMed = {
      location: {
        address: address,
        region: region,
        comuna: comuna,
      },
      bankAccount: {
        bankSelected: bankSelected,
        typeAccountSelected: typeAccountSelected,
        numAccount: numAccount,
      },
      docDescription: docDescription,
      mainSpecialty: mainSpecialty,
      typeDoctor: specialties,
      operationsList: operated,
      status: "enabled",
      firstTime: false,
    };
    let uncomplete = [];

    if (!dataDoctor.medicalCenterId) {
      if (address === "") {
        uncomplete.push("Dirección");
      }
      if (region === "0") {
        uncomplete.push("Región");
      }
      if (comuna === "0") {
        uncomplete.push("Comuna");
      }
      if (
        presencial === false &&
        domicilio === false &&
        videollamada === false
      ) {
        uncomplete.push("Tipo de atención");
      }
      if (fonasa === false && isapre === false && particular === false) {
        uncomplete.push("Tipo de pago recibido");
      }
      if (isapre === true && isapreArray.length === 0) {
        uncomplete.push("Isapres");
      }
      if (mainSpecialty === "") {
        uncomplete.push("Especialidad principal");
      }
      if (bankSelected === 0) {
        uncomplete.push("Banco");
      }
      if (typeAccountSelected === 0) {
        uncomplete.push("Tipo de cuenta");
      }
      if (numAccount === "") {
        uncomplete.push("Numero de cuenta");
      }
      if (docDescription === "") {
        uncomplete.push("Descripción del doctor");
      }
      if (logoDoc === "") {
        uncomplete.push("Logo del doctor");
      }
      if (valueHour === "") {
        uncomplete.push("Valor de la hora");
      }
    } else {
      data = dataDoctorCenterMed;
      if (address === "") {
        uncomplete.push("Dirección");
      }
      if (region === "0") {
        uncomplete.push("Región");
      }
      if (comuna === "0") {
        uncomplete.push("Comuna");
      }

      if (mainSpecialty === "") {
        uncomplete.push("Especialidad principal");
      }

      if (docDescription === "") {
        uncomplete.push("Descripción del doctor");
      }
      if (logoDoc === "") {
        uncomplete.push("Logo del doctor");
      }
      if (bankSelected === 0) {
        uncomplete.push("Banco");
      }
      if (typeAccountSelected === 0) {
        uncomplete.push("Tipo de cuenta");
      }
      if (numAccount === "") {
        uncomplete.push("Numero de cuenta");
      }
    }

    if (uncomplete.length > 0) {
      Swal.fire({
        icon: "info",
        title: "Oops...",
        text: `Por favor complete el/los campo(s): ${uncomplete.join(", ")}`,
      });
    } else {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      if (imageProfile === "") {
        Swal.close();
        Swal.fire({
          icon: "question",
          title: "¿Estas seguro?",
          text: "No has seleccionado una imagen de perfil, ¿Deseas continuar?",
          showCancelButton: true,
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              imageUrl: loadingSVG,
              imageWidth: 300,
              imageHeight: 300,
              title: "Cargando...",
              showConfirmButton: false,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            const res = updateDoctor(data, docId);
            if (res) {
              Swal.close();
              Swal.fire({
                icon: "success",
                title: "Se ha completado el perfil",
                text: "ya completaste tu perfil ahora puedes Comenzar a utilizar tu aplicación",
              }).then(() => {
                navigate("/");
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Hubo un error al completar tu perfil, intentalo nuevamente",
              });
            }
          }
        });
      } else {
        const resLogoImage = await uploadFile(logoDoc, "logo");
        const resImage = await uploadFile(imageProfile, "imageProfile");
        console.log("resLogoImage", resLogoImage);
        console.log("resImage", resImage);
        let res;
        if (resLogoImage && resImage) {
          data.imageProfile = resImage;
          data.logo = resLogoImage;
          res = await updateDoctor(data, docId);
        }
        if (res) {
          Swal.close();
          Swal.fire({
            icon: "success",
            title: "Se ha completado el perfil",
            text: "ya completaste tu perfil ahora puedes Comenzar a utilizar tu aplicación",
          }).then(() => {
            navigate("/");
          });
        }
      }
      // console.log('completado');
    }
  };

  const handleDeleteSpecialty = (value) => {
    // Filtrar el array de especialidades para excluir la especialidad con el valor dado
    const updatedSpecialties = specialties.filter(
      (specialty) => specialty.value !== value
    );
    setSpecialties(updatedSpecialties);

    // Si la especialidad eliminada es la principal, restablece mainSpecialty a una cadena vacía
    if (mainSpecialty === value) {
      setMainSpecialty("");
    }
    // Si la especialidad eliminada es una especialidad secundaria, también la eliminamos de mainSpecialty si coincide
    if (
      mainSpecialty === value &&
      specialties.some((option) => option.value === value)
    ) {
      setMainSpecialty("");
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: { xs: "100%", lg: "800px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          alt="logo"
          src={logo}
          style={{ marginTop: "10px", width: "120px", marginBottom: "30px" }}
        />
        <Typography
          variant="displaySmall"
          sx={{
            color: "#006686)",
            textAlign: "center",
          }}
        >
          Bienvenido a GetMED, a continuación configuraremos tu perfil médico
        </Typography>
        <img
          alt="medicine"
          src={medicine}
          style={{ marginTop: "50px", marginBottom: "30px" }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          marginBottom: "50px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "95%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="headlineSmall"
            sx={{
              color: "#006686)",
              textAlign: "center",
            }}
          >
            {dataDoctor.medicalCenterId ? "1 de 6" : "1 de 7"}
          </Typography>
          <CheckCircleOutlineIcon
            sx={{
              fontSize: "35px",
              color:
                address.length > 0 && region.length > 0 && comuna.length > 0
                  ? "green"
                  : "grey",
            }}
          />
        </Box>
        <Card
          sx={{
            mt: 2,
            borderRadius: "28px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: 2, md: 4, lg: 6 },
            gap: 2,
          }}
        >
          <Typography variant="titleLarge" sx={{}}>
            Dirección de consulta
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="bodyLarge"
              sx={{
                color: "#006686",
                textAlign: "center",
              }}
            >
              Indica tu dirección de atención
            </Typography>
          </Box>
          <Select
            required
            margin="normal"
            variant="filled"
            fullWidth
            startAdornment={
              <InputAdornment position="start" sx={{ color: "black" }}>
                <MapOutlinedIcon />
              </InputAdornment>
            }
            defaultValue={region}
            onChange={(e) => {
              setRegion(e.target.value);
              setComuna("0");
            }}
          >
            <MenuItem
              sx={{
                color: "grey",
              }}
              value={0}
            >
              Seleccione región
            </MenuItem>
            {location.map((e, i) => (
              <MenuItem value={e.region} key={i}>
                {e.region}
              </MenuItem>
            ))}
          </Select>

          <Select
            required
            fullWidth
            margin="normal"
            variant="filled"
            startAdornment={
              <InputAdornment position="start" sx={{ color: "black" }}>
                <ExploreOutlinedIcon />
              </InputAdornment>
            }
            value={comuna}
            defaultValue={"0"}
            onChange={(e) => {
              setComuna(e.target.value);
            }}
          >
            <MenuItem value={"0"}>Seleccione su comuna</MenuItem>
            {location.map((e, i) => {
              if (e.region === region) {
                return e.comunas.map((comuna, i) => (
                  <MenuItem value={comuna} key={i}>
                    {comuna}
                  </MenuItem>
                ));
              } else {
                return null;
              }
            })}
          </Select>

          <TextField
            required
            placeholder="Dirección"
            // value={rut}
            type="text"
            fullWidth
            onChange={(e) => setAddress(e.target.value)}
            variant="filled"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ color: "black" }}>
                  <PlaceOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
        </Card>
      </Box>
      {!dataDoctor.medicalCenterId && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginBottom: "50px",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "95%",
              alignItems: "center",
            }}
          >
            <Typography
              variant="headlineSmall"
              sx={{
                color: "#006686)",
                textAlign: "center",
              }}
            >
              2 de 7
            </Typography>
            <CheckCircleOutlineIcon
              sx={{
                fontSize: "35px",
                color:
                  presencial || videollamada || domicilio ? "green" : "grey",
              }}
            />
          </Box>
          <Card
            id="card"
            sx={{
              mt: 2,
              borderRadius: "28px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              p: { xs: 2, md: 4, lg: 6 },
              gap: 2,
            }}
          >
            <Typography
              variant="titleLarge"
              sx={{
                textAlign: "center",
              }}
            >
              ¿Cómo te gustaría atender a tus pacientes?
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5vh",
                  marginBottom: "3vh",
                }}
              >
                <Typography
                  variant="bodyLarge"
                  sx={{
                    color: "#006686)",
                    textAlign: "center",
                  }}
                >
                  Tipos de atención (Seleccione al menos una opción)
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <AntSwitch0
                  inputProps={{ "aria-label": "ant design0" }}
                  checked={presencial}
                  onChange={(e) => {
                    handlePresencial(e.target.checked);
                  }}
                />
                <Typography variant="body" sx={{ ml: 3 }}>
                  Presencial
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <AntSwitch1
                  inputProps={{ "aria-label": "ant design1" }}
                  checked={videollamada}
                  onChange={(e) => {
                    handleVideollamada(e.target.checked);
                  }}
                />
                <Typography variant="body" sx={{ ml: 3 }}>
                  Videollamada
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  marginBottom: "25px",
                }}
              >
                <AntSwitch2
                  inputProps={{ "aria-label": "ant design2" }}
                  checked={domicilio}
                  onChange={(e) => {
                    handleDomicilio(e.target.checked);
                  }}
                />
                <Typography variant="body" sx={{ ml: 3 }}>
                  A domicilio
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body2"
              sx={{ marginBottom: "50px", marginTop: "40px" }}
            >
              Se puede cambiar en configuraciones más tarde *
            </Typography>
          </Card>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "95%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="headlineSmall"
            sx={{
              color: "#006686)",
              textAlign: "center",
            }}
          >
            {dataDoctor.medicalCenterId ? "2 de 6" : "3 de 7"}
          </Typography>
          <CheckCircleOutlineIcon
            sx={{
              fontSize: "35px",
              color: mainSpecialty !== "" ? "green" : "grey",
            }}
          />
        </Box>
        <Card
          sx={{
            mt: 2,
            borderRadius: "28px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: 2, md: 4, lg: 6 },
            gap: 2,
          }}
        >
          <Typography
            variant="titleLarge"
            sx={{
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Especialidades
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "12px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "5vh",
              }}
            >
              <Typography
                variant="bodyLarge"
                sx={{
                  // marginTop: "50px",
                  // marginBottom: "20px"
                  color: "#006686",
                  textAlign: "center",
                  fontSize: "20px",
                  fontStyle: "normal",
                  marginBottom: "10px",
                }}
              >
                Mis especialidades
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {/* <Typography
                variant="h5"
                sx={{
                  // marginTop: "50px",
                  // marginBottom: "20px"
                  color: "#006686",
                  textAlign: "center",
                  fontSize: "16px",
                  fontStyle: "normal",
                  marginBottom: "10px",
                }}
              >
                Seleccione su especialidad principal
              </Typography> */}
            </Box>
            {mainSpecialty !== "" && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "20px",
                  }}
                  variant="body1"
                >
                  {mainSpecialty}
                </Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={() => handleDeleteSpecialty(mainSpecialty)} // Eliminar la especialidad principal
                >
                  <DeleteOutlineOutlined />
                </IconButton>
              </Box>
            )}

            {mainSpecialty !== "" && (
              <Box
                sx={{
                  width: "80%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {/* <Typography
                  variant="h5"
                  sx={{
                    // marginTop: "50px",
                    // marginBottom: "20px"
                    color: "#006686",
                    textAlign: "center",
                    fontSize: "16px",
                    fontStyle: "normal",
                    marginBottom: "15px",
                  }}
                >
                  Seleccione su especialidad segundaria
                </Typography> */}
              </Box>
            )}
            {specialties.length > 1 &&
              specialties.slice(1).map((specialty, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                    variant="body1"
                  >
                    {specialty.value && specialty.value}
                  </Typography>
                  <IconButton
                    sx={{ color: "black" }}
                    onClick={() => handleDeleteSpecialty(specialty.value)} // Eliminar especialidad secundaria
                  >
                    <DeleteOutlineOutlined />
                  </IconButton>
                </Box>
              ))}

            <Select
              required
              fullWidth
              variant="filled"
              startAdornment={
                <InputAdornment position="start" sx={{ color: "black" }}>
                  <AddBoxOutlinedIcon />
                </InputAdornment>
              }
              defaultValue={0}
              value={selectedSpecialty === "" ? 0 : selectedSpecialty}
              onChange={(e) => setSelectedSpecialty(e.target.value)}
            >
              <MenuItem value={0}>
                {mainSpecialty === ""
                  ? "Seleccione especialidad principal"
                  : "Seleccione especialidad segundaria"}
              </MenuItem>
              {speciatlity.map((e, i) => (
                <MenuItem value={e.value || ""} key={i}>
                  {e.value.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Button
            variant="outlined"
            onClick={() => {
              handleSelectSpreciality();
            }}
          >
            {mainSpecialty === ""
              ? "Añadir especialidad principal"
              : "Añadir especialidad segundaria"}
          </Button>
          <Typography
            variant="body2"
            sx={{ marginBottom: "50px", marginTop: "40px" }}
          >
            Se puede cambiar en configuraciones más tarde *
          </Typography>
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "95%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="headlineSmall"
            sx={{
              color: "#006686)",
              textAlign: "center",
            }}
          >
            {dataDoctor.medicalCenterId ? "3 de 6" : "4 de 7"}
          </Typography>
          <CheckCircleOutlineIcon
            sx={{
              fontSize: "35px",
              color: operated.length > 0 ? "green" : "grey",
            }}
          />
        </Box>

        <Card
          sx={{
            mt: 2,
            borderRadius: "28px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: 2, md: 4, lg: 6 },
            gap: 2,
          }}
        >
          <Typography
            variant="titleLarge"
            sx={{
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            ¿Realizas operaciones?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "5vh",
              }}
            >
              <Typography
                variant="bodyLarge"
                sx={{
                  // marginTop: "50px",
                  // marginBottom: "20px"
                  color: "#006686",
                  textAlign: "center",
                  marginBottom: "12px",
                }}
              >
                Operaciones
              </Typography>
            </Box>
            {operated.map((el, index) => (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                  }}
                  variant="body1"
                >
                  {el.value}
                </Typography>
                <IconButton
                  sx={{ color: "black" }}
                  onClick={() => handleDeleteOperation(el.value)}
                >
                  <DeleteOutlineOutlined />
                </IconButton>
              </Box>
            ))}
            <Select
              required
              variant="filled"
              fullWidth
              startAdornment={
                <InputAdornment position="start" sx={{ color: "black" }}>
                  <AddBoxOutlinedIcon />
                </InputAdornment>
              }
              value={selectedOperated === "" ? 0 : selectedOperated}
              defaultValue={0}
              onChange={(e) => {
                setSelectedOperated(e.target.value);
              }}
            >
              <MenuItem value={0}>Seleccione operación</MenuItem>
              {operations.map((e, i) => (
                <MenuItem value={e.value || ""} key={i}>
                  {e.value.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              flexDirection: { xs: "column", md: "row" },
              marginTop: "20px",
              gap: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                handleSelectOperation();
              }}
            >
              Añadir operación seleccionada
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                addNewOperation();
              }}
            >
              Añadir otra
            </Button>
          </Box>
          <Typography
            variant="body2"
            sx={{ marginBottom: "50px", marginTop: "40px" }}
          >
            Se puede cambiar en configuraciones más tarde *
          </Typography>
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "95%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="headlineSmall"
            sx={{
              color: "#006686)",
              textAlign: "center",
            }}
          >
            {dataDoctor.medicalCenterId ? "4 de 6" : "5 de 7"}
          </Typography>
          <CheckCircleOutlineIcon
            sx={{
              fontSize: "35px",
              color:
                (particular &&
                  bankSelected !== 0 &&
                  typeAccountSelected !== 0 &&
                  numAccount.length > 0) ||
                (fonasa &&
                  bankSelected !== 0 &&
                  typeAccountSelected !== 0 &&
                  numAccount.length > 0) ||
                (isapre &&
                  bankSelected !== 0 &&
                  typeAccountSelected !== 0 &&
                  numAccount.length > 0 &&
                  isapreArray.length > 0)
                  ? "green"
                  : "grey",
            }}
          />
        </Box>

        <Card
          sx={{
            mt: 2,
            borderRadius: "28px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: 2, md: 4, lg: 6 },
            gap: 2,
          }}
        >
          <Typography
            variant="titleLarge"
            sx={{
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            {!dataDoctor.medicalCenterId
              ? "Datos bancarios y medios de pago "
              : "Datos bancarios"}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "12px",
            }}
          >
            {!dataDoctor.medicalCenterId && (
              <>
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setParticular(e.target.checked);
                      }}
                      sx={{
                        color: "#006686",
                        "&.Mui-checked": {
                          color: "#006686",
                        },
                      }}
                    />
                    <Typography variant="body" sx={{ ml: 3 }}>
                      Particular
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setFonasa(e.target.checked);
                      }}
                      sx={{
                        color: "#006686",
                        "&.Mui-checked": {
                          color: "#006686",
                        },
                      }}
                    />
                    <Typography variant="body" sx={{ ml: 3 }}>
                      Fonasa
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Checkbox
                      onChange={(e) => {
                        setIsapre(e.target.checked);
                        setIsapreArray([]);
                      }}
                      sx={{
                        color: "#006686",
                        "&.Mui-checked": {
                          color: "#006686",
                        },
                      }}
                    />
                    <Typography variant="body" sx={{ ml: 3 }}>
                      Isapre
                    </Typography>
                  </Box>
                </Box>
                {isapre && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "3vh",
                    }}
                  >
                    <Typography
                      variant="bodyLarge"
                      sx={{
                        color: "#006686",
                        textAlign: "center",
                        marginBottom: "12px",
                      }}
                    >
                      Al seleccionar Isapre, deberá indicar las que aceptas
                    </Typography>
                  </Box>
                )}
                {isapreArray.map((el, index) => (
                  <Box
                    key={index}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                      }}
                      variant="body1"
                    >
                      {el.value}
                    </Typography>
                    <IconButton
                      sx={{ color: "black" }}
                      onClick={() => handleDeleteIsapre(el.value)}
                    >
                      <DeleteOutlineOutlined />
                    </IconButton>
                  </Box>
                ))}
                {isapre && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      flexDirection: { xs: "column", md: "row" },
                      marginTop: "20px",
                      gap: 2,
                    }}
                  >
                    <Select
                      required
                      variant="filled"
                      fullWidth
                      startAdornment={
                        <InputAdornment
                          position="start"
                          sx={{ color: "black" }}
                        >
                          <AddBoxOutlinedIcon />
                        </InputAdornment>
                      }
                      defaultValue={0}
                      onChange={(e) => {
                        setIsapreSelected(e.target.value);
                      }}
                    >
                      <MenuItem value={0}>Seleccione Isapre</MenuItem>
                      {isapres.map((e, i) => (
                        <MenuItem value={e.value || ""} key={i}>
                          {e.value.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        handleSelectIsapre();
                      }}
                    >
                      Añadir
                    </Button>
                  </Box>
                )}
              </>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                flexDirection: { xs: "column" },
                marginTop: "20px",
                gap: 2,
              }}
            >
              <Typography
                variant="bodyLarge"
                sx={{
                  color: "#006686",
                  marginBottom: 1,
                }}
              >
                Datos bancarios
              </Typography>

              <Select
                required
                fullWidth
                variant="filled"
                startAdornment={
                  <InputAdornment position="start" sx={{ color: "black" }}>
                    <AccountBalanceOutlinedIcon />
                  </InputAdornment>
                }
                value={bankSelected}
                defaultValue={0}
                onChange={(e) => {
                  setBankSelected(e.target.value);
                }}
              >
                <MenuItem value={0}>Seleccione banco</MenuItem>
                {bankOptions.map((e, i) => (
                  <MenuItem value={e.value || i} key={i}>
                    {e.value.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              <Select
                required
                variant="filled"
                fullWidth
                value={typeAccountSelected}
                defaultValue={0}
                onChange={(e) => {
                  setTypeAccountSelected(e.target.value);
                }}
              >
                <MenuItem value={0}>Seleccione tipo de cuenta</MenuItem>
                {typeAccountOptions.map((e, i) => (
                  <MenuItem value={e.value || i} key={i}>
                    {e.value.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                sx={{
                  width: "100%",
                  backgroundColor: "#DEE3EB",
                }}
                required
                placeholder="Número de cuenta"
                // value={rut}
                type="number"
                onChange={(e) => setNumAccount(e.target.value)}
                variant="filled"
              />
            </Box>
            {!dataDoctor.medicalCenterId && (
              <>
                {" "}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "3vh",
                  }}
                >
                  <Typography
                    variant="bodyLarge"
                    sx={{
                      color: "#006686",
                      textAlign: "center",
                      marginBottom: 1,
                    }}
                  >
                    Valor de la consulta
                  </Typography>
                </Box>
                <TextField
                  required
                  placeholder="Ingrese el monto en peso chileno"
                  // value={rut}
                  type="number"
                  onChange={(e) => setValueHour(e.target.value)}
                  variant="filled"
                  fullWidth
                />
              </>
            )}

            <br />

            <Typography
              variant="body2"
              sx={{ marginBottom: "50px", marginTop: "40px" }}
            >
              Se puede cambiar en configuraciones más tarde *
            </Typography>
          </Box>
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "95%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="headlineSmall"
            sx={{
              color: "#006686)",
              textAlign: "center",
            }}
          >
            {dataDoctor.medicalCenterId ? "5 de 6" : "6 de 7"}
          </Typography>
          <CheckCircleOutlineIcon
            sx={{ fontSize: "35px", color: logoDoc !== "" ? "green" : "grey" }}
          />
        </Box>
        <Card
          id="card"
          sx={{
            mt: 2,
            borderRadius: "28px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: 2, md: 4, lg: 6 },
            gap: 2,
          }}
        >
          <Typography
            variant="titleLarge"
            sx={{
              marginBottom: "20px",
            }}
          >
            Logo
          </Typography>
          <Box
            sx={{
              width: "100 %",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 2,
            }}
          >
            <Typography
              variant="bodyLarge"
              sx={{
                color: "#006686",
                textAlign: "center",
                marginBottom: 1,
              }}
            >
              Esta imagen se mostrará a los usuarios de la aplicación
            </Typography>
          </Box>
          {logoDoc !== "" ? (
            <Container sx={styleImage}>
              <img
                style={{ objectFit: "contain", width: "25vw", height: "25vh" }}
                className="files-gallery-item"
                src={logoDoc.preview.url}
                alt="photo"
                onClick={() => {
                  setLogoDoc("");
                }}
              />
            </Container>
          ) : (
            <Files
              className="files-dropzone"
              onChange={(e) => {
                handleEditImageLogo(e);
              }}
              // onError={handleError}
              accepts={["image/*"]}
              clickable
              style={{
                width: "80%",
                height: "264px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                border: "1px solid #006686",
                color: "#006686",
                borderRadius: "28px",
                cursor: "pointer",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              maxFiles={1}
            >
              <Typography
                variant="bodyLarge"
                sx={{
                  color: "#006686",
                  textAlign: "center",
                }}
              >
                Arrastre o haga click para seleccionar
              </Typography>
            </Files>
          )}

          {/* <Box style={{ width: "60%", display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '3vh' }}>
            <Typography variant="h5" sx={{
              // marginTop: "50px",
              // marginBottom: "20px"
              color: '#006686',
              textAlign: 'center',
              fontSize: '18px',
              fontStyle: 'normal',
              marginBottom: "10px"
            }}>Esta descripción se mostrará a los usuarios de la aplicación</Typography>
            
          </Box>
          <TextField
            sx={{
              width: '60%',
              backgroundColor: '#DEE3EB',
            }}
            required
            placeholder="Descripción del doctor"
            // value={rut}
            type="text"
            onChange={(e) => setDocDescription(e.target.value)}
            variant="standard"
            multiline
            rows={8}
            inputProps={{
              style: {
                // height: '100vh', // Aumenta la altura
                paddingLeft: '10px'
              },
            }}
          /> */}
          <Typography
            variant="body2"
            sx={{ marginBottom: "50px", marginTop: "40px" }}
          >
            Se puede cambiar en configuraciones más tarde *
          </Typography>
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "50px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "95%",
            alignItems: "center",
          }}
        >
          <Typography
            variant="headlineSmall"
            sx={{
              color: "#006686)",
              textAlign: "center",
            }}
          >
            {dataDoctor.medicalCenterId ? "6 de 6" : "7 de 7"}
          </Typography>
          <CheckCircleOutlineIcon
            sx={{
              fontSize: "35px",
              color:
                imageProfile !== "" && docDescription !== "" ? "green" : "grey",
            }}
          />
        </Box>
        <Card
          id="card"
          sx={{
            mt: 2,
            borderRadius: "28px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            p: { xs: 2, md: 4, lg: 6 },
            gap: 2,
          }}
        >
          <Typography
            variant="titleLarge"
            sx={{
              marginBottom: "20px",
            }}
          >
            Perfil
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "3vh",
            }}
          >
            <Typography
              variant="bodyLarge"
              sx={{
                color: "#006686",
                textAlign: "center",
                marginBottom: 1,
              }}
            >
              Esta imagen se mostrará a los usuarios de la aplicación
            </Typography>
          </Box>
          {imageProfile !== "" ? (
            <Container sx={styleImage}>
              <img
                style={{ objectFit: "contain", width: "25vw", height: "25vh" }}
                className="files-gallery-item"
                src={imageProfile.preview.url}
                alt="photo"
                onClick={() => {
                  setImageProfile("");
                }}
              />
            </Container>
          ) : (
            <Files
              className="files-dropzone"
              onChange={(e) => {
                handleEditImageProfile(e);
              }}
              // onError={handleError}
              accepts={["image/*"]}
              clickable
              style={{
                width: "80%",
                height: "264px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                border: "1px solid #006686",
                color: "#006686",
                borderRadius: "28px",
                cursor: "pointer",
                marginTop: "20px",
                marginBottom: "20px",
              }}
              maxFiles={1}
            >
              <Typography
                variant="bodyLarge"
                sx={{
                  color: "#006686",
                  textAlign: "center",
                }}
              >
                Arrastre o haga click para seleccionar
              </Typography>
            </Files>
          )}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "3vh",
            }}
          >
            <Typography
              variant="bodyLarge"
              sx={{
                color: "#006686",
                textAlign: "center",
                marginBottom: 1,
              }}
            >
              Esta descripción se mostrará a los usuarios de la aplicación
            </Typography>
          </Box>
          <TextField
            required
            placeholder="Descripción del doctor"
            // value={rut}
            type="text"
            onChange={(e) => setDocDescription(e.target.value)}
            variant="filled"
            fullWidth
            multiline
            rows={5}
            inputProps={{
              style: {
                // height: '100vh', // Aumenta la altura
                paddingLeft: "10px",
              },
            }}
          />
          <Typography
            variant="body2"
            sx={{ marginBottom: "50px", marginTop: "40px" }}
          >
            Se puede cambiar en configuraciones más tarde *
          </Typography>
        </Card>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 4,
          marginBottom: 4,
        }}
      >
        <Button
          variant="contained"
          disableElevation
          onClick={() => {
            handleCompleteProfile();
          }}
        >
          Guardar Perfil
        </Button>
      </Box>
    </Container>
  );
}
