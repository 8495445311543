import {
  Box,
  Button,
  CardActions,
  Container,
  Divider,
  Grid,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Secretaries from "../../src/assets/Secretaies.svg";
import CardSecretary from "./card_secretary";
import Swal from "sweetalert2";
import loadingSVG from "../assets/logo_Loading.gif";
import { useUpdateSecretaries } from "../context/UpdateSrecretaries";

export default function SecretariesComponent({
  handleOpenDialog,
  setType,
  setSecretarieData,
}) {
  const { secretariesData } = useUpdateSecretaries();
  // const myId = useSelector((state) => state.auth.idUser);
  // const userData = useSelector((state) => state.auth.userData);
  const [secretaries, setSecretaries] = useState(secretariesData);

  useEffect(() => {
    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      setSecretaries(secretariesData);
      Swal.close();
    }
    getData();
    // updateSecretariesAsync();
  }, [secretariesData]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 0,
        height: "100%",
      }}
    >
      <Box
        sx={{
          p: 2,
          pb: 12,
          flexGrow: 1,
          overflowY: "scroll",
        }}
      >
        {secretaries?.length > 0 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid container>
              {secretaries.map((secretary, index) => (
                <Grid key={index}>
                  <CardSecretary
                    data={secretary}
                    setType={setType}
                    handleOpenDialog={handleOpenDialog}
                    setSecretarieData={setSecretarieData}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={Secretaries} alt="secretaries" width={"60%"} />
            <Typography
              variant="h5"
              sx={{
                color: "var(--m-3-sys-light-primary, #006686)",
                textAlign: "center",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "32px",
                color: "rgba(0, 102, 134, 1)",
              }}
            >
              Agregue una secretaria
            </Typography>
          </div>
        )}
      </Box>

      <Box
        sx={{
          zIndex: "200",
          width: "100%",
          backgroundColor: "white",
          position: "sticky",
          bottom: { xs: "0px", sm: "0px", md: "60px" },
        }}
      >
        <Divider />
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            py: 3,
            px: 2,
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              setType("new");
              handleOpenDialog();
            }}
          >
            Agregar
          </Button>
        </CardActions>
      </Box>
    </Box>
  );
}
