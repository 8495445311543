export const arrExam = [
    {
      id: 1,
      tipo: "Toma de muestra",
      elementos: ["Sangre adulto", "Sangre niño"],
    },
    {
      id: 2,
      tipo: "Hematologos",
      elementos: [
        "Hemogramas - VHS",
        "Protrombina",
        "Ttpk",
        "Tiempo de sangria",
        "Rcto plaquetas",
        "Grupo rh",
        "Ferremia",
      ],
    },
    {
      id: 3,
      tipo: "Bioquimicos",
      elementos: [
        "Glocema",
        "Uremia-bun",
        "Acido úrico",
        "Colesterol total",
        "Trigliceridos",
        "Creatina",
        "Transaminasas(GOT-GTP)",
        "Electrolitros (NA-K-CL)",
        "Curva tolerancia glucosa",
        "Hemoglobina Glicosilada",
        "Perfil Hepático",
        "Perfil lipidico",
        "Perfil bioquimico",
      ],
    },
    {
      id: 4,
      tipo: "Hormonales",
      elementos: [
        "T3",
        "T4",
        "T4 libre",
        "Tsh",
        "Insulina",
        "Curva de insulina",
        "B.H.C.G",
        "H. Foliculo estimul (Fs.H)",
        "H luteinizante (LH.)",
        "Estradiol",
        "Prolactina",
        "Progesterona",
        "Testosterona",
      ],
    },
    {
      id: 5,
      tipo: "Marcadores tumorales",
      elementos: [
        "PSA total",
        "Psa libre",
        "Cea",
        "Ca 125",
        "Ga 19-9",
        "Ca 15-3",
      ],
    },
    {
      id: 6,
      tipo: "Orina",
      elementos: [
        "Orina completa",
        "Urocultivo-abg",
        "Cleareange de crea",
        "Migroalbuminuria",
        "Proteinuria",
      ],
    },
    {
      id: 7,
      tipo: "Deposiciones",
      elementos: [
        "Coprogultivo",
        "Rotavirus",
        "Adenovirus",
        "Hemoragias ocultas",
        "Parasitologico",
        "Test Graham",
        "Leucocitos fecales",
        "PHI",
        "Fehling",
        "Helicobacter pylori heces",
      ],
    },
    {
      id: 8,
      tipo: "Inmunologicos",
      elementos: [
        "Proteina C. Reactiva",
        "Factor Reumatoideo",
        "Ac. Anti Nucleares",
        "Aso",
        "Ac Anti Tiroideos",
        "Ige Total",
        "Vih",
        "Vd.R.L",
        "Hepatitis a (LgG. 10M)",
        "Hepatitis B",
        "Hepatitis C",
        "Mycoplasma Pn (LgG LgM)",
      ],
    },
    {
      id: 9,
      tipo: "Microbioticos",
      elementos: [
        "Cultivo corriente",
        "Antibiograma",
        "Cultivo de hongos",
        "Cultivo de gonococos",
        "Tinción de Gram",
        "Flujo vaginal o flujo uretral",
        "Clamidas",
        "Mycoplasma - Ureaplasma",
        "Recuento eosinofilos",
        "Estudio flujo vaginal",
        "Estudio secrecion uretral",
      ],
    },
    {
      id: 10,
      tipo: "Otros",
      elementos: [
        "Vitamina B12",
        "Vitamina D",
        "Amilasa",
        "Lipasa",
        "Ck total",
        "Ck Mb",
        "Ggt",
        "Helicobacter Pylori Sangre",
        "Panel Respiratorio Ifi",
        "P. Resp Viral por Pcr-Rt",
        "P. Resp Bact por Pcr-Rt",
        "Bordetella por Pcr-Rt",
      ],
    },
  ];