import Swal from 'sweetalert2';
import { logout } from '../actions/auth_actions';
import { auth } from '../database/config';
import { signOut } from 'firebase/auth';

function startInactivityTimer({ navigate, dispatch }) {
  let timer;

  const redirectToLogin = () => {
    dispatch(logout());
    signOut(auth)
      .then(() => {
        navigate("/login");
        console.log("Signed out successfully");
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const showWarning = () => {
    // Muestra el cuadro de diálogo de Swal
    Swal.fire({
      title: '¡Inactividad detectada!',
      text: 'Dentro de dos minutos se cerrará tu sesión.',
      icon: 'warning',
      confirmButtonText: 'OK'
    });
  };

  const resetTimer = () => {
    clearTimeout(timer);
    // Mostrar el cuadro de diálogo de Swal después de 10 segundos de inactividad
    timer = setTimeout(showWarning, 480000);
  };

  const handleActivity = () => {
    resetTimer();
  };

  // Agregar oyentes de eventos para restablecer el temporizador en actividad
  window.addEventListener('mousemove', handleActivity);
  window.addEventListener('keypress', handleActivity);

  // Iniciar el temporizador
  resetTimer();

  // Redirigir al login después de 10 minutos de inactividad
  const logoutTimer = setTimeout(redirectToLogin, 600000);

  // Limpiar el temporizador y los oyentes de eventos cuando ya no se necesiten
  return () => {
    clearTimeout(timer);
    clearTimeout(logoutTimer);
    window.removeEventListener('mousemove', handleActivity);
    window.removeEventListener('keypress', handleActivity);
  };
}

export default startInactivityTimer;