import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import {
  getMyAcceptedRequests,
  subscribeToMyAcceptedRequests,
} from "../database/request";
import DialogCalendar from "../components/dialog_calendar";

import DialogNewReserve from "../components/new_reserve_dialog";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import loadingSVG from "../assets/logo_Loading.gif";

//REDUX IMPORTS
import { useSelector } from "react-redux";
import TopBar from "../components/top_bar";
import { getDoctorById } from "../database/doctors";
import Swal from "sweetalert2";
// import NewDrawerComponent from "../components/new_drawer";
import { Box, Paper } from "@mui/material";

export default function CalendarPage() {
  // const [loading, setLoading] = useState(true);
  const localData = useSelector((state) => state.auth.userData);

  const [acceptedRequest, setAcceptedRequest] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [openNewReserve, setOpenNewReserve] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [dataDoctor, setDataDoctor] = useState({});
  const [isMorePopoverOpen, setIsMorePopoverOpen] = useState(false);
  const [myId, setMyId] = useState(
    localData.idDoctor ? localData.idDoctor : localData.id
  );

  const handleClose = () => {
    setOpen(false);
  };

  const close = () => {
    setOpenNewReserve(false);
  };
  const openNew = () => {
    setOpenNewReserve(true);
  };

  const handleClick = (info) => {
    var fechaActual = new Date();
    fechaActual.setHours(0, 0, 0, 0); // Normalizar la fecha actual a las 00:00 horas

    // Obtener la fecha en formato ISO (YYYY-MM-DD)
    var fechaActualISO = fechaActual.toISOString().split("T")[0];

    // Verificar si la fecha es anterior a la fecha actual
    if (info.dateStr < fechaActualISO) {
      Swal.fire({
        icon: "error",
        title: "Día pasado",
        text: "No puedes reservar en un día que ya pasó. Prueba a reservar hoy u otro día futuro.",
      });
    } else {
      setSelectedDate(info.dateStr);
      openNew();
    }
  };

  useEffect(() => {
    let unsubscribe; // Define la variable unsubscribe fuera del bloque if

    async function getData() {
      Swal.fire({
        imageUrl: loadingSVG,
        imageWidth: 300,
        imageHeight: 300,
        title: "Cargando...",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
      if (localData.role === "doctor") {
        const doctorData = await getDoctorById(myId);
        setDataDoctor(doctorData);
        // Llama a la función subscribeToMyAcceptedRequests pasando el ID del usuario y una función callback para manejar los datos
        unsubscribe = subscribeToMyAcceptedRequests(myId, (data) => {
          // Actualiza el estado con las nuevas solicitudes cada vez que haya un cambio en la base de datos
          setAcceptedRequest(data);
        });
      } else {
        const doctorData = await getDoctorById(localData.idDoctor);
        setDataDoctor(doctorData);
        // Llama a la función subscribeToMyAcceptedRequests pasando el ID del usuario y una función callback para manejar los datos
        unsubscribe = subscribeToMyAcceptedRequests(
          localData.idDoctor,
          (data) => {
            // Actualiza el estado con las nuevas solicitudes cada vez que haya un cambio en la base de datos
            setAcceptedRequest(data);
          }
        );
      }
      Swal.close();
    }
    getData();

    // Devuelve una función de limpieza para desuscribirse cuando el componente se desmonte
    return () => {
      // Limpia la suscripción
      unsubscribe();
    };
  }, []);

  const loadData = async () => {
    Swal.fire({
      imageUrl: loadingSVG,
      imageWidth: 300,
      imageHeight: 300,
      title: "Cargando...",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
    if (localData.role === "doctor") {
      const data = await getMyAcceptedRequests(myId);
      setAcceptedRequest(data);
    } else {
      const data = await getMyAcceptedRequests(localData.idDoctor);
      setAcceptedRequest(data);
    }
    Swal.close();
  };

  const handleEventClick = (info) => {
    if (isMorePopoverOpen) {
      setIsMorePopoverOpen(false);
    }
    setOpen(true);
    setSelectedData(info.event.extendedProps.data);
  };
  const styles = {
    base: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "100%",
      marginTop: "2vh",
    },
    md: {
      marginLeft: "19%",
      marginRight: "1%",
    },
    lg: {
      marginLeft: "15%",
    },
  };

  return (
    <Box
      sx={{
        height: "100%", // Asegura que Box ocupe el 100% del contenedor padre
        display: "flex",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          padding: { xs: "8px", sm: "16px", md: "24px", lg: "32px" },
          color: "#006686",
          textTransform: "capitalize",
          borderRadius: "20px",
          width: "100%",
          height: "100%",
        }}
      >
        <FullCalendar
          height={"100%"}
          noEventsText="No hay reservas para hoy"
          dayMaxEvents={2}
          moreLinkContent={(num) => `Ver ${num.num} más`}
          dateClick={handleClick}
          firstDay={1}
          aspectRatio={1.5} // Adjusted aspect ratio for responsiveness
          plugins={[
            dayGridPlugin,
            interactionPlugin,
            listPlugin,
            timeGridPlugin,
          ]}
          initialView="dayGridMonth"
          moreLinkClick={() => setIsMorePopoverOpen(true)}
          moreLinkWillUnmount={() => setIsMorePopoverOpen(false)}
          weekends={true}
          eventClick={handleEventClick}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          buttonText={{
            today: "Hoy",
            month: "Mes",
            week: "Semana",
            day: "Día",
            list: "Lista",
          }}
          events={acceptedRequest}
          locale={"es"}
          validRange={{
            start: new Date(new Date().setMonth(new Date().getMonth() - 1))
              .toISOString()
              .slice(0, 10),
            end: new Date(new Date().setMonth(new Date().getMonth() + 2))
              .toISOString()
              .slice(0, 10),
          }}
        />
      </Paper>

      {open && (
          <DialogCalendar
            open={open}
            close={handleClose}
            data={selectedData}
            myId={myId}
            loadData={loadData}
          />
      )}

      {openNewReserve && (
        <DialogNewReserve
          open={openNewReserve}
          close={close}
          selectedDate={selectedDate}
          myId={myId}
        />
      )}
    </Box>
  );
}
