import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  serverTimestamp,
  setDoc,
  where,
  deleteDoc,
  updateDoc,
} from "firebase/firestore";
import { db, auth, storage } from "./config";
import { createUserWithEmailAndPassword } from "firebase/auth";

import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

export async function getSecretaryById(userId) {
  const docRef = doc(db, "users", userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      id: docSnap.id,
      ...docSnap.data(),
    };
  } else {
    return null;
  }
}

export async function getMySecretaries(idDoc) {
  const mySecretaries = await getDocs(
    query(
      collection(db, "users"),
      where("idDoctor", "==", idDoc),
      where("role", "==", "secretary")
    )
  );
  return mySecretaries.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

export async function createSecretary(data) {
  try {
    // const usuarioActual = auth.currentUser;
    // const responseApi = await axios.post(
    //   "https://us-central1-dfinder-5f8ae.cloudfunctions.net/app/createUserFirebase",
    //   {
    //     email: data.email,
    //     password: data.password,
    //   }
    // );

    const responseApi = await createUserWithEmailAndPassword(
      auth,
      data.email,
      data.password
    );
    const res = responseApi.user;

    if (data.image) {
      const imageUrl = await uploadImage(data.image);
      data.image = imageUrl;
    }

    const docRef = doc(collection(db, "users"), res.uid);

    let a = await setDoc(docRef, {
      ...data,
      role: "secretary",
      createdAt: serverTimestamp(),
    });

    return a;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function uploadImage(file) {
  const storageRef = ref(storage, `secretaries/${file.name}-${Date.now()}`);

  try {
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Error al subir archivo:", error);
          reject(error);
        },
        async () => {
          try {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
            resolve(downloadURL);
          } catch (error) {
            console.error("Error al obtener el URL de descarga:", error);
            reject(error);
          }
        }
      );
    });
  } catch (error) {
    console.error("Error al subir el archivo:", error);
    return null;
  }
}

export async function deleteSecretary(secretaryId) {
  try {
    const docRef = doc(collection(db, "users"), secretaryId);
    await deleteDoc(docRef);

    return true;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
}

export async function updateSecretary(secretaryId, newData) {
  try {
    // Obtener la referencia al documento de la secretaria en la base de datos
    const docRef = doc(collection(db, "users"), secretaryId);

    // Verificar si hay una imagen nueva para subir
    if (newData.image) {
      // Subir la nueva imagen y obtener su URL
      const imageUrl = await uploadImage(newData.image);
      // Actualizar el dato de la imagen en los nuevos datos
      newData.image = imageUrl;
    }

    // Actualizar los datos de la secretaria en la base de datos
    await updateDoc(docRef, newData);

    // Retornar true para indicar que la actualización fue exitosa
    return true;
  } catch (error) {
    // Manejar cualquier error que ocurra durante el proceso de actualización
    console.error(error);
    // Retornar el error para que pueda ser manejado por el llamador de la función
    return error;
  }
}
